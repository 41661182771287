import palette from "./palette";

export const DATA_VIZ_COLORS = [
  palette.blue[60],
  palette.violet[60],
  palette.yellow[60],
  palette.green[60],
  palette.orange[60],
  palette.teal[60],
  palette.purple[60],
  palette.blue[20],
  palette.red[60],
  palette.aqua[60],
  palette.violet[20],
  palette.green[20],
  palette.red[20],
];

export const DATA_VIZ_COLORS_LOW_OPACITY = [
  palette.blue[20],
  palette.violet[20],
  palette.yellow[20],
  palette.green[20],
  palette.orange[20],
  palette.teal[20],
  palette.purple[20],
  palette.blue[10],
  palette.red[20],
  palette.aqua[20],
  palette.violet[10],
  palette.green[10],
  palette.red[10],
];

export const ECO_DATA_VIZ_COLORS = [
  palette.green[60],
  palette.teal[60],
  palette.green[100],
  palette.blue[60],
  palette.green[70],
  palette.violet[60],
  palette.green[20],
  palette.aqua[60],
  palette.purple[60],
  palette.blue[20],
  palette.teal[70],
  palette.yellow[20],
  palette.blue[70],
];

export const fontStyles = {
  fontFamily_numbers: "San Francisco",
  fontFamily: "Aeonik",
  fontSize_base: "16px",
  fontSize_small: "0.7rem",
  fontSize_ui: "0.875rem",
  fontSize_xs: "0.55rem",
  fontWeight_bold: 700,
  fontWeight_regular: 400,
  fontWeight_semiBold: 600,
  fontWeight_thin: 100,
  h1_fontSize: "2.125rem",
  h1_fontWeight: 700,
  h2_fontSize: "2rem",
  h2_fontWeight: 600,
  h3_fontSize: "1.5rem",
  h3_fontWeight: 600,
  h4_fontSize: "1.25rem",
  h4_fontWeight: 600,
  h5_fontSize: "0.875rem",
  h5_fontWeight: 400,
  h6_fontSize: "0.75rem",
  h6_fontWeight: 400,
};

export const sizesAndPositions = {
  borderRadius_1: "6px",
  borderRadius_2: "8px",
  borderRadius_3: "12px",
  borderRadius_4: "16px",
  lineHeight_ui: 1.5,
  lineHeight: 1.25,
  size_jumbo: "4rem",
  size_large: "3rem",
  size_medium: "2.5rem",
  size_small: "2rem",
  size_tiny: "1.5rem",
  space_jumbo: "3.75rem",
  space_lg: "1.5rem",
  space_md: "1rem", // Root Font Size (16px)
  space_sm: "0.75rem",
  space_xl: "2rem",
  space_xs: "0.5rem",
  space_xxl: "3rem",
  space_xxs: "0.25rem",
  zIndex_100: 100,
  zIndex_1600: 1600,
  zIndex_200: 200,
  zIndex_400: 400,
  zIndex_800: 800,
};

const globalColors = {
  background_color_disabled: palette.grey[20],
  background_color_disabled_inverse: palette.grey[100],
  background_color: palette.grey[10],
  backgroundColor_successPrimary_hover: palette.green[70],
  bar_chart_cursor_fill: palette.grey[20],
  border_color_dark: palette.offBlack,
  border_color: palette.grey[20],
  box_shadow: "rgba(0, 0, 0, 0.2)",
  chart_axis_text: palette.offBlack,
  chart_cartesian_grid_lines: palette.grey[20],
  chart_cursor_hover: palette.grey[10],
  chart_cursor_line_color: palette.grey[100],
  chart_fill_opacity: 0.75,
  chart_legend_header: palette.grey[10],
  cloud_status_warning: palette.yellow[60],
  data_visualization_colors: DATA_VIZ_COLORS,
  date_picker_selected_highlight: palette.blue[10],
  divider_color: palette.grey[10],
  elevated_background_color: palette.white,
  feedback_contrast_text_color: palette.white,
  feedback_negative_background: palette.red[20],
  feedback_negative_outline: palette.red[70],
  feedback_negative: palette.red[60],
  feedback_neutral_background: palette.grey[10],
  feedback_neutral_outline: palette.grey[90],
  feedback_neutral: palette.grey[100],
  feedback_positive_background: palette.green[20],
  feedback_positive_outline: palette.green[70],
  feedback_positive: palette.green[60],
  feedback_warn_background: palette.orange[10],
  feedback_warn_outline: palette.orange[70],
  feedback_warn: palette.orange[60],
  input_background_color: palette.white,
  link_color_hover: palette.blue[60],
  link_color: palette.blue[70],
  loading_outline_line_color_bold: palette.grey[60],
  loading_outline_line_color: palette.grey[50],
  loading_skeleton_bg_color: palette.grey[20],
  loading_skeleton_fg_color: palette.grey[10],
  loading_spinner_color: palette.blue[100],
  overlay_background_color: "rgba(30, 35, 40, 0.4)",
  panel_backgroundColor: palette.white,
  primary_color_background_inverse: palette.blue[20],
  primary_color_background: palette.blue[60],
  primary_color_border: palette.blue[60],
  primary_color_focus: palette.blue[70],
  primary_color_hover: palette.blue[70],
  primary_color_text: palette.blue[60],
  secondary_color_background_hover: palette.grey[20],
  secondary_color_background: palette.grey[10],
  secondary_color_border: palette.grey[20],
  secondary_color: palette.grey[60],
  select_color: palette.offBlack,
  select_control_border_color_focused: palette.white,
  select_control_border_color: palette.grey[20],
  select_is_active: palette.violet[60],
  side_drawer_background_color: palette.white,
  side_drawer_header_background_color: palette.offBlack,
  side_drawer_header_text_color: palette.white,
  switch_color_off: palette.grey[50],
  switch_color_on: palette.green[60],
  table_cell_background_color_selected: palette.grey[90],
  table_cell_background_color: palette.white,
  table_cell_background_color_masked: palette.grey[20],
  table_footer_background_color: palette.grey[30],
  table_header_background_color: palette.white,
  table_header_text_color: palette.black,
  table_row_background_color_hover: palette.grey[20],
  tag_background_color_danger: palette.red[20],
  tag_background_color_primary: palette.aqua[60],
  tag_background_color_warning: palette.yellow[20],
  tag_background_color: palette.grey[20],
  tag_button_background_color_danger_hover: palette.red[60],
  tag_button_background_color_hover: palette.grey[60],
  tag_button_background_color_warning_hover: palette.yellow[60],
  tag_button_color_danger: palette.red[70],
  tag_button_color_warning: palette.yellow[70],
  tag_button_color: palette.grey[90],
  text_color_caption: palette.grey[90],
  text_color_disabled: palette.grey[60],
  text_color_inverse: palette.white,
  text_color_placeholder: palette.grey[60],
  text_color_secondary_disabled: palette.grey[50],
  text_color_secondary: palette.grey[60],
  text_color: palette.offBlack,
  tooltip_background_color: "rgba(0, 0, 0, 0.9)",
  tooltip_disabled_cell_color: palette.grey[30],
  tooltip_text_color_hover: palette.aqua[60],
  tooltip_text_color: palette.white,
};

const featureSpecificColors = {
  alert_event_feed_alert_event_node: palette.red[60],
  alert_event_feed_rule_event_node: palette.blue[60],
  alert_event_feed_timeline: palette.grey[60],
  aws_cud_color_covered: palette.violet[60],
  aws_cud_color_estimated: palette.green[60],
  aws_cud_color_on_demand: palette.blue[60],
  aws_cud_color_vis_0_sp_cost: palette.blue[60],
  aws_cud_color_vis_1_ri_cost: palette.violet[60],
  aws_cud_color_vis_2_on_demand_cost: palette.yellow[60],
  aws_cud_color_vis_3_unused_cost: palette.red[60],
  aws_cud_color_vis_lost: palette.red[60],
  aws_cud_color_vis_saved: palette.green[60],
  big_query_duration_1: palette.grey[20],
  big_query_duration_2: palette.grey[50],
  big_query_duration_3: palette.grey[60],
  big_query_duration_4: palette.grey[90],
  biqquery_flat_rate_commitment_color: palette.offBlack,
  budgets_chart_fill_actual: palette.blue[60],
  budgets_chart_fill_highlighted_version: palette.violet[20],
  budgets_chart_fill_latest_version: palette.green[20],
  budgets_chart_fill_overage_actual: palette.red[60],
  budgets_chart_fill_overage_forecast: palette.red[20],
  budgets_chart_fill_overage_historical: palette.red[20],
  budgets_chart_fill_underspend: palette.blue[60],
  budgets_chart_reference_line: palette.blue[60],
  budgets_chart_stroke: palette.grey[100],
  budgets_expand_button_background_color: palette.green[60],
  compute_vizibility_aggregation: palette.violet[60],
  cud_chart_fill_coverable_cost: palette.blue[60],
  cud_chart_fill_existing_coverage: palette.violet[60],
  cud_chart_fill_recommendation: palette.green[70],
  cud_chart_fill_recommendation_max_savings: palette.teal[60],
  cud_chart_fill_total_spend: palette.blue[20],
  cud_color_1yr_stroke: palette.blue[70],
  cud_color_1yr: palette.blue[60],
  cud_color_3yr_stroke: palette.green[70],
  cud_color_3yr: palette.green[60],
  cud_color_on_demand: palette.grey[60],
  data_table_border: palette.grey[10],
  date_picker_day_name_color: palette.grey[100],
  default_button_text_color: palette.offBlack,
  eco_impact: palette.green[60],
  fiscal_calendar_month_background_past: palette.grey[20],
  fiscal_calendar_month_header_background_past: palette.grey[30],
  fiscal_calendar_month_header_background: palette.grey[20],
  modal_background_color: palette.white,
  net_cost_table_cell_background_color: palette.white,
  net_cost_total_highlight: palette.yellow[20],
  ramp_plans_actuals: palette.purple[60],
  ramp_plans_breakpoint: palette.yellow[60],
  ramp_plans_derived_projection: palette.violet[20],
  ramp_plans_projected_stroke: palette.blue[70],
  ramp_plans_projected: palette.blue[60],
  recommendations_chart_line_color: palette.blue[70],
  recommendations_meter_color_active: palette.green[70],
  recommendations_meter_color_as_designed: palette.violet[60],
  recommendations_meter_color_snoozed: palette.blue[100],
  report_display_grip_color: palette.grey[60],
  report_favorite_color_hover: palette.yellow[20],
  report_favorite_color: palette.yellow[60],
  report_management_loader_color: palette.grey[50],
  report_management_row_hover: palette.violet[10],
  resource_selector_background_hover: "rgba(30,135,255, 0.3)",
  resource_selector_border: palette.blue[70],
  section_card_border: palette.grey[20],
  side_nav_background_color: palette.white,
  side_nav_color_secondary: palette.grey[90],
  tab_text_color: palette.grey[90],
  toast_text_color: palette.offBlack,
  top_nav_background_color: palette.white,
  ubergraph_advanced_controls_background: palette.violet[20],
  ubergraph_advanced_controls_text: palette.violet[100],
  ubergraph_advanced_controls: palette.violet[60],
};

export const featureSpecificSizesAndPositions = {
  skeletonLoaderBorderRadius: sizesAndPositions.borderRadius_1.replace(
    "px",
    ""
  ),
};

const stylePaths = {
  logo_path: "/assets/img/logo-standard.svg",
};

const baseTheme = {
  // Defaults
  ...fontStyles,
  ...sizesAndPositions,
  ...featureSpecificSizesAndPositions,

  // Theme Specific
  ...globalColors,
  ...featureSpecificColors,
  ...stylePaths,
};

export const defaultTheme = baseTheme;

export type Theme = typeof defaultTheme;
