import styled from "@emotion/styled";
import { IntegrationStatusVariant } from "../constants";

export const IntegrationStatusTag = styled("div")<{
  variant?: IntegrationStatusVariant;
  width?: number | string;
}>(({ theme, variant, width }) => ({
  alignItems: "center",
  borderColor: (() => {
    switch (variant) {
      case IntegrationStatusVariant.DANGER:
        return theme.feedback_negative;
      case IntegrationStatusVariant.WARNING:
        return theme.cloud_status_warning;
      case IntegrationStatusVariant.SUCCESS:
        return theme.feedback_positive;
      default:
        return theme.feedback_neutral;
    }
  })(),
  backgroundColor: (() => {
    switch (variant) {
      case IntegrationStatusVariant.DANGER:
        return theme.feedback_negative_background;
      case IntegrationStatusVariant.WARNING:
        return theme.feedback_warn_background;
      case IntegrationStatusVariant.SUCCESS:
        return theme.feedback_positive_background;
      default:
        return theme.feedback_neutral_background;
    }
  })(),
  borderRadius: theme.borderRadius_1,
  borderWidth: "1px",
  borderStyle: "solid",
  color: theme.text_color_inverse,
  display: "flex",
  gap: "5px",
  fontSize: ".75rem",
  height: 24,
  justifyContent: "center",
  minWidth: 40,
  ...(width && { width }),
}));
