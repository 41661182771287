import common from "../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  /*0*/ fiscalCalendarDOW_SUNDAY: "Sunday",
  /*1*/ fiscalCalendarDOW_MONDAY: "Monday",
  /*2*/ fiscalCalendarDOW_TUESDAY: "Tuesday",
  /*3*/ fiscalCalendarDOW_WEDNESDAY: "Wednesday",
  /*4*/ fiscalCalendarDOW_THURSDAY: "Thursday",
  /*5*/ fiscalCalendarDOW_FRIDAY: "Friday",
  /*6*/ fiscalCalendarDOW_SATURDAY: "Saturday",
  actionItemUpdateIntegrations: "Update Integrations",
  actionMenuItemAzureCertLabel: "Client Certificate for Azure Integration",
  actionMenuItemDeleteCloud: "Delete",
  actionMenuItemDeleteUserGroupConfig: "Delete",
  actionMenuItemDownloadAzureCert: "Azure Certificate",
  actionMenuItemDownloadOCIKey: "OCI Public Key",
  actionMenuItemEditCloud: "Edit",
  actionMenuItemEditUser: "Edit User",
  actionMenuItemEditUserGroupConfig: "Edit",
  actionMenuItemMakeCopyUserGroupConfig: "Make Copy",
  actionMenuItemOCIKeyLabel: "Public Key for Oracle Cloud Integration",
  actionMenuItemOracleCertLabel: "Client Certificate for OCI Integration",
  actionMenuItemRevokeUserAccess: "Revoke User Access",
  actionMenuItemValidateCloud: "Validate",
  actionSaveCloud: "Save Cloud",
  errorInputFieldRequired: "This field is required",
  addUserGroupConfigButtonLabel: "Add User Group Config",
  addUsersButtonLabel: "Add Users",
  apiTokenInputLabel: "API Token",
  appIDLabel: "App ID",
  azureBillingExportsLabel: "Billing Exports",
  azureCloudCardDateRange: "Date Range",
  azureCloudCardDateRangeEndLabel: "End Date:",
  azureCloudCardDateRangeStartLabel: "Start Date:",
  azureCloudCardServiceUrl: "Service URL",
  azureCloudFormTypeDefaultLabel: "Select Azure Cloud Type",
  azureCloudFormTypeLabel_APNA: "Azure Partner Network Agreement",
  azureCloudFormTypeLabel_AUTO: "Automatically Detect Plan Type",
  azureCloudFormTypeLabel_EA_LEGACY: "Enterprise Agreement (Legacy)",
  azureCloudFormTypeLabel_EA: "Enterprise Agreement",
  azureCloudFormTypeLabel_MCA: "Microsoft Customer Agreement",
  azureCloudFormTypeLabel_MPA: "Microsoft Partner Agreement (Legacy)",
  azureCloudFormTypeLabel_MPAv2: "Microsoft Partner Agreement",
  azureExportTypeActualsLabel: "Cost and usage details (Actual)",
  azureExportTypeAmortizedLabel: "Cost and usage details (Amortized)",
  azurePemFileName: "Ternary_Azure.pem",
  billingAccountIDLabel: "Billing Account ID",
  billingExportTableIDLabel: "Billing Export Table ID",
  cloudAdminBasicInfo: "Basic",
  cloudAlibabaAdminReports: "Cost & Usage Reports",
  cloudAlibabaFormTitleCreate: "Create Alibaba",
  cloudAlibabaFormTitleUpdate: "Update Alibaba",
  cloudAlibabaReportNameLabel: "Name",
  cloudAlibabaReportPathLabel: "Report Path Prefix",
  cloudAlibabaReportRegionLabel: "Region",
  cloudAlibabaReportS3BucketLabel: "S3 Bucket Name",
  cloudAttributeBigQueryFeedbackWarning: "Your changes will be lost if you do not save them",
  cloudAttributeBigQueryFeedbackWarningTitle: "Unsaved Changes",
  cloudAttributeBigQueryNotConfigured: "BigQuery usage analysis is not configured yet. Add a new configuration above to get started.",
  cloudAttributeName: "Name",
  cloudAWSAdminReports: "Cost & Usage Reports",
  cloudAWSFormTitleCreate: "Create AWS",
  cloudAWSFormTitleUpdate: "Update AWS",
  cloudAWSReportDefaultRegionLabel: "Select Region",
  cloudAWSReportNameLabel: "Name",
  cloudAWSReportPathLabel: "Report Path Prefix",
  cloudAWSReportRegionLabel: "Region",
  cloudAWSReportS3BucketLabel: "S3 Bucket Name",
  cloudAzureAdminBillingExports: "Billing Exports",
  cloudAzureAttributeAppID: "Azure App ID",
  cloudAzureAttributeAzureType: "Azure Type",
  cloudAzureAttributeDirectory: "Azure Directory ID",
  cloudAzureDateColumnLabel: "Date Column",
  cloudAzureDateColumnLabelOptional: "Date Column (Optional)",
  cloudAzureExportTypeLabel: "Export Type",
  cloudAzureFormTitleCreate: "Create Azure",
  cloudAzureFormTitleUpdate: "Update Azure",
  cloudAzureServiceURLLabel: "Service URL",
  cloudAzureStorageContainerLabel: "Storage Container",
  cloudAzureStoragePrefixLabel: "Storage Container Prefix",
  cloudAzureStrictStoragePrefixLabel: "Strict Storage Prefix",
  cloudDropDownLabel: "New Integration",
  cloudGCPFormTitleCreate: "Create Cloud",
  cloudGCPFormTitleUpdate: "Update Cloud",
  cloudHelpBigQueryDataProject: "Project ID containing BigQuery activity",
  cloudHelpCommitmentsSharing: "Check this box if committed use discount sharing is enabled within your billing account.",
  cloudHelpLabelConfiguration: "Choose labels from your billing export that will be visible in Ternary. For best performance,use an inclusion filter.",
  cloudOCIAttributeRegion: "Tenancy Region",
  cloudOCIAttributeRegionPlaceholder: "Select OCI Region",
  cloudOCIAttributeStorageBucket: "Custom Storage Bucket",
  cloudOCIAttributeStorageNamespace: "Custom Storage Namespace",
  cloudOCIAttributeTenancy: "Tenancy OCID",
  cloudOCIAttributeUser: "Service User OCID",
  cloudOCIKeyFileName: "Ternary_OCI.pem",
  cloudPlaceholderRoleArn: "arn:aws:iam::123456789:role/NAME_HERE",
  cloudsLabel: "Clouds",
  cloudValidationErrorButton: "Click to view validation errors",
  configureButtonLabel: "Configure",
  createAlibabaIntegrationButtonLabel: "Alibaba",
  createAWSIntegrationButtonLabel: "AWS",
  createAzureIntegrationButtonLabel: "Azure",
  createGCPIntegrationButtonLabel: "GCP",
  createJiraIntegrationFormTitle: "Jira",
  createMongoDbIntegrationButtonLabel: "MongoDB",
  createOracleIntegrationButtonLabel: "Oracle",
  createSnowflakeButtonlabel: "Snowflake",
  backToIntegrationsButtonLabel: "Back to Integrations",
  curBucketAndPathLabel: "CUR S3 Bucket and Path",
  currentTenantCopiedToClipboard: "Copied to clipboard...",
  currentTenantGeneralInformation: "General Information",
  currentTenantGeneralInformationFormIDLabel: "Tenant ID",
  currentTenantGeneralInformationFormIDTooltip: "Copy Tenant ID",
  currentTenantGeneralInformationFormNameLabel: "Tenant Name",
  currentTenantGeneralInformationFormNameTooltip: "Copy Tenant Name",
  currentTenantGeneralInformationFormServiceAccount: "Service Account",
  currentTenantGeneralInformationFormServiceAccountTooltip: "Copy Service Account",
  currentTenantGeneralInformationFormServiceAccountUID: "Service Account Unique ID",
  currentTenantGeneralInformationFormServiceAccountUIDTooltip: "Copy Service Account Unique ID",
  dataIntegrationLabel: "Cloud & Data Integrations",
  dataRefreshStatus: "Ternary last refreshed your data %refresh% ago. Latest timestamp found in your bill is %upstream% old ( %upstreamtimestamp% UTC )",
  datePickerModalEndLabel: "End",
  datePickerModalEndLabelOptional: "End (Optional)",
  datePickerModalStartLabel: "Start",
  datePickerModalStartLabelOptional: "Start (Optional)",
  defaultCategory_HIDDEN: "Hidden",
  defaultCategory_PREFERRED: "Preferred",
  defaultCategory_UNASSIGNED: "Unassigned",
  deleteDataIntegrationConfirmationMessage: "Are you sure you want to delete this Data Integration?",
  deleteDataIntegrationConfirmationTitle: "Delete Data Integration",
  deleteIntegrationConfirmationTitle: "Delete Integration",
  deleteJiraIntegrationConfirmationMessage: "Are you sure you want to delete this Jira integration?",
  disableNotificationsLabel: "Opt-Out of Email Notifications",
  duplicateEmailMessage: "Duplicate input email",
  emailInputLabel: "Email",
  emailInputTooltipCaption: "Type or paste in a comma-separated list of emails.",
  emailsInputLabel: "Email(s)",
  enableCarbonData: "Enable Carbon Footprint Data?",
  errorCreatingAlibabaIntegrationMessage: "An error occurred while attempting to create the Alibaba data integration. Please try again.",
  errorCreatingAWSIntegrationMessage: "An error occurred while attempting to create the AWS data integration. Please try again.",
  errorCreatingAzureIntegrationMessage: "An error occurred while attempting to create the Azure data integration. Please try again.",
  errorCreatingJiraIntegrationMessage: "An error occurred while attempting to create the Jira integration. Please try again.",
  errorCreatingOracleIntegrationMessage: "An error occurred while attempting to create the Oracle data integration. Please try again.",
  errorCreatingPreferredLabelsMessage: "An error occurred when attempting to create preferred labels",
  errorCreatingPreferredMeasuresMessage: "An error occurred when attempting to create preferred measures",
  errorCreatingSnowflakeIntegrationMessage: "An error occurred while attempting to create the Snowflake data integration. Please try again.",
  errorCreatingUserGroupConfigMessage: "An error occurred when attempting to create the user group configuration. Please try again.",
  errorDeletingDataIntegrationMessage: "An error occurred while attempting to delete the data integration. Please try again.",
  errorDeletingJiraIntegrationMessage: "An error occurred while attempting to delete the Jira integration. Please try again.",
  errorDeletingUserGroupConfigMessage: "An error occurred while attempting to delete the user group configuration. Please try again.",
  errorGrantingUsersTenantAccessMessage: "An error occurred when attempting to give users access to the tenant. Please try again.",
  errorLoadingSlackIntegrationMessage: "An error occurred while loading the Slack Integration. Please try again.",
  errorRevokingUserTenantAccessMessage: "An error occurred while attempting to revoke user access to the tenant. Please try again.",
  errorUpdatingAWSIntegrationMessage: "An error occurred while attempting to update the AWS data integration. Please try again.",
  errorUpdatingAzureIntegrationMessage: "An error occurred while attempting to update the Azure data integration. Please try again.",
  errorUpdatingDimensionPreferencesMessage: "An error occurred when attempting to update label preferences. Please try again.",
  errorUpdatingIntegrationMessage: "An error occurred while attempting to update the integration. Please try again.",
  errorUpdatingMeasurePreferencesMessage: "An error occurred when attempting to update measure preferences. Please try again.",
  errorUpdatingPreferredLabelsMessage: "An error occurred when attempting to update preferred labels",
  errorUpdatingUserGroupConfigMessage: " An error occurred when attempting to update the user group configuration. Please try again.",
  errorUpdatingUserNotificationMessage: "An error occurred while attempting to update new user notification preferences. Please try again.",
  errorUpdatingUserTenantRoles: "An error occurred while attempting to update the users roles. Please try again.",
  errorValidatingDataIntegrationMessage: "An error occurred while attempting to validate the data integration. Please try again.",
  fiscalCalendarAddYear: "Add Year",
  fiscalCalendarAddYearAdd: "Add Year",
  fiscalCalendarAddYearAutofill: "Autofill dates",
  fiscalCalendarAddYearReset: "Reset",
  fiscalCalendarBuilderHeader: "Fiscal Calendar",
  fiscalCalendarChangeAt: " at ",
  fiscalCalendarChangeUpdatedBy: "Last updated by ",
  fiscalCalendarFormLabelEndDate: "End Date:",
  fiscalCalendarFormLabelQuarterPattern: "Quarter Pattern:",
  fiscalCalendarFormLabelSelectQuarterPattern: "Select Quarter Pattern...",
  fiscalCalendarFormLabelStartDate: "Start Date:",
  fiscalCalendarFormSelectedYear: "Selected Year",
  fiscalCalendarInvalidDOWChange: "All fiscal calendar years must start on the same day of the week.",
  fiscalCalendarInvalidPeriodLong: "Fiscal period %FPK% is %WEEKCOUNT% weeks long",
  fiscalCalendarInvalidPeriodShort: "Fiscal period %FPK% is only %WEEKCOUNT% weeks long",
  fiscalCalendarInvalidYearOverlap: "Fiscal years %Y1% and %Y2% are overlapping",
  fiscalCalendarNotConfigured: "Fiscal Calendar not configured",
  fiscalCalendarNotConfiguredAddYear: "Add a year to get started...",
  fiscalCalendarRemoveYear: "Remove Year",
  fiscalCalendarRemoveYearWarning: "Only the first and last years of a fiscal calendar can be removed.",
  fiscalCalendarSaveAddedMany: "Added %COUNT% fiscal years",
  fiscalCalendarSaveAddedOne: "Added 1 fiscal year",
  fiscalCalendarSaveChangedMany: "Changed %COUNT% fiscal years",
  fiscalCalendarSaveChangedOne: "Changed 1 fiscal year",
  fiscalCalendarSaveHasError: "1 year is invalid",
  fiscalCalendarSaveHasErrors: "%COUNT% years are invalid",
  fiscalCalendarSaveRemovedMany: "Removed %COUNT% fiscal years",
  fiscalCalendarSaveRemovedOne: "Removed 1 fiscal year",
  fiscalCalendarSaveReset: "Reset",
  fiscalCalendarSaveUpdate: "Save Calendar",
  fiscalCalendarTodayInfoCurrentDate: "Today's Date",
  fiscalCalendarTodayInfoCurrentPeriod: "Current Fiscal Period",
  fiscalCalendarTodayInfoFiscalPeriod: "FY%FY%-FQ%FQ%-FM%FM%-FW%FW%",
  fiscalCalendarTodayInfoNoCalendar: "No fiscal year associated with today's date",
  fiscalCalendarUpdatingErrorMessage: "An error occurred while attempting to update the tenant. Please try again.",
  fiscalCalendarUpdatingSuccessMessage: "The tenant has been successfully updated.",
  fiscalCalendarValidationDayOfWeekDiffNext: "Next year starts on a %DOW%",
  fiscalCalendarValidationDayOfWeekDiffPrev: "Previous year starts on a %DOW%",
  fiscalCalendarValidationDayOfWeekShouldEnd: "Year should end on a %DOW%",
  fiscalCalendarValidationDayOfWeekShouldStart: "Year should start on a %DOW%",
  fiscalCalendarValidationHasWeek: "Has 1 week",
  fiscalCalendarValidationHasWeekPrev: "Previous year has 1 week",
  fiscalCalendarValidationHasWeeks: "Has %WEEKS% weeks",
  fiscalCalendarValidationHasWeeksPrev: "Previous year has %WEEKS% weeks",
  fiscalCalendarValidationIsOverlappingPrev: "Overlaps previous year",
  fiscalCalendarValidationNoIssuesDetected: "No issues detected",
  fiscalCalendarValidationQuarterPatternDiffNext: "Has different quarter pattern than following year",
  fiscalCalendarValidationQuarterPatternDiffPrev: "Has different quarter pattern than previous year",
  fiscalCalendarValidationQuarterPatternSameNext: "Shares quarter pattern with following year",
  fiscalCalendarValidationQuarterPatternSamePrev: "Shares quarter pattern with previous year",
  fiscalCalendarViewMonth: "M",
  fiscalCalendarViewQuarterNumber: "Q%QUARTER_NUMBER%",
  fiscalCalendarViewTooltipQuarter: "Q",
  fiscalCalendarViewTooltipWeek: "W",
  fiscalCalendarViewTooltipYear: "Y",
  fiscalCalendarYearPickerLabel: "Fiscal Year",
  fiscalCalendarYearPickerPlaceholder: "Fiscal Year",
  formTitleCreateUsers: "Add New Users",
  formTitleUpdateUser: "Update User",
  initialDataRefreshNotCompletedText: "Your initial data ingestion has not yet completed. If it has been more than 24 hours since setup please reach out to support.",
  integrationButtonLabelConfigure: "Configure",
  integrationButtonLabelDetails: "Details",
  tableHeaderName: "Name",
  tableHeaderStatus: "Status",
  integrationButtonLabelSlack: "Add to Slack",
  integrationStatusLabelConnected: "Connected",
  integrationStatusLabelNotConfigured: "Not Configured",
  invalidEmailMessage: "Invalid email format",
  issueTransitionIDInputCaption: `For custom final states. `,
  issueTransitionIDInputCaptionLink: "Learn more about transition IDs",
  issueTransitionIDInputLabel: "Issue Transition ID(s)",
  jiraDocumentationCaption: "Learn more about our Jira integration ",
  jiraIntegrationApiTokenLabel: "API Token",
  jiraIntegrationEmailLabel: "Email",
  jiraIntegrationInstanceUrlLabel: "Instance URL",
  jiraIntegrationNotConfiguredMessage: "Jira integration is not yet configured.",
  jiraIntegrationProjectIDLabel: "Project ID",
  jiraIntegrationTitle: "Jira",
  labelManagementBoxTitleAll: "Groupings",
  labelManagementBoxTitleHidden: "Hidden Labels",
  labelManagementBoxTitlePreferred: "Preferred Labels",
  labelMeasureManagementAddCategory: "Add Category",
  labelMeasureManagementCategoryName: "Category Name",
  labelMeasureManagementReset: "Reset All Changes",
  labelMeasureManagementResetMessage: "Are you sure you want to reset all unsaved changes?",
  labelMeasureManagementResetTitle: "Reset All Unsaved Changes",
  labelMeasureManagementSave: "Save Changes",
  labelMeasureMoveToCategory: "Move %COUNT% to \"%CATEGORY%\"",
  labelMeasureSelectAll: "Select All",
  labelMeasureSelectNone: "Clear Selection",
  labelsManagementDataSourceTitle: "Source",
  loading: "Loading...",
  measureManagementBoxTitleAll: "Measures",
  measureManagementBoxTitleHidden: "Hidden Measures",
  measureManagementBoxTitlePreferred: "Preferred Measures",
  measurePreferencesNoneAvailableMessage: "No Measures Available",
  measurePreferencesNoneSelectedMessage: "No Measures Selected",
  mongoDbIntegrationCardOrganizationID: "Organization ID",
  monitoredBigQueryProjectsLabel: "Monitored BigQuery Projects",
  monitoringRoleARNLabel: "Metrics Collection Role ARN",
  newUserNotificationBudgetsLabel: "Budgets",
  newUserNotificationsAlertsLabel: "Alerts",
  newUserNotificationsDailyLabel: "Daily",
  newUserNotificationsDisableAllLabel: "Disable All",
  newUserNotificationsModalDescription: "Enabled notifications settings will be applied to all new users invited through email and SSO-provisioned users.",
  newUserNotificationsModalTitle: "New User Notification Preferences",
  newUserNotificationsMonthlyLabel: "Monthly",
  newUserNotificationsRecommendationsLabel: "Recommendations",
  newUserNotificationsReportsLabel: "Reports",
  newUserNotificationsToolTipMessage: "New user notification preferences",
  newUserNotificationsWeeklyLabel: "Weekly",
  noBQProjectsMessage: "No BigQuery projects configured",
  noCURsMessage: "No CURs configured",
  notificationPreferencesLabel: "Notification Preferences",
  notificationPreferencesTooltipCaption: "The user can update this setting on their User Settings Page.",
  notSpecifiedCaption: "Not Specified",
  optionLabelDeleteIntegration: "Delete Integration",
  optionLabelUpdateIntegration: "Update Integration",
  preferredLabelsNoneAvailableMessage: "No Labels Available",
  preferredLabelsNoneSelectedMessage: "No Labels Selected",
  projectIDInputLabel: "Project ID",
  revalidateCloudTooltipCaption: "Revalidate Cloud",
  revokerUserAccessConfirmationMessage: "Are you sure you want to revoke access to the tenant from %name%?",
  revokerUserAccessConfirmationTitle: "Revoke Access",
  roleARNLabel: "Role ARN",
  rolesInputLabel: "Roles",
  rootResourceIDLabel: "Root Resource ID",
  scopedViewAvailableLabel: "Available",
  scopedViewEnabledByDefaultLabel: "Enabled By Default",
  scopedViewEnabledStrictLabel: "Strictly Enabled",
  scopedViewEnforcedLabel: "Enforced",
  sideDrawerTitleCreate: "Create",
  sideDrawerTitleEdit: "Edit",
  slackErrorCreatingIntegration: "An error occurred while attempting to create the integration. Please try again.",
  slackErrorDeletingIntegration: "An error occurred while attempting to delete the Slack integration. Please try again.",
  slackErrorUpdatingIntegration: "An error occurred while attempting to update the integration. Please try again.",
  slackIntegrationAttributeChannelID: "Channel ID",
  slackIntegrationAttributeTeamURL: "Team URL",
  slackIntegrationChannelIDCaption: "See our %link% for setup instructions.",
  slackIntegrationChannelIDCaptionLink: "Integrations Guide",
  slackIntegrationConfigured: "Slack integration is active.",
  slackIntegrationDeleteConfirmation: "Are you sure you want to delete this Slack integration?",
  slackIntegrationInstructions: "Click the button below to begin.",
  slackIntegrationNotConfigured: "Slack integration is not yet configured.",
  slackIntegrationTitle: "Slack",
  slackIntegrationWorkspaceURLLabel: "Workspace URL",
  snowflakeIntegrationCardAccountName: "Account Name",
  snowflakeIntegrationCardConnectedAccounts: "Accounts in Organization",
  snowflakeIntegrationCardOrgName: "Organization Name",
  snowflakeIntegrationCardRole: "Account Role",
  snowflakeIntegrationCardWarehouse: "Warehouse",
  socialIntegrationLabel: "Collaboration & Workflow Integrations",
  successCreatingAlibabaIntegrationMessage: "The Alibaba data integration has been successfully created.",
  successCreatingAWSIntegrationMessage: "The AWS data integration has been successfully created.",
  successCreatingAzureIntegrationMessage: "The Azure data integration has been successfully created.",
  successCreatingDimensionPreferences: "Label Preferences have been successfully created.",
  successCreatingJiraIntegrationMessage: "The Jira integration has been successfully created.",
  successCreatingMeasurePreferences: "Measure Preferences have been successfully created.",
  successCreatingOracleIntegrationMessage: "The Oracle data integration has been successfully created.",
  successCreatingSnowflakeIntegrationMessage: "The Snowflake data integration has been successfully created.",
  successCreatingUserGroupConfigMessage: "The user group configuration has been successfully created.",
  successDeletingDataIntegrationMessage: "The data integration has been successfully deleted.",
  successDeletingJiraIntegrationMessage: "The Jira integration has been successfully deleted.",
  successDeletingSlackIntegrationMessage: "The Slack integration has been successfully deleted.",
  successDeletingUserGroupConfigMessage: "The user group config has been successfully deleted.",
  successGrantingUsersTenantAccessMessage: "All users have successfully been granted access to the tenant.",
  successRevokingUserTenantAccessMessage: "The users tenant access has been successfully revoked.",
  successUpdatingAWSIntegrationMessage: "The AWS data integration has been successfully updated.",
  successUpdatingAzureIntegrationMessage: "The Azure data integration has been successfully updated.",
  successUpdatingDimensionPreferencesMessage: "Label Preferences have been successfully updated.",
  successUpdatingIntegrationMessage: "The integration has been successfully updated.",
  successUpdatingMeasurePreferencesMessage: "Measure Preferences have been successfully updated.",
  successUpdatingSlackIntegrationMessage: "The integration has been successfully updated.",
  successUpdatingUserGroupConfigMessage: "The user group config has been successfully updated.",
  successUpdatingUserNotifications: "New user notification preferences have been successfully updated.",
  successUpdatingUserTenantRoles: "The users roles have been successfully updated.",
  tabLabelClouds: "Clouds",
  tabLabelCustomGroupings: "Custom Groupings",
  tabLabelCustomLabels: "Custom Labels",
  tabLabelFiscalCalendar: "Fiscal Calendar",
  tabLabelGlobalPreferences: "Global",
  tabLabelIntegrations: "Integrations",
  tabLabelScopedViews: "Scoped Views",
  tabLabelTenant: "Tenant",
  tabLabelUserGroupConfigs: "User Group Configurations",
  tabLabelUsers: "Users",
  tableHeaderCreatedAt: "Created At",
  tableHeaderCreatedBy: "Created By",
  tableHeaderNumberOfScopedViews: "Scoped Views",
  tableHeaderTimeLastModified: "Time Last Modified",
  tableHeaderUpdatedBy: "Updated By",
  userAlreadyGrantedAccessMessage: "This user has already been granted access to the tenant.",
  userCountLabel: "Users",
  userFormAlertsLabel: "Alerts",
  userFormBudgetsLabel: "Budgets",
  userFormDisableAllLabel: "Disable All",
  userFormInfoToolTipMessage: "If no preferences are applied, new users will inherit globally set notification preferences.",
  userFormNotificationSettingsButtonLabel: "Notification Settings",
  userFormRecommendationsLabel: "Recommendations",
  userFormReportsDailyLabel: "Daily",
  userFormReportsLabel: "Reports",
  userFormReportsMonthlyLabel: "Monthly",
  userFormReportsWeeklyLabel: "Weekly",
  userGroupConfigDeleteModalMessage: "Are you sure you want to delete this user group configuration? This operation cannot be undone.\n\nDeleting this user group configuration will immediately remove users from their assigned scoped views.",
  userGroupConfigDeleteModalTitle: "Delete User Group Configuration",
  userGroupConfigFormAddScopedViewLabel: "Add scoped view.",
  userGroupConfigFormGroupNameDuplicateError: "Group name already exists.",
  userGroupConfigFormGroupNameInstructions: "Enter the group name as it appears on the ID token exactly.",
  userGroupConfigFormGroupNameLabel: "Group Name",
  userGroupConfigFormGroupNameScopedViewsInstructions: "Assign scoped views to the users upon login",
  userGroupConfigFormGroupNameScopedViewsLabel: "Scoped views",
  userRolePermissionsDocumentationCaption: "Learn more about roles %link%.",
  usersTabLabel: "Users",
  userTableHeader_createdAt: "Created At",
  userTableHeader_email: "Email",
  userTableHeader_lastLogin: "Last Login",
  userTableHeader_name: "Name",
  userTableHeader_roles: "Roles",
  validationErrorsCloudText: "Cloud: %name%",
  validationErrorsCountText: "Total Errors: %count%",
  validationErrorsHeader: "Validation Errors",
  validationErrorsHelperText: "We have detected one or more validation issues with your cloud configuration. Your configuration may be missing permissions or you may need to configure some features.",
  validationErrorsTooltip: "Click to update the cloud configuration",
  validationErrorText: "Error: ",
  validEmailMessage: "This is a valid email",
};
