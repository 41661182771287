import { operatorOptions } from "@/constants";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useAuthenticatedUser from "@/hooks/useAuthenticatedUser";
import { Input, QueryFilter } from "@/types";
import Divider from "@/ui-lib/components/Divider";
import Form, { FormField } from "@/ui-lib/components/Form";
import Select from "@/ui-lib/components/Select";
import SelectCheckbox, { Option } from "@/ui-lib/components/SelectCheckbox";
import SelectDropdown from "@/ui-lib/components/SelectDropdown";
import { SelectDropdownFilter } from "@/ui-lib/components/SelectDropdownFilter";
import Switch from "@/ui-lib/components/Switch";
import TextInput from "@/ui-lib/components/TextInput";
import IconTimes from "@/ui-lib/icons/IconTimes";
import getMergeState from "@/utils/getMergeState";
import { isOperator } from "@/utils/typeGuards";
import { useTheme } from "@emotion/react";
import {
  faInfoCircle,
  faMinus,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import {
  DataSource,
  Operator,
  ScopedViewType,
  UserConfigStatus,
} from "@ternary/api-lib/constants/enums";
import { ScopedViewFilter, UserConfig } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { getEnumAtKey } from "@ternary/api-lib/utils/typeGuards";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { isEqual, keyBy, noop, truncate, uniq } from "lodash";
import React, { useState } from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import { getFullName } from "../../../utils/UserUtils";
import { groupOptionsByPreferences } from "../../admin/utils";
import copyText from "../copyText";

const dataSourceSelectOptions = [
  {
    label: copyText.dataSource_BILLING_label,
    value: DataSource.BILLING,
  },
  {
    label: copyText.dataSource_KUBERNETES_CONTAINER_USAGE_label,
    value: DataSource.KUBERNETES_CONTAINER_USAGE,
  },
  {
    label: copyText.dataSource_KUBERNETES_NODE_USAGE_label,
    value: DataSource.KUBERNETES_NODE_USAGE,
  },
];

const newFilter = {
  dataSource: "" as DataSource,
  name: "",
  operator: Operator.EQUALS,
  values: [],
};

// TODO: Separate this form into CreateScopedViewForm & EditScopedViewForm
export enum Action {
  CREATE = "CREATE",
  UPDATE = "UPDATE",
}

const LIST_AVAILABLE = "LIST_AVAILABLE";
const LIST_ENABLED_STRICT = "LIST_ENABLED_STRICT";
const LIST_ENABLED = "LIST_ENABLED";
const LIST_ENFORCED = "LIST_ENFORCED";

type User = {
  id: string;
  email: string;
  firstName: string;
  lastName: string;
};

type DimensionPreference = {
  dataSource: DataSource;
  category: string;
  values: string[];
};

export interface Props {
  action: Action;
  availableDimensionsMap: { [key in DataSource]?: string[] };
  dimensionValuesMap: { [key: string]: string[] };
  isLoading: boolean;
  isLoadingDimensionValuesMap: boolean;
  isProcessing: boolean;
  dimensionPreferences: DimensionPreference[];
  scopedView?: {
    id: string;
    applyToNewUserStatus: UserConfigStatus | null;
    createdBy: string;
    filters: ScopedViewFilter[];
    name: string;
    type: ScopedViewType;
    userConfigs: UserConfig[];
  };
  type: ScopedViewType;
  users: User[];
  onInteraction: (interaction: ScopedViewForm.Interaction) => void;
}

interface State {
  applyToNewUsersStatusInput: Input<UserConfigStatus | null>;
  filtersInput: Input<ScopedViewFilter[]>;
  listKey: string;
  nameInput: Input<string>;
  userConfigsInput: Input<UserConfig[]>;
}

const initialState: State = {
  applyToNewUsersStatusInput: {
    value: null,
    hasChanged: false,
    isValid: true,
  },
  filtersInput: { value: [], hasChanged: false, isValid: false },
  listKey: LIST_AVAILABLE,
  nameInput: { value: "", hasChanged: false, isValid: false },
  userConfigsInput: {
    value: [],
    hasChanged: false,
    isValid: true,
  },
};

export function ScopedViewForm(props: Props): JSX.Element {
  const authenticatedUser = useAuthenticatedUser();
  const activityTracker = useActivityTracker();
  const gatekeeper = useGatekeeper();
  const theme = useTheme();

  //
  // State
  //

  const [state, setState] = useState<State>(
    props.scopedView
      ? {
          applyToNewUsersStatusInput: {
            value: props.scopedView.applyToNewUserStatus,
            hasChanged: false,
            isValid: true,
          },
          filtersInput: {
            value: props.scopedView.filters,
            hasChanged: false,
            isValid: true,
          },
          listKey: LIST_AVAILABLE,
          nameInput: {
            value:
              props.action === Action.CREATE
                ? `${props.scopedView.name} (${copyText.scopedViewCopyLabel})`
                : props.scopedView.name,
            hasChanged: props.action === Action.CREATE,
            isValid: true,
          },
          userConfigsInput: {
            value: props.scopedView.userConfigs,
            hasChanged: false,
            isValid: true,
          },
        }
      : initialState
  );

  const mergeState = getMergeState(setState);

  const [customFilter, setCustomFilter] = useState<string[]>([]);

  //
  // Interaction Handlers
  //

  function handleChangeName(event: React.ChangeEvent<HTMLInputElement>): void {
    const { name, value } = event.target;

    const isValid = value.trim().length > 0;

    const hasChanged = props.scopedView?.[name] !== value.trim();

    mergeState({ [`${name}Input`]: { value, hasChanged, isValid } });
  }

  function handleEnabledByDefaultChange(checked: boolean): void {
    const hasChanged = !isEqual(
      props.scopedView?.userConfigs.some(
        (userConfig) =>
          userConfig.userID === authenticatedUser.id &&
          userConfig.status === UserConfigStatus.ENABLED
      ),
      checked
    );

    setState((currentState) => ({
      ...currentState,
      userConfigsInput: {
        value:
          currentState.userConfigsInput.value.length === 0
            ? [
                {
                  userID: authenticatedUser.id,
                  status: UserConfigStatus.ENABLED,
                },
              ]
            : currentState.userConfigsInput.value.map((userConfig) =>
                userConfig.userID === authenticatedUser.id
                  ? {
                      ...userConfig,
                      status: checked
                        ? UserConfigStatus.ENABLED
                        : UserConfigStatus.DISABLED,
                    }
                  : userConfig
              ),
        hasChanged,
        isValid: true,
      },
    }));
  }

  function handleAddFilter(dataSource: DataSource): void {
    setState((currentState) => {
      const newFilters = [
        ...currentState.filtersInput.value,
        { ...newFilter, dataSource },
      ];

      const hasChanged = !isEqual(newFilters, props.scopedView?.filters);

      return {
        ...currentState,
        filtersInput: {
          value: newFilters,
          hasChanged,
          isValid: false,
        },
      };
    });
  }

  function handleRemoveFilter(index: number): void {
    props.onInteraction({
      type: ScopedViewForm.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED,
      index,
    });

    setState((currentState) => {
      const newFilters = [
        ...currentState.filtersInput.value.slice(0, index),
        ...currentState.filtersInput.value.slice(index + 1),
      ];

      const isValid =
        newFilters.length > 0 &&
        !newFilters.some(
          (filter) => filter.values && filter.values.length === 0
        );

      const hasChanged = !isEqual(newFilters, props.scopedView?.filters);

      return {
        ...currentState,
        filtersInput: {
          value: newFilters,
          hasChanged,
          isValid,
        },
      };
    });
  }

  function handleUpdateFilterName(name: string, index: number): void {
    if (!name) return;

    setState((currentState) => {
      const filter = currentState.filtersInput.value[index];

      props.onInteraction({
        type: ScopedViewForm.INTERACTION_FILTER_NAME_CHANGED,
        index,
        filter: { ...filter, name, values: [] },
      });

      const newFilters = [
        ...currentState.filtersInput.value.slice(0, index),
        {
          ...filter,
          name,
          values: [],
        },
        ...currentState.filtersInput.value.slice(index + 1),
      ];

      const hasChanged = !isEqual(newFilters, props.scopedView?.filters);

      return {
        ...currentState,
        filtersInput: {
          ...currentState.filtersInput,
          value: newFilters,
          hasChanged,
        },
      };
    });
  }

  function handleUpdateFilterOperator(value: string, index: number): void {
    if (!isOperator(value)) return;

    setState((currentState) => {
      const filter = currentState.filtersInput.value[index];

      if (!filter) return currentState;

      let values: QueryFilter["values"] = null;

      switch (value) {
        case Operator.EQUALS:
        case Operator.NOT_EQUALS:
          values = filter.values ?? [];
          break;
        case Operator.CONTAINS:
        case Operator.NOT_CONTAINS:
          values = filter.values && filter.values[0] ? [filter.values[0]] : [];
          break;
        case Operator.NOT_SET:
        case Operator.SET:
          values = null;
          break;
      }

      const newFilters = [
        ...currentState.filtersInput.value.slice(0, index),
        { ...filter, operator: value, values },
        ...currentState.filtersInput.value.slice(index + 1),
      ];

      const isValid = !newFilters.some(
        (filter) => filter.values && filter.values.length === 0
      );

      const hasChanged = !isEqual(newFilters, props.scopedView?.filters);

      return {
        ...currentState,
        filtersInput: {
          value: newFilters,
          hasChanged,
          isValid,
        },
      };
    });
  }

  function handleUpdateFilterValues(values: string[], index: number): void {
    setState((currentState) => {
      const filter = currentState.filtersInput.value[index];

      if (!filter) return currentState;

      const newFilters = [
        ...currentState.filtersInput.value.slice(0, index),
        { ...filter, values },
        ...currentState.filtersInput.value.slice(index + 1),
      ];

      const isValid = !newFilters.some(
        (filter) => filter.values && filter.values.length === 0
      );

      const hasChanged = !isEqual(newFilters, props.scopedView?.filters);

      return {
        ...currentState,
        filtersInput: {
          value: newFilters,
          hasChanged,
          isValid,
        },
      };
    });
  }

  function handleChangeAvailableUserID(userIDs: string[]): void {
    const hasChanged = !isEqual(
      userIDs.slice().sort((a, b) => (a > b ? 1 : -1)),
      props.scopedView?.userConfigs
        .reduce(
          (accum: string[], userConfig) =>
            userConfig.status === UserConfigStatus.DISABLED
              ? [...accum, userConfig.userID]
              : accum,
          []
        )
        .sort((a, b) => (a > b ? 1 : -1))
    );

    setState((prevState) => ({
      ...prevState,
      listKey: LIST_AVAILABLE,
      userConfigsInput: {
        value: [
          ...prevState.userConfigsInput.value.filter(
            (config) => config.status !== UserConfigStatus.DISABLED
          ),
          ...userIDs.map((userID) => ({
            userID,
            status: UserConfigStatus.DISABLED,
          })),
        ].sort((a, b) =>
          getFullName(usersKeyedByID[a.userID]).toLowerCase() >
          getFullName(usersKeyedByID[b.userID]).toLowerCase()
            ? 1
            : -1
        ),
        hasChanged,
        isValid: true,
      },
    }));
  }

  function handleChangeEnabledByDefaultUserID(userIDs: string[]): void {
    const hasChanged = !isEqual(
      userIDs.slice().sort((a, b) => (a > b ? 1 : -1)),
      props.scopedView?.userConfigs
        .reduce(
          (accum: string[], userConfig) =>
            userConfig.status === UserConfigStatus.ENABLED
              ? [...accum, userConfig.userID]
              : accum,
          []
        )
        .sort((a, b) => (a > b ? 1 : -1))
    );

    setState((prevState) => ({
      ...prevState,
      listKey: LIST_ENABLED,
      userConfigsInput: {
        value: [
          ...prevState.userConfigsInput.value.filter(
            (config) => config.status !== UserConfigStatus.ENABLED
          ),
          ...userIDs.map((userID) => ({
            userID,
            status: UserConfigStatus.ENABLED,
          })),
        ].sort((a, b) =>
          getFullName(usersKeyedByID[a.userID]).toLowerCase() >
          getFullName(usersKeyedByID[b.userID]).toLowerCase()
            ? 1
            : -1
        ),
        hasChanged,
        isValid: true,
      },
    }));
  }

  function handleChangeEnforcedUserID(userIDs: string[]): void {
    const hasChanged = !isEqual(
      userIDs.slice().sort((a, b) => (a > b ? 1 : -1)),
      props.scopedView?.userConfigs
        .reduce(
          (accum: string[], userConfig) =>
            userConfig.status === UserConfigStatus.ENFORCED
              ? [...accum, userConfig.userID]
              : accum,
          []
        )
        .sort((a, b) => (a > b ? 1 : -1))
    );

    setState((prevState) => ({
      ...prevState,
      listKey: LIST_ENFORCED,
      userConfigsInput: {
        value: [
          ...prevState.userConfigsInput.value.filter(
            (config) => config.status !== UserConfigStatus.ENFORCED
          ),
          ...userIDs
            .map((userID) => ({
              userID,
              status: UserConfigStatus.ENFORCED,
            }))
            .sort((a, b) =>
              getFullName(usersKeyedByID[a.userID]).toLowerCase() >
              getFullName(usersKeyedByID[b.userID]).toLowerCase()
                ? 1
                : -1
            ),
        ],
        hasChanged,
        isValid: true,
      },
    }));
  }

  function handleChangeStrictlyEnabledUserID(userIDs: string[]): void {
    const hasChanged = !isEqual(
      userIDs.slice().sort((a, b) => (a > b ? 1 : -1)),
      props.scopedView?.userConfigs
        .reduce(
          (accum: string[], userConfig) =>
            userConfig.status === UserConfigStatus.ENABLED_STRICT
              ? [...accum, userConfig.userID]
              : accum,
          []
        )
        .sort((a, b) => (a > b ? 1 : -1))
    );

    setState((prevState) => ({
      ...prevState,
      listKey: LIST_ENABLED_STRICT,
      userConfigsInput: {
        value: [
          ...prevState.userConfigsInput.value.filter(
            (config) => config.status !== UserConfigStatus.ENABLED_STRICT
          ),
          ...userIDs.map((userID) => ({
            userID,
            status: UserConfigStatus.ENABLED_STRICT,
          })),
        ].sort((a, b) =>
          getFullName(usersKeyedByID[a.userID]).toLowerCase() >
          getFullName(usersKeyedByID[b.userID]).toLowerCase()
            ? 1
            : -1
        ),
        hasChanged,
        isValid: true,
      },
    }));
  }

  function handleDeleteUserID(userID: string): void {
    setState((currentState) => {
      const newIDs = currentState.userConfigsInput.value.filter(
        (config) => config.userID !== userID
      );

      const hasChanged = !isEqual(
        newIDs,
        props.scopedView?.userConfigs.map((userConfig) => userConfig.userID)
      );

      return {
        ...currentState,
        userConfigsInput: {
          value: newIDs,
          hasChanged,
          isValid: true,
        },
      };
    });
  }

  function handleToggleApplyToNewUsers(listKey: string): void {
    switch (listKey) {
      case LIST_AVAILABLE: {
        const applyToNewUserStatus =
          state.applyToNewUsersStatusInput.value === UserConfigStatus.DISABLED
            ? null
            : UserConfigStatus.DISABLED;

        const applyToNewUserStatusHasChanged = !isEqual(
          applyToNewUserStatus,
          props.scopedView?.applyToNewUserStatus
        );

        mergeState({
          applyToNewUsersStatusInput: {
            value: applyToNewUserStatus,
            hasChanged: applyToNewUserStatusHasChanged,
            isValid: true,
          },
          listKey,
        });
        break;
      }
      case LIST_ENABLED: {
        const applyToNewUserStatus =
          state.applyToNewUsersStatusInput.value === UserConfigStatus.ENABLED
            ? null
            : UserConfigStatus.ENABLED;

        const applyToNewUserStatusHasChanged = !isEqual(
          applyToNewUserStatus,
          props.scopedView?.applyToNewUserStatus
        );

        mergeState({
          applyToNewUsersStatusInput: {
            value: applyToNewUserStatus,
            hasChanged: applyToNewUserStatusHasChanged,
            isValid: true,
          },
          listKey,
        });
        break;
      }
      case LIST_ENFORCED: {
        const applyToNewUserStatus =
          state.applyToNewUsersStatusInput.value === UserConfigStatus.ENFORCED
            ? null
            : UserConfigStatus.ENFORCED;

        const applyToNewUserStatusHasChanged = !isEqual(
          applyToNewUserStatus,
          props.scopedView?.applyToNewUserStatus
        );

        mergeState({
          applyToNewUsersStatusInput: {
            value: applyToNewUserStatus,
            hasChanged: applyToNewUserStatusHasChanged,
            isValid: true,
          },
          listKey,
        });
        break;
      }
    }
  }

  function handleChangeAllUserConfigs(): void {
    switch (state.listKey) {
      case LIST_AVAILABLE: {
        const userConfigs =
          availableLength === props.users.length
            ? []
            : props.users.map((user) => ({
                userID: user.id,
                status: UserConfigStatus.DISABLED,
              }));

        const hasChanged = !isEqual(
          userConfigs.slice().sort((a, b) => (a.userID > b.userID ? -1 : 1)),
          props.scopedView?.userConfigs
            .reduce(
              (accum: UserConfig[], userConfig) =>
                userConfig.status === UserConfigStatus.DISABLED
                  ? [...accum, userConfig]
                  : accum,
              []
            )
            .sort((a, b) => (a.userID > b.userID ? -1 : 1))
        );

        mergeState({
          userConfigsInput: {
            value: userConfigs,
            hasChanged,
            isValid: true,
          },
        });
        break;
      }
      case LIST_ENABLED: {
        const userConfigs =
          defaultEnabledLength === props.users.length
            ? []
            : props.users.map((user) => ({
                userID: user.id,
                status: UserConfigStatus.ENABLED,
              }));

        const hasChanged = !isEqual(
          userConfigs.slice().sort((a, b) => (a.userID > b.userID ? -1 : 1)),
          props.scopedView?.userConfigs
            .reduce(
              (accum: UserConfig[], userConfig) =>
                userConfig.status === UserConfigStatus.ENABLED
                  ? [...accum, userConfig]
                  : accum,
              []
            )
            .sort((a, b) => (a.userID > b.userID ? -1 : 1))
        );

        mergeState({
          userConfigsInput: {
            value: userConfigs,
            hasChanged: hasChanged,
            isValid: true,
          },
        });
        break;
      }
      case LIST_ENABLED_STRICT: {
        const userConfigs =
          strictlyEnabledLength === props.users.length
            ? []
            : props.users.map((user) => ({
                userID: user.id,
                status: UserConfigStatus.ENABLED_STRICT,
              }));

        const hasChanged = !isEqual(
          userConfigs.slice().sort((a, b) => (a.userID > b.userID ? -1 : 1)),
          props.scopedView?.userConfigs
            .reduce(
              (accum: UserConfig[], userConfig) =>
                userConfig.status === UserConfigStatus.ENABLED_STRICT
                  ? [...accum, userConfig]
                  : accum,
              []
            )
            .sort((a, b) => (a.userID > b.userID ? -1 : 1))
        );

        mergeState({
          userConfigsInput: {
            value: userConfigs,
            hasChanged: hasChanged,
            isValid: true,
          },
        });
        break;
      }
      case LIST_ENFORCED: {
        const userConfigs =
          enforcedLength === props.users.length - 1
            ? []
            : props.users.reduce((accum: UserConfig[], user) => {
                if (
                  user.id ===
                  (props.scopedView?.createdBy
                    ? props.scopedView.createdBy
                    : authenticatedUser.id)
                )
                  return accum;

                return [
                  ...accum,
                  {
                    userID: user.id,
                    status: UserConfigStatus.ENFORCED,
                  },
                ];
              }, []);

        const hasChanged = !isEqual(
          userConfigs.slice().sort((a, b) => (a.userID > b.userID ? -1 : 1)),
          props.scopedView?.userConfigs
            .reduce(
              (accum: UserConfig[], userConfig) =>
                userConfig.status === UserConfigStatus.ENFORCED
                  ? [...accum, userConfig]
                  : accum,
              []
            )
            .sort((a, b) => (a.userID > b.userID ? -1 : 1))
        );

        mergeState({
          userConfigsInput: {
            value: userConfigs,
            hasChanged: hasChanged,
            isValid: true,
          },
        });
        break;
      }
    }
  }

  function renderChangeAllUsersButton() {
    let text = copyText.addAllUsersLabel;
    switch (state.listKey) {
      case LIST_AVAILABLE: {
        if (availableLength === props.users.length) {
          text = copyText.removeAllUsersLabel;
        } else {
          text = copyText.addAllUsersLabel;
        }
        break;
      }
      case LIST_ENABLED: {
        if (defaultEnabledLength === props.users.length) {
          text = copyText.removeAllUsersLabel;
        } else {
          text = copyText.addAllUsersLabel;
        }
        break;
      }
      case LIST_ENABLED_STRICT: {
        if (strictlyEnabledLength === props.users.length - 1) {
          text = copyText.removeAllUsersLabel;
        } else {
          text = copyText.addAllUsersLabel;
        }
        break;
      }
      case LIST_ENFORCED: {
        if (enforcedLength === props.users.length - 1) {
          text = copyText.removeAllUsersLabel;
        } else {
          text = copyText.addAllUsersLabel;
        }
        break;
      }
    }

    return (
      <Button
        iconStart={
          <Icon icon={text === copyText.addAllUsersLabel ? faPlus : faMinus} />
        }
        size="small"
        type="button"
        width={160}
        onClick={handleChangeAllUserConfigs}
      >
        {text}
      </Button>
    );
  }

  function handleClose(): void {
    setState(initialState);
    props.onInteraction({
      type: ScopedViewForm.INTERACTION_CANCEL_BUTTON_CLICKED,
    });
  }

  //
  // Submission Handlers
  //

  function handleSubmit(event: React.MouseEvent<HTMLButtonElement>): void {
    event.preventDefault();
    if (props.action === Action.CREATE) {
      props.onInteraction({
        type: ScopedViewForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE,
        applyToNewUserStatus: state.applyToNewUsersStatusInput.value,
        filters: state.filtersInput.value,
        name: state.nameInput.value,
        userConfigs: state.userConfigsInput.value,
      });
    } else if (props.action === Action.UPDATE) {
      if (!props.scopedView) return;

      props.onInteraction({
        type: ScopedViewForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE,
        scopedViewID: props.scopedView.id,
        ...(state.applyToNewUsersStatusInput.hasChanged
          ? {
              applyToNewUserStatus: state.applyToNewUsersStatusInput.value,
            }
          : {}),
        ...(state.filtersInput.hasChanged
          ? { filters: state.filtersInput.value }
          : {}),
        ...(state.nameInput.hasChanged ? { name: state.nameInput.value } : {}),
        ...(state.userConfigsInput.hasChanged
          ? {
              userConfigs: state.userConfigsInput.value,
            }
          : {}),
      });
    }
  }

  //
  // Validations
  //

  const hasChanged = Object.values(state).some((input) => input.hasChanged);

  const isValid = Object.values(state).every((input) =>
    [LIST_AVAILABLE, LIST_ENABLED, LIST_ENABLED_STRICT, LIST_ENFORCED].includes(
      input
    )
      ? true
      : input.isValid
  );

  const canSubmit = hasChanged && isValid;

  //
  // Render
  //

  const selectedDimensions = state.filtersInput.value.map((filter) => ({
    dataSource: filter.dataSource,
    name: filter.name,
  }));

  const selectedDataSources = state.filtersInput.value
    .reduce((accum: { label: string; value: DataSource }[], filter) => {
      if (accum.some((option) => filter.dataSource === option.value)) {
        return accum;
      } else {
        return [
          ...accum,
          {
            label: copyText[`dataSource_${filter.dataSource}_label`],
            value: filter.dataSource,
          },
        ];
      }
    }, [])
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  const usersKeyedByID = keyBy(props.users, "id");

  const availableUsersOptions = props.users.reduce(
    (accum: Option[], user) =>
      state.userConfigsInput.value.some(
        (userConfig) =>
          user.id === userConfig.userID &&
          userConfig.status !== UserConfigStatus.DISABLED
      )
        ? accum
        : [
            ...accum,
            {
              label: `${getFullName(user)} (${user.email})`,
              value: user.id,
            },
          ],
    []
  );

  const defaultEnabledUsersOptions = props.users.reduce(
    (accum: Option[], user) =>
      state.userConfigsInput.value.some(
        (userConfig) =>
          user.id === userConfig.userID &&
          userConfig.status !== UserConfigStatus.ENABLED
      )
        ? accum
        : [
            ...accum,
            {
              label: `${getFullName(user)} (${user.email})`,
              value: user.id,
            },
          ],
    []
  );

  const enforcedUsersOptions = props.users.reduce(
    (accum: Option[], user) =>
      state.userConfigsInput.value.some(
        (userConfig) =>
          user.id === userConfig.userID &&
          userConfig.status !== UserConfigStatus.ENFORCED
      ) ||
      user.id ===
        (props.scopedView?.createdBy
          ? props.scopedView.createdBy
          : authenticatedUser.id)
        ? accum
        : [
            ...accum,
            {
              label: `${getFullName(user)} (${user.email})`,
              value: user.id,
            },
          ],
    []
  );

  const strictEnabledUsersOptions = props.users.reduce(
    (accum: Option[], user) =>
      state.userConfigsInput.value.some(
        (userConfig) =>
          user.id === userConfig.userID &&
          userConfig.status !== UserConfigStatus.ENABLED_STRICT
      )
        ? accum
        : [
            ...accum,
            {
              label: `${getFullName(user)} (${user.email})`,
              value: user.id,
            },
          ],
    []
  );

  const availableLength = state.userConfigsInput.value.filter(
    (config) => config.status === UserConfigStatus.DISABLED
  ).length;
  const defaultEnabledLength = state.userConfigsInput.value.filter(
    (config) => config.status === UserConfigStatus.ENABLED
  ).length;
  const enforcedLength = state.userConfigsInput.value.filter(
    (config) => config.status === UserConfigStatus.ENFORCED
  ).length;
  const strictlyEnabledLength = state.userConfigsInput.value.filter(
    (config) => config.status === UserConfigStatus.ENABLED_STRICT
  ).length;

  function getAvailableDimensions(filter: ScopedViewFilter): string[] {
    return props.availableDimensionsMap &&
      props.availableDimensionsMap[filter.dataSource]
      ? (props.availableDimensionsMap[filter.dataSource] ?? [])
      : [];
  }

  function renderUserIDs(listKey: string): (JSX.Element | undefined)[] {
    let userConfigs: UserConfig[] = [];

    switch (listKey) {
      case LIST_AVAILABLE: {
        userConfigs = state.userConfigsInput.value.filter(
          (userConfig) => userConfig.status === UserConfigStatus.DISABLED
        );

        break;
      }
      case LIST_ENABLED: {
        userConfigs = state.userConfigsInput.value.filter(
          (userConfig) => userConfig.status === UserConfigStatus.ENABLED
        );
        break;
      }
      case LIST_ENABLED_STRICT: {
        userConfigs = state.userConfigsInput.value.filter(
          (userConfig) => userConfig.status === UserConfigStatus.ENABLED_STRICT
        );
        break;
      }
      case LIST_ENFORCED: {
        userConfigs = state.userConfigsInput.value.filter(
          (userConfig) => userConfig.status === UserConfigStatus.ENFORCED
        );
        break;
      }
    }

    if (userConfigs.length === 0) return [];

    return userConfigs.map((userConfig) => {
      const user = usersKeyedByID[userConfig.userID];

      if (!user) {
        activityTracker.captureMessage(
          `Data integrity issue detected for scoped view id: ${props.scopedView?.id} & userID: ${userConfig.userID}`
        );
        return;
      }

      return (
        <Box
          key={userConfig.userID}
          display="table-row"
          marginBottom={theme.space_xs}
          overflow="scroll"
        >
          <Box display="table-cell" paddingRight={theme.space_lg}>
            <Text fontSize={theme.fontSize_base}>
              {getFullName(user).length > 0 ? getFullName(user) : user.email}
            </Text>
          </Box>
          <Flex display="table-cell" justifyContent="space-between" width={300}>
            <Box paddingRight={theme.space_sm}>
              <Text fontSize={theme.fontSize_base}>
                {truncate(user.email, { length: 30 })}
              </Text>
            </Box>
            <Button
              iconStart={<IconTimes />}
              size="tiny"
              type="button"
              onClick={() => handleDeleteUserID(userConfig.userID)}
            />
          </Flex>
        </Box>
      );
    });
  }

  const isShared =
    props.type === ScopedViewType.PRIVATE &&
    props.scopedView?.type === ScopedViewType.ADMIN;

  return (
    <Form>
      <Flex justifyContent="space-between">
        {/* Name */}
        <Box minWidth="400px">
          <FormField
            disabled={isShared}
            name="name"
            input={TextInput}
            label={copyText.nameInputLabel}
            value={state.nameInput.value}
            onChange={handleChangeName}
          />
        </Box>
        {/* On by default */}
        {props.type === ScopedViewType.ADMIN ? null : (
          <FormField label={copyText.enabledByDefaultLabel}>
            <Flex justifyContent="flex-end">
              <Switch
                name="enabledByDefault"
                checked={state.userConfigsInput.value.some(
                  (userConfig) =>
                    userConfig.userID === authenticatedUser.id &&
                    userConfig.status === UserConfigStatus.ENABLED
                )}
                onChange={handleEnabledByDefaultChange}
              />
            </Flex>
          </FormField>
        )}
      </Flex>
      {/* Filters */}
      <Text appearance="h4" marginBottom={theme.space_sm}>
        {copyText.filtersHeader}
      </Text>
      {selectedDataSources.map((dataSourceOption, dataSourceIndex) => {
        return (
          <React.Fragment key={dataSourceIndex}>
            <Flex>
              <Flex
                alignItems="center"
                justifyContent="center"
                transform="rotate(-90deg)"
                width="60px"
              >
                <Text align="center" bold textDecoration="underline">
                  {dataSourceOption.label}
                </Text>
              </Flex>

              <Flex direction="column">
                {state.filtersInput.value.map((filter, filterIndex) => {
                  if (filter.dataSource !== dataSourceOption.value) return null;

                  const availableDimensions = getAvailableDimensions(
                    filter
                  ).map((filter) => ({
                    label: filter,
                    value: filter,
                  }));
                  const filteredDimension = availableDimensions.filter(
                    (option) =>
                      !selectedDimensions.some(
                        (dimension) =>
                          option.value === dimension.name &&
                          dataSourceOption.value === dimension.dataSource
                      )
                  );

                  const groupedDimensions = groupOptionsByPreferences(
                    filteredDimension,
                    props.dimensionPreferences,
                    filter.dataSource,
                    gatekeeper.isLabelPreferenceAdmin
                  );

                  const filterOption = availableDimensions.find(
                    (option) => option.value === filter.name
                  );

                  const operatorOption = operatorOptions.find(
                    (option) => option.value === filter.operator
                  );

                  const currentValues = props.dimensionValuesMap[
                    filter.dataSource
                  ]
                    ? props.dimensionValuesMap[filter.dataSource][filter.name]
                    : [];

                  const filterOptions = uniq([
                    ...customFilter.slice().sort(),
                    ...(currentValues ?? []).slice().sort(),
                  ]).map((value) => ({ label: value, value }));

                  return (
                    <Flex key={`${filterIndex}-${filter.name}`}>
                      <Flex direction="column" width="100%">
                        <Flex marginTop={theme.space_sm}>
                          <Box marginRight={theme.space_md} width={190}>
                            <Select
                              compact
                              defaultValue={filterOption}
                              disabled={isShared}
                              hideSelectedOptions
                              options={groupedDimensions}
                              placeholder={copyText.selectFilterNameLabel}
                              searchable
                              onChange={(option) =>
                                option
                                  ? handleUpdateFilterName(
                                      option.value,
                                      filterIndex
                                    )
                                  : noop
                              }
                            />
                          </Box>
                          <Box marginRight={theme.space_md} width={140}>
                            <Select
                              compact
                              defaultValue={operatorOption}
                              disabled={isShared}
                              options={operatorOptions}
                              onChange={(option) =>
                                option
                                  ? handleUpdateFilterOperator(
                                      option.value,
                                      filterIndex
                                    )
                                  : noop
                              }
                            />
                          </Box>
                          <Box width={240} onClick={() => isShared}>
                            <SelectDropdownFilter
                              compact
                              filter={filter}
                              operator={
                                operatorOption?.value ?? filter?.operator
                              }
                              options={filterOptions}
                              isLoading={props.isLoadingDimensionValuesMap}
                              showInputStyle
                              onChange={(value: string | string[]) => {
                                handleUpdateFilterValues(
                                  typeof value === "string" ? [value] : value,
                                  filterIndex
                                );
                              }}
                              onCreateOption={(value: string) => {
                                setCustomFilter((currentState) => [
                                  ...currentState,
                                  value,
                                ]);
                              }}
                            />
                          </Box>
                          <Box
                            alignSelf="end"
                            marginBottom={theme.space_xxs}
                            marginLeft={theme.space_sm}
                          >
                            <Tooltip
                              content={copyText.removeFilterTooltipCaption}
                              hide={isShared}
                            >
                              <Button
                                disabled={isShared}
                                iconStart={<IconTimes />}
                                size="small"
                                type="button"
                                onClick={() => handleRemoveFilter(filterIndex)}
                              />
                            </Tooltip>
                          </Box>
                        </Flex>
                      </Flex>
                    </Flex>
                  );
                })}
                <Button
                  disabled={isShared}
                  iconStart={<Icon icon={faPlus} />}
                  marginTop={theme.space_sm}
                  size="tiny"
                  type="button"
                  width={120}
                  onClick={() => handleAddFilter(dataSourceOption.value)}
                >
                  {copyText.addFilterButtonLabel}
                </Button>
              </Flex>
            </Flex>
            {dataSourceIndex !== dataSourceSelectOptions.length - 1 && (
              <Divider margin={theme.space_sm} />
            )}
          </React.Fragment>
        );
      })}
      {selectedDataSources.length !== dataSourceSelectOptions.length && (
        <Flex marginVertical={theme.space_sm}>
          <SelectDropdown
            disabled={isShared}
            options={dataSourceSelectOptions.filter(
              (option) =>
                !selectedDataSources.some(
                  (dataSource) => option.value === dataSource.value
                )
            )}
            placement="bottom-start"
            onChange={(dataSource) =>
              dataSource
                ? handleAddFilter(
                    getEnumAtKey(DataSource, dataSource) ?? DataSource.BILLING
                  )
                : noop
            }
          >
            <Button
              disabled={isShared}
              iconStart={<Icon icon={faPlus} />}
              size="small"
              type="button"
            >
              {copyText.addDatasetButtonLabel}
            </Button>
          </SelectDropdown>
        </Flex>
      )}
      {/* Users */}
      {props.type === ScopedViewType.ADMIN && (
        <Box>
          <Text appearance="h4" marginBottom={theme.space_md}>
            {copyText.usersHeader}
          </Text>
          <Text appearance="h5">
            {`${copyText.availableForHeader} (${availableLength})`}
          </Text>
          <Flex alignItems="center" marginBottom={theme.space_md}>
            <Box width={360}>
              <SelectCheckbox
                hideValues
                options={availableUsersOptions}
                placeholder={copyText.selectUsersPlaceholder}
                selectedValues={state.userConfigsInput.value
                  .filter(
                    (userConfig) =>
                      userConfig.status === UserConfigStatus.DISABLED
                  )
                  .map((userConfig) => userConfig.userID)}
                onChange={handleChangeAvailableUserID}
              />
            </Box>
            <Flex alignItems="center" marginLeft={theme.space_md}>
              <Switch
                checked={
                  state.applyToNewUsersStatusInput.value ===
                  UserConfigStatus.DISABLED
                }
                onChange={() => handleToggleApplyToNewUsers(LIST_AVAILABLE)}
              />
              <Text marginLeft={theme.space_sm} marginVertical={0}>
                {copyText.switchApplyToNewUsersLabel}
              </Text>
            </Flex>
          </Flex>
          <Text appearance="h5">
            {`${copyText.enforcedForHeader} (${enforcedLength})`}
          </Text>
          <Flex alignItems="center" marginBottom={theme.space_md}>
            <Box width={360}>
              <SelectCheckbox
                hideValues
                options={enforcedUsersOptions}
                placeholder={copyText.selectUsersPlaceholder}
                selectedValues={state.userConfigsInput.value
                  .filter(
                    (userConfig) =>
                      userConfig.status === UserConfigStatus.ENFORCED
                  )
                  .map((userConfig) => userConfig.userID)}
                onChange={handleChangeEnforcedUserID}
              />
            </Box>
            <Flex alignItems="center" marginLeft={theme.space_md}>
              <Switch
                checked={
                  state.applyToNewUsersStatusInput.value ===
                  UserConfigStatus.ENFORCED
                }
                onChange={() => handleToggleApplyToNewUsers(LIST_ENFORCED)}
              />
              <Text marginHorizontal={theme.space_sm} marginVertical={0}>
                {copyText.switchApplyToNewUsersLabel}
              </Text>
            </Flex>
            <Tooltip
              content={copyText.enforcedForTooltip}
              icon={faInfoCircle}
            />
          </Flex>
          <Text appearance="h5">
            {`${copyText.enabledByDefaultForHeader} (${defaultEnabledLength})`}
          </Text>
          <Flex marginBottom={theme.space_md}>
            <Box width={360}>
              <SelectCheckbox
                hideValues
                options={defaultEnabledUsersOptions}
                placeholder={copyText.selectUsersPlaceholder}
                selectedValues={state.userConfigsInput.value
                  .filter(
                    (userConfig) =>
                      userConfig.status === UserConfigStatus.ENABLED
                  )
                  .map((userConfig) => userConfig.userID)}
                onChange={handleChangeEnabledByDefaultUserID}
              />
            </Box>
            <Flex alignItems="center" marginLeft={theme.space_md}>
              <Switch
                checked={
                  state.applyToNewUsersStatusInput.value ===
                  UserConfigStatus.ENABLED
                }
                onChange={() => handleToggleApplyToNewUsers(LIST_ENABLED)}
              />
              <Text marginLeft={theme.space_sm} marginVertical={0}>
                {copyText.switchApplyToNewUsersLabel}
              </Text>
            </Flex>
          </Flex>
          <Text appearance="h5">
            {`${copyText.strictEnabledForHeader} (${strictlyEnabledLength})`}
          </Text>
          <Flex marginBottom={theme.space_md}>
            <Box width={360}>
              <SelectCheckbox
                hideValues
                options={strictEnabledUsersOptions}
                placeholder={copyText.selectUsersPlaceholder}
                selectedValues={state.userConfigsInput.value
                  .filter(
                    (userConfig) =>
                      userConfig.status === UserConfigStatus.ENABLED_STRICT
                  )
                  .map((userConfig) => userConfig.userID)}
                onChange={handleChangeStrictlyEnabledUserID}
              />
            </Box>
            <Flex alignItems="center" marginLeft={theme.space_md}>
              <Switch
                checked={
                  state.applyToNewUsersStatusInput.value ===
                  UserConfigStatus.ENABLED_STRICT
                }
                onChange={() =>
                  handleToggleApplyToNewUsers(LIST_ENABLED_STRICT)
                }
              />
              <Text marginLeft={theme.space_sm} marginVertical={0}>
                {copyText.switchApplyToNewUsersLabel}
              </Text>
            </Flex>
          </Flex>
          <Box display="table" width="100%" marginBottom={theme.space_xxl}>
            <Box display="table-row">
              <Box display="table-header-group">
                <Flex>
                  <Text
                    bold
                    color={
                      state.listKey === LIST_AVAILABLE
                        ? theme.primary_color_text
                        : theme.text_color_secondary
                    }
                    cursor="pointer"
                    onClick={() => mergeState({ listKey: LIST_AVAILABLE })}
                  >
                    {`${copyText.availableForButtonLabel} (${availableLength}) /`}
                  </Text>
                  <Text
                    bold
                    color={
                      state.listKey === LIST_ENFORCED
                        ? theme.primary_color_text
                        : theme.text_color_secondary
                    }
                    cursor="pointer"
                    marginLeft={theme.space_xxs}
                    onClick={() => mergeState({ listKey: LIST_ENFORCED })}
                  >
                    {`${copyText.enforcedForButtonLabel} (${enforcedLength}) /`}
                  </Text>
                  <Text
                    bold
                    color={
                      state.listKey === LIST_ENABLED
                        ? theme.primary_color_text
                        : theme.text_color_secondary
                    }
                    cursor="pointer"
                    marginLeft={theme.space_xxs}
                    onClick={() => mergeState({ listKey: LIST_ENABLED })}
                  >
                    {`${copyText.enabledByDefaultForButtonLabel} (${defaultEnabledLength}) /`}
                  </Text>
                  <Text
                    bold
                    color={
                      state.listKey === LIST_ENABLED_STRICT
                        ? theme.primary_color_text
                        : theme.text_color_secondary
                    }
                    cursor="pointer"
                    marginLeft={theme.space_xxs}
                    onClick={() => mergeState({ listKey: LIST_ENABLED_STRICT })}
                  >
                    {`${copyText.strictEnabledForButtonLabel} (${strictlyEnabledLength})`}
                  </Text>
                </Flex>
              </Box>
              <Box display="table-cell">
                <Flex width="100%" justifyContent={"flex-end"}>
                  {renderChangeAllUsersButton()}
                </Flex>
              </Box>
            </Box>
            <Box display="table-row">
              <Box
                display="table-cell"
                marginTop={theme.space_lg}
                paddingRight={theme.space_lg}
              >
                <Text bold>{copyText.tableHeaderName}</Text>
              </Box>
              <Box display="table-cell">
                <Text bold>{copyText.tableHeaderEmail}</Text>
              </Box>
            </Box>
            {renderUserIDs(state.listKey)}
          </Box>
        </Box>
      )}
      <Flex justifyContent="end">
        <Button
          marginRight={theme.space_xs}
          secondary
          type="reset"
          width={100}
          onClick={handleClose}
        >
          {copyText.cancelButtonLabel}
        </Button>
        <Button
          disabled={!canSubmit || props.isLoading || props.isProcessing}
          primary
          width={100}
          onClick={handleSubmit}
          type="button"
        >
          {props.isProcessing ? <LoadingSpinner /> : copyText.submitButtonLabel}
        </Button>
      </Flex>
    </Form>
  );
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace ScopedViewForm {
  export const INTERACTION_CANCEL_BUTTON_CLICKED = `ScopedViewForm.INTERACTION_CANCEL_BUTTON_CLICKED`;
  export const INTERACTION_FILTER_NAME_CHANGED = `GlobalFilterModal.INTERACTION_FILTER_NAME_CHANGED`;
  export const INTERACTION_REMOVE_FILTER_BUTTON_CLICKED = `GlobalFilterModal.INTERACTION_REMOVE_FILTER_BUTTON_CLICKED`;
  export const INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE = `ScopedViewForm.INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE`;
  export const INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE = `ScopedViewForm.INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE`;

  interface InteractionCancelButtonClicked {
    type: typeof INTERACTION_CANCEL_BUTTON_CLICKED;
  }

  interface InteractionFilterNameChanged {
    type: typeof INTERACTION_FILTER_NAME_CHANGED;
    index: number;
    filter: ScopedViewFilter;
  }

  interface InteractionRemoveFilterButtonClicked {
    type: typeof INTERACTION_REMOVE_FILTER_BUTTON_CLICKED;
    index: number;
  }

  interface InteractionSubmitButtonClickedCreate {
    type: typeof INTERACTION_SUBMIT_BUTTON_CLICKED_CREATE;
    applyToNewUserStatus: UserConfigStatus | null;
    filters: ScopedViewFilter[];
    name: string;
    userConfigs: UserConfig[];
  }

  interface InteractionSubmitButtonClickedUpdate {
    type: typeof INTERACTION_SUBMIT_BUTTON_CLICKED_UPDATE;
    applyToNewUserStatus?: UserConfigStatus | null;
    scopedViewID: string;
    filters?: ScopedViewFilter[];
    name?: string;
    scopedViewType?: ScopedViewType;
    userConfigs?: UserConfig[];
  }

  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionFilterNameChanged
    | InteractionRemoveFilterButtonClicked
    | InteractionSubmitButtonClickedCreate
    | InteractionSubmitButtonClickedUpdate;
}

export default ScopedViewForm;
