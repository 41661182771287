import styled from "@emotion/styled";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import Icon from "@ternary/web-ui-lib/components/Icon";
import { createElement } from "react";

type AlignOptions = "center" | "left" | "right";

interface TableProps {
  compact?: boolean;
}

export const StyledTable = styled("table")<TableProps>(({ compact }) => ({
  borderSpacing: compact ? 0 : 1,
}));

interface HeaderProps {
  compact?: boolean;
}

export const HeaderRow = styled("tr")<HeaderProps>(({ compact, theme }) => ({
  backgroundColor: theme.table_header_background_color,
  color: theme.text_color_inverse,
  fontSize: "0.75rem",
  height: "40px",
  borderRadius: theme.borderRadius_2,

  // NOTE: The order of these matters

  "th:first-of-type": {
    borderRadius: compact ? "8px 0 0 0" : "8px 0 0 8px",
  },

  "th:last-child": {
    borderRadius: compact ? "0 8px 0 0" : "0 8px 8px 0",
  },

  "th:only-child": {
    borderRadius: compact ? "8px 8px 0 0" : "8px",
  },
}));

interface FooterProps {
  compact?: boolean;
  footerDelta?: boolean;
}

export const FooterRow = styled("tr")<FooterProps>(
  ({ compact, footerDelta, theme }) => ({
    color: theme.text_color_inverse,
    fontSize: "0.75rem",
    height: footerDelta ? "60px" : "40px",
    // NOTE: The order of these matters

    "td:first-of-type": {
      borderRadius: compact ? "0 0 0 8px" : "8px 0 0 8px",
    },

    "td:last-child": {
      borderRadius: compact ? "0 0 8px 0" : "0 8px 8px 0",
    },

    "td:only-child": {
      borderRadius: compact ? "0 0 8px 8px" : "8px",
    },
  })
);

interface HeaderCellProps {
  align?: AlignOptions;
  sortable: boolean;
  left?: number;
  isPinned?: boolean;
}

export const HeaderCell = styled("th")<HeaderCellProps>(
  ({ align, sortable, theme, left, isPinned }) => ({
    backgroundColor: theme.table_header_background_color,
    color: theme.text_color,
    fontWeight: "normal",
    padding: theme.space_sm,
    ...(sortable ? { cursor: "pointer" } : {}),
    ...(isPinned ? { position: "sticky", zIndex: 1 } : {}),
    ...(typeof left === "number"
      ? { position: "sticky", left, zIndex: 3 }
      : {}),

    "> div": {
      justifyContent: (() => {
        switch (align) {
          case "center":
            return "center";
          case "left":
            return "flex-start";
          case "right":
            return "flex-end";
        }
      })(),
    },
  })
);

interface RowProps {
  alternate?: boolean;
  clickable?: boolean;
  compact?: boolean;
  footer?: boolean;
  height?: string;
}

export const Row = styled("tr")<RowProps>(
  ({ alternate, clickable, compact, footer, theme, height }) => ({
    ...(clickable ? { cursor: "pointer" } : {}),
    fontSize: theme.fontSize_ui,
    height: height ? height : compact ? "36px" : "56px",
    ...(footer ? {} : { marginTop: compact ? "2px" : theme.space_xxs }),
    position: "relative",

    ...(alternate
      ? {
          ":nth-of-type(even) td": {
            backgroundColor: theme.table_row_background_color_hover,
          },
        }
      : {}),

    "td:first-of-type": {
      borderRadius: compact ? "unset" : "8px 0 0 8px",
    },

    "td:last-child": {
      borderRadius: compact ? "unset" : "0 8px 8px 0",
    },

    "&:hover > *": {
      ...(clickable
        ? { backgroundColor: theme.table_row_background_color_hover }
        : {}),
    },
  })
);

interface CellProps {
  align?: AlignOptions;
  disabled?: boolean;
  footer?: boolean;
  isCellMasked?: boolean;
  isSelected: boolean;
  left?: number;
}

export const Cell = styled("td")<CellProps>(
  ({ align, footer, disabled, isCellMasked, isSelected, theme, left }) => ({
    alignItems: "center",
    backgroundColor: isSelected
      ? theme.table_cell_background_color_selected
      : theme.table_cell_background_color,

    border: footer ? `1px solid ${theme.data_table_border}` : "none",
    color: isSelected
      ? theme.text_color_inverse
      : disabled
        ? theme.text_color_disabled
        : theme.text_color,
    display: "flex",
    justifyContent: (() => {
      switch (align) {
        case "center":
          return "center";
        case "left":
          return "flex-start";
        case "right":
          return "flex-end";
      }
    })(),
    padding: theme.space_sm,

    ...(align && { textAlign: align }),
    ...(typeof left === "number"
      ? { position: "sticky", left, zIndex: 1 }
      : {}),

    ...(isCellMasked
      ? { backgroundColor: theme.table_cell_background_color_masked }
      : {}),
  })
);

export const FooterCell = styled("td")<CellProps>(
  ({ align, isSelected, theme, left }) => ({
    alignItems: "center",
    backgroundColor: theme.table_footer_background_color,
    color: isSelected ? theme.text_color_inverse : theme.text_color,
    display: "flex",
    fontSize: theme.fontSize_ui,
    justifyContent: (() => {
      switch (align) {
        case "center":
          return "center";
        case "left":
          return "flex-start";
        case "right":
          return "flex-end";
      }
    })(),
    padding: theme.space_sm,
    ...(align && { textAlign: align }),
    ...(typeof left === "number" ? { position: "sticky", left } : {}),
  })
);

export const ActionMenuButton = styled("button")(({ theme }) => ({
  backgroundColor: theme.panel_backgroundColor,
  border: `1px solid ${theme.secondary_color_border}`,
  borderRadius: theme.borderRadius_2,
  color: theme.text_color,
  cursor: "pointer",
  height: "2rem",
  outline: 0,
  padding: "0.375rem",
  width: "2rem",

  "&:hover": {
    backgroundColor: theme.secondary_color_background,
  },

  "&:focus": {
    backgroundColor: "#B5D7FF",
  },

  '& [role="img"]': {
    cursor: "pointer",
    height: "0.75rem",
    width: "0.75rem",
  },
}));

ActionMenuButton.defaultProps = {
  children: createElement(Icon, { icon: faEllipsisV }),
};
