import copyText from "@/constants/copyText";

export function getFilterValuesButtonLabel(values: string[]): string {
  return values
    .reduce((accum: string[], value) => {
      if (accum.length < 3) {
        return [...accum, value];
      }

      if (accum.length === 3) {
        return [...accum, `${values.length - 3} ${copyText.moreOptionsLabel}`];
      }

      return accum;
    }, [])
    .join(" or ");
}
