import { useTheme } from "@emotion/react";
import { TimeGranularity } from "@ternary/api-lib/constants/enums";
import {
  areaStyleProps,
  cartesianStyleProps,
  xAxisStyleProps,
  yAxisStyleProps,
} from "@ternary/api-lib/ui-lib/charts/styles";
import { getFormatForGranularity } from "@ternary/api-lib/ui-lib/utils/dates";
import { formatTimestamp } from "@ternary/web-ui-lib/charts/utils";
import Box from "@ternary/web-ui-lib/components/Box";
import { formatCurrencyRounded } from "@ternary/web-ui-lib/utils/formatNumber";
import { isAfter, sub } from "date-fns";
import { keyBy } from "lodash";
import React from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  ErrorBar,
  Line,
  ReferenceDot,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import SimpleChartTooltip from "../../../components/SimpleChartTooltip";

export type AnomalyChartData = {
  amount: number;
  time: string;
  error?: number[];
};

type PredictionBoundData = {
  lowerBound: number;
  upperBound: number;
  timestamp: string;
};

interface Props {
  boundsData?: PredictionBoundData[];
  data: AnomalyChartData[];
  granularity: TimeGranularity;
  eventTime: string;
  value: number;
  lowerBound: number | null;
  upperBound: number | null;
}

export default function AnomalyChart(props: Props): JSX.Element {
  const theme = useTheme();
  const timeToAmount: { [time: string]: number } = props.data.reduce(
    (accum, datum) => ({
      ...accum,
      [datum.time]: datum.amount,
    }),
    {}
  );

  const eventTime = getFormattedEventDate(props.eventTime, props.granularity);

  const boundsDataKeyedByTimestamp = keyBy(props.boundsData ?? [], "timestamp");

  return (
    <Box width="100%" height="100%">
      <ResponsiveContainer width="100%">
        <ComposedChart
          margin={{
            bottom: 5,
            left: 20,
            right: 30,
          }}
          data={props.data}
        >
          <CartesianGrid
            {...cartesianStyleProps}
            stroke={theme.chart_cartesian_grid_lines}
          />
          <XAxis
            {...xAxisStyleProps}
            dataKey="time"
            stroke={theme.chart_axis_text}
            tickFormatter={(value) => {
              return formatTimestamp(
                value,
                getFormatForGranularity(props.granularity)
              );
            }}
            tick={{
              fontSize: "0.8rem",
              stroke: theme.text_color,
            }}
          />
          <YAxis
            {...yAxisStyleProps}
            stroke={theme.chart_axis_text}
            tickFormatter={(number) =>
              formatCurrencyRounded({
                number,
              })
            }
            tick={{
              fontSize: "0.8rem",
              stroke: theme.text_color,
            }}
            tickCount={5}
            domain={["auto", "auto"]}
            type="number"
          />
          <Tooltip
            content={(e) => (
              <SimpleChartTooltip
                label={
                  e.label &&
                  formatTimestamp(
                    e.label,
                    getFormatForGranularity(props.granularity)
                  )
                }
                total={timeToAmount[e.label]}
              />
            )}
          />
          {/* Area below the bounds */}
          <Area
            {...areaStyleProps}
            dataKey={(datum) => {
              const { lowerBound } =
                boundsDataKeyedByTimestamp[datum.time] ?? {};
              return lowerBound;
            }}
            fillOpacity={0}
            stackId="0"
            stroke={theme.secondary_color}
            strokeWidth={1}
            type="basis"
          />

          {/* Bounds */}
          <Area
            {...areaStyleProps}
            dataKey={(datum) => {
              const { lowerBound, upperBound } =
                boundsDataKeyedByTimestamp[datum.time] ?? {};
              const value = upperBound - lowerBound;
              return value;
            }}
            fill={theme.secondary_color_background}
            stackId="0"
            stroke={theme.secondary_color}
            strokeWidth={1}
            type="basis"
          />

          <Line
            dataKey={(datum) => {
              if (isAfter(new Date(datum.time), sub(new Date(), { days: 2 }))) {
                return null;
              }
              return datum.amount;
            }}
            dot={false}
            stroke={theme.recommendations_chart_line_color}
            connectNulls={false}
            strokeWidth={2}
            type="monotone"
          >
            <ErrorBar
              dataKey="error"
              width={4}
              strokeWidth={2}
              stroke={theme.feedback_negative}
              direction="y"
            />
          </Line>
          <ReferenceDot
            strokeWidth={3}
            fill={theme.feedback_negative}
            stroke={theme.feedback_negative}
            x={eventTime}
            y={props.value}
            r={3}
            ifOverflow="extendDomain"
          />
        </ComposedChart>
      </ResponsiveContainer>
    </Box>
  );
}

// NOTE: Displays UTC time
function getFormattedEventDate(
  eventTime: string,
  granularity: TimeGranularity
) {
  const newDate = new Date(new Date(eventTime).toISOString());

  switch (granularity) {
    case TimeGranularity.DAY: {
      newDate.setUTCHours(0);
      newDate.setUTCMinutes(0);
      newDate.setUTCSeconds(0);
      newDate.setUTCMilliseconds(0);

      return newDate.toISOString();
    }
    case TimeGranularity.HOUR: {
      newDate.setUTCMinutes(0);
      newDate.setUTCSeconds(0);
      newDate.setUTCMilliseconds(0);

      return newDate.toISOString();
    }
    case TimeGranularity.MINUTE: {
      newDate.setUTCSeconds(0);
      newDate.setUTCMilliseconds(0);

      return newDate.toISOString();
    }
    default: {
      newDate.setHours(0);
      newDate.setMinutes(0);
      newDate.setSeconds(0);
      newDate.setMilliseconds(0);

      return newDate.toISOString();
    }
  }
}
