import common from "../../../common.copyText";

/* prettier-ignore */
export default {
  ...common,
  addBigQueryConfigurationButtonLabel: "Add Configuration",
  alibabaAddCostReportButtonLabel: "Add Report",
  alibabaFormAddReportButtonLabel: "Add Report",
  alibabaFormBucketNameValidationErrorCaption: "Must be an Alibaba S3 bucket name",
  alibabaFormCreatedReportsHeader: "Created Reports: %reports%",
  alibabaFormEditConfirmationTitle: "Edit Alibaba Cost and Usage Report",
  alibabaFormEditModalMessage: "Are you sure you want to edit this report? This operation cannot be undone.\n\n Editing this report will remove current progress",
  alibabaFormFormTitleCreate: "Create Alibaba",
  alibabaFormFormTitleUpdate: "Update Alibaba",
  alibabaFormGeneralRoleARNInputPlaceholder: "acs:ram::<account-id>:role/<role-name>",
  alibabaFormNewAlibabaIntegrationHeader: "New Alibaba Integration",
  alibabaFormPlaceholderRoleArn: "arn:aws:iam::123456789:role/NAME_HERE",
  alibabaFormReportDefaultRegionLabel: "Select Region",
  alibabaFormReportNameLabel: "Name",
  alibabaFormReportPathLabel: "Report Path Prefix",
  alibabaFormReportRegionLabel: "Region",
  alibabaFormReportS3BucketLabel: "S3 Bucket Name",
  alibabaFormRoleARNInputLabel: "Role ARN",
  alibabaFormTabLabelCostAndUsageReports: "Cost & Usage Reports",
  alibabaFormTabLabelGeneral: "General",
  apiTokenInputLabel: "API Token",
  awsFormAddReportButtonLabel: "Add Report",
  awsFormAttributeName: "Name",
  awsFormBucketNameValidationErrorCaption: "Must be an AWS S3 bucket name ",
  awsFormCreatedReportsHeader: "Created Reports: %reports%",
  awsFormEditReportConfirmationTitle: "Edit Aws Cost and Usage Report",
  awsFormEditReportModalMessage: "Are you sure you want to edit this report? This operation cannot be undone.\n\n Editing this report will remove current progress",
  awsFormFormTitleCreate: "Create AWS",
  awsFormFormTitleUpdate: "Update AWS",
  awsFormMonitoringRoleARNLabel: "Monitoring ARN",
  awsFormMonitoringRoleARNCaption: "If you have not set up <centralizedLink>Centralized Monitoring</centralizedLink>, leave this blank. Centralized Monitoring is the best way to pull CloudWatch metrics from your current and future AWS accounts to avoid rate limits from AWS APIs. Once you set it up, provide the Amazon Resource Name (ARN) referring to the <monitoringLink>Monitoring</monitoringLink> role in the destination AWS account for Centralized Monitoring.",
  awsFormNewAwsIntegrationHeader: "New AWS Integration",
  awsFormPlaceholderRoleArn: "arn:aws:iam::123456789:role/NAME_HERE",
  awsFormLinkedAccountID: "Account ID",
  awsFormLinkedAccountRoleArn: "Linked Account ARN",
  awsFormLinkedAccountARNCaption: "Provide an Amazon Resource Name (ARN) referring to a <link>Linked Account</link> role in your AWS account. If you are not using Centralized Monitoring, you will need to create a Linked Account role for every AWS account that you want Ternary to collect monitoring (CloudWatch) data for.",
  awsFormLinkedAccountARNAlreadyExists: "⚠️ You already added that ARN. Try another one.",
  awsFormLinkedAccountIdAlreadyExists: "⚠️ An account with this AWS Account ID already exists.",
  awsFormLinkedAccountAddButtonLabel: "Add Linked Account",
  awsFormLinkedAccountsListLabel: "Linked Accounts: %VALUE%",
  awsFormEditLinkedAccountConfirmationTitle: "Edit Linked Account",
  awsFormEditLinkedAccountModalMessage: "Are you sure you want to edit this linked account? This operation cannot be undone.\n\n Editing this account will remove current progress",
  awsFormReportDefaultRegionLabel: "Select Region",
  awsFormReportNameLabel: "Name",
  awsFormReportPathLabel: "Report Path Prefix",
  awsFormReportRegionLabel: "Region",
  awsFormReportS3BucketLabel: "S3 Bucket Name",
  awsFormRoleARNLabel: "Payer Account ARN",
  awsFormRoleARNCaption: "Provide an Amazon Resource Name (ARN) referring to a <payerAccountLink>Payer Account</payerAccountLink> role in your AWS account, which is able to read your CUR (Cost and Usage Report)",
  awsFormRolesInputLabel: "Roles",
  awsFormRootResourceIDLabel: "Root Resource ID",
  awsFormTabLabelAdminReports: "Cost & Usage Reports",
  awsFormTabLabelGeneral: "General",
  awsFormTabLabelLinkedAccounts: "Linked Accounts",
  azureFormBillingDateColumnInputLabel: "Date Column",
  azureFormBillingEndDateInputLabel: "End Date",
  azureFormBillingExportTypeInputLabel: "Export Type",
  azureFormBillingServiceURLInputLabel: "Service URL",
  azureFormBillingStartDateInputLabel: "Start Date",
  azureFormBillingStorageContainerInputLabel: "Storage Container",
  azureFormBillingStoragePrefixInputLabel: "Storage Container Prefix",
  azureFormBillingStrictStoragePrefixInputLabel: "Strict Storage Prefix",
  azureFormEditConfirmationTitle: "Edit Azure Billing Exports",
  azureFormEditModalMessage: "Are you sure you want to edit this report? This operation cannot be undone.\n\n Editing this report will remove current progress",
  azureFormGeneralAppIDInputLabel: "Azure App ID",
  azureFormGeneralCertificateAction: "Client Certificate for Azure Integration",
  azureFormGeneralCertificateLabel: "Azure Certificate",
  azureFormGeneralDirectoryIDInputLabel: "Azure Directory ID",
  azureFormGeneralNameInputLabel: "Name",
  azureFormGeneralTypeInputLabel: "Select Azure Cloud Type",
  azureFormNewAzureIntegrationHeader: "New Azure Integration",
  azureFormTabLabelBillingExports: "Billing Exports",
  azureFormTabLabelGeneral: "General",
  backToIntegrationsButtonLabel: "Back to Integrations",
  createIntegrationHeader: "Create New Integration",
  updateIntegrationHeader: "Update Integration",
  emailInputLabel: "Email",
  emailInputTooltipCaption: "Type or paste in a comma-separated list of emails.",
  emailsInputLabel: "Email(s)",
  errorCreatingAlibabaIntegrationMessage: "An error occurred while attempting to create the Alibaba data integration. Please try again.",
  errorCreatingAWSIntegrationMessage: "An error occurred while attempting to create the AWS data integration. Please try again.",
  errorCreatingAzureIntegrationMessage: "An error occurred while attempting to create the Azure data integration. Please try again.",
  errorCreatingGcpIntegrationMessage: "An error occurred while attempting to create the GCP data integration. Please try again.",
  errorCreatingMongoDbIntegrationMessage: "An error occurred while attempting to create the MongoDB data integration. Please try again.",
  errorCreatingOracleIntegrationMessage: "An error occurred while attempting to create the Oracle data integration. Please try again.",
  errorCreatingSnowflakeIntegrationMessage: "An error occurred while attempting to create the Snowflake data integration. Please try again.",
  errorInputFieldArnError: "Must be a Role ARN",
  errorInputFieldInvalidID: "Must be a valid ID",
  errorInputFieldRequired: "This field is required",
  errorUpdatingAlibabaIntegrationMessage: "An error occurred while attempting to update the Alibaba data integration. Please try again.",
  errorUpdatingAWSIntegrationMessage: "An error occurred while attempting to update the AWS data integration. Please try again.",
  errorUpdatingAzureIntegrationMessage: "An error occurred while attempting to update the Azure data integration. Please try again.",
  errorUpdatingGcpIntegrationMessage: "An error occurred while attempting to update the GCP data integration. Please try again.",
  errorUpdatingMongoDbIntegrationMessage: "An error occurred while attempting to update the MongoDB data integration. Please try again.",
  errorUpdatingOracleIntegrationMessage: "An error occurred while attempting to update the Oracle data integration. Please try again.",
  errorUpdatingSnowflakeIntegrationMessage: "An error occurred while attempting to update the Snowflake data integration. Please try again.",
  errorValidatingDataIntegrationMessage: "An error occurred while attempting to validate the data integration. Please try again.",
  gcpFormBigQueryConfigurationsListLabel: "Configurations:",
  gcpFormBigQueryProjectIDInputLabel: "Project ID",
  gcpFormBigQueryScope: "Scan for BigQuery Jobs",
  gcpFormBigQueryScopeFolderLabel: "In the folder containing this project",
  gcpFormBigQueryScopeProjectLabel: "In this project only",
  gcpFormBigQueryUsageRegions: "Usage Regions",
  gcpFormBillingDatasetIDInputCaption: "Dataset ID containing the Standard Billing export.",
  gcpFormBillingDatasetIDInputLabel: "Dataset ID",
  gcpFormBillingLocationInputCaption: "Location of the Standard Billing export.",
  gcpFormBillingLocationInputLabel: "Location",
  gcpFormBillingProjectIDInputCaption: "Project ID containing the Standard Billing export.",
  gcpFormBillingProjectIDInputLabel: "Project ID",
  gcpFormBillingTableIDInputCaption: "Table ID containing the Standard Billing export.",
  gcpFormBillingTableIDInputLabel: "Table ID",
  gcpFormCarbonFootprintDatasetIDInputCaption: "Dataset ID containing the Carbon Footprint export.",
  gcpFormCarbonFootprintLocationInputCaption: "Location of the Carbon Footprint export.",
  gcpFormCarbonFootprintProjectIDInputCaption: "Project ID containing the Carbon Footprint export.",
  gcpFormCarbonFootprintTableIDInputCaption: "Table ID containing the Carbon Footprint export.",
  gcpFormCustomPricingDatasetIDInputCaption: "Dataset ID containing the Custom Pricing export.",
  gcpFormCustomPricingLocationInputCaption: "Location of the Custom Pricing export.",
  gcpFormCustomPricingProjectIDInputCaption: "Project ID containing the Custom Pricing export.",
  gcpFormCustomPricingTableIDInputCaption: "Table ID containing the Custom Pricing export.",
  gcpFormDatasetIDValidationErrorCaption: "Must be a GCP dataset ID",
  gcpFormDetailedBillingDatasetIDInputCaption: "Dataset ID containing the Detailed Billing export.",
  gcpFormDetailedBillingLocationInputCaption: "Location of the Detailed Billing export.",
  gcpFormDetailedBillingProjectIDInputCaption: "Project ID containing the Detailed Billing export.",
  gcpFormDetailedBillingTableIDInputCaption: "Table ID containing the Detailed Billing export.",
  gcpFormGeneralBillingAccountIDInputCaption: "18 letter/digit billing account ID from your Google Cloud Billing console.",
  gcpFormGeneralBillingAccountIDInputLabel: "Billing Account ID",
  gcpFormGeneralBillingAccountIDInputPlaceholder: "AAA000-BBB111-CCC222",
  gcpFormGeneralBillingInputError: "Must be a GCP Billing Account ID",
  gcpFormGeneralCudSharingEnabledLabel: "Commitment Sharing Enabled",
  gcpFormGeneralNameInputLabel: "Name",
  gcpFormGeneralRootResourceIDInputCaption: "Designate an organization, folder or single project that Ternary will scan. For best results, use an organization.",
  gcpFormGeneralRootResourceIDInputError: "Must be an organization, project or folder.",
  gcpFormGeneralRootResourceIDInputLabel: "Root Resource ID",
  gcpFormGeneralRootResourceIDInputPlaceholder: "organizations/111222333",
  gcpFormNewGcpIntegrationHeader: "New GCP Integration",
  gcpFormProjectIDValidationErrorCaption: "Must be a GCP project ID",
  gcpFormTabLabelBigQuery: "BigQuery",
  gcpFormTabLabelCarbonFootprint: "Carbon Footprint Export",
  gcpFormTabLabelCustomPricing: "Pricing Export",
  gcpFormTabLabelDetailedBilling: "Detailed Export",
  gcpFormTabLabelGeneral: "General",
  gcpFormTabLabelStandardBilling: "Standard Export",
  gcpFormTableIDValidationErrorCaption: "Must be a GCP table ID",
  integrationLastInstructions: "After submitting your new integration, we will validate all permissions and attempt to collect your cost & usage data, assuming we have the necessary access your data will begin processing and should complete within 24 hours",
  updateIntegrationLastInstructions: "After submitting this update, we'll validate the permissions and attempt to collect your cost & usage data. If we have the necessary access, your data will begin processing.",
  issueTransitionIDInputCaption: `For custom final states. `,
  issueTransitionIDInputCaptionLink: "Learn more about transition IDs",
  issueTransitionIDInputLabel: "Issue Transition ID(s)",
  jiraDocumentationCaption: "Learn more about our Jira integration ",
  jiraErrorUpdatingIntegration: "An error occurred while attempting to update the integration. Please try again.",
  jiraIntegrationApiTokenLabel: "API Token",
  jiraIntegrationEmailLabel: "Email",
  jiraIntegrationInstanceUrlLabel: "Instance URL",
  jiraIntegrationNotConfiguredMessage: "Jira integration is not yet configured.",
  jiraIntegrationProjectIDLabel: "Project ID",
  jiraIntegrationTitle: "Jira",
  mongoDbFormGeneralAuthorizationTypeApiKey: "Api Key",
  mongoDbFormGeneralAuthorizationTypeLabel: "Authorization Type",
  mongoDbFormGeneralAuthorizationTypeServiceAccount: "Service Account",
  mongoDbFormGeneralClientIDInputLabel: "Client ID",
  mongoDbFormGeneralClientSecretInputLabel: "Client Secret",
  mongoDbFormGeneralOrganizationIDInputLabel: "Organization ID",
  mongoDbFormGeneralOrganizationIDInputPlaceholder: "",
  mongoDbFormGeneralPublicKeyInputLabel: "Public Key",
  mongoDbFormGeneralSecretKeyInputLabel: "Secret Key",
  mongoDbFormNewMongoDbIntegrationHeader: "New MongoDB Integration",
  mongoDbFormTabLabelGeneral: "General",
  oracleFormCustomStorageBucketInputError: "Must be a valid bucket name",
  oracleFormCustomStorageNamespaceInputError: "Must be a valid namespace",
  oracleFormGeneralNameLabel: "Name",
  oracleFormGeneralOCICertificateAction: "Client Certificate for OCI Integration",
  oracleFormGeneralOCICertificateLabel: "OCI Certificate",
  oracleFormGeneralOCIDInputError: "Must be a valid OCID",
  oracleFormGeneralRegionLabel: "Tenancy Region",
  oracleFormGeneralStorageBucketLabel: "Custom Storage Bucket",
  oracleFormGeneralStorageNamespaceLabel: "Custom Storage Namespace",
  oracleFormGeneralTenancyOCIDLabel: "Tenancy OCID",
  oracleFormGeneralUserOCIDLabel: "Service User OCID",
  oracleFormNewOracleIntegrationHeader: "New Oracle Integration",
  oracleFormTabLabelCustom: "Custom",
  oracleFormTabLabelGeneral: "General",
  projectIDInputLabel: "Project ID",
  slackErrorCreatingIntegration: "An error occurred while attempting to create the integration. Please try again.",
  slackErrorDeletingIntegration: "An error occurred while attempting to delete the Slack integration. Please try again.",
  slackErrorUpdatingIntegration: "An error occurred while attempting to update the integration. Please try again.",
  slackIntegrationAttributeChannelID: "Channel ID",
  slackIntegrationAttributeTeamURL: "Team URL",
  slackIntegrationChannelIDCaption: "See our %link% for setup instructions.",
  slackIntegrationChannelIDCaptionLink: "Integrations Guide",
  slackIntegrationConfigured: "Slack integration is active.",
  slackIntegrationInstructions: "Click the button below to begin.",
  slackIntegrationNotConfigured: "Slack integration is not yet configured.",
  slackIntegrationTitle: "Slack",
  slackIntegrationWorkspaceURLLabel: "Workspace URL",
  snowflakeFormAccountsAddButtonLabel: "Add Account",
  snowflakeFormAccountsListLabel: "Created accounts: %VALUE%",
  snowflakeFormAccountsNameInputLabel: "Account Name",
  snowflakeFormAccountsPasswordInputLabel: "Password",
  snowflakeFormAccountsRoleInputLabel: "Role",
  snowflakeFormAccountsUsernameInputLabel: "Username",
  snowflakeFormAccountsWarehouseInputLabel: "Warehouse",
  snowflakeFormEditConfirmationTitle: "Edit Snowflake Account",
  snowflakeFormEditModalMessage: "Are you sure you want to edit this account? This operation cannot be undone.\n\n Editing this account will remove current progress",
  snowflakeFormGeneralCloudNameInputLabel: "Cloud Name",
  snowflakeFormGeneralOrganizationInputCaption: "Designate an Organization, folder or single project that Ternary will scan. For best results, use an organization.",
  snowflakeFormGeneralOrganizationInputLabel: "Organization",
  snowflakeFormNewIntegrationHeader: "New Snowflake Integration",
  successCreatingAlibabaIntegrationMessage: "The Alibaba data integration has been successfully created.",
  successCreatingAWSIntegrationMessage: "The AWS data integration has been successfully created.",
  successCreatingAzureIntegrationMessage: "The Azure data integration has been successfully created.",
  successCreatingGcpIntegrationMessage: "The GCP data integration has been successfully created.",
  successCreatingMongoDbIntegrationMessage: "The MongoDB data integration has been successfully created.",
  successCreatingOracleIntegrationMessage: "The Oracle data integration has been successfully created.",
  successCreatingSnowflakeIntegrationMessage: "The Snowflake data integration has been successfully created.",
  successUpdatingAlibabaIntegrationMessage: "The Alibaba data integration has been successfully updated.",
  successUpdatingAWSIntegrationMessage: "The AWS data integration has been successfully updated.",
  successUpdatingAzureIntegrationMessage: "The Azure data integration has been successfully updated.",
  successUpdatingGcpIntegrationMessage: "The GCP data integration has been successfully updated.",
  successUpdatingMongoDbIntegrationMessage: "The MongoDB data integration has been successfully updated.",
  successUpdatingOracleIntegrationMessage: "The Oracle data integration has been successfully updated.",
  successUpdatingSnowflakeIntegrationMessage: "The Snowflake data integration has been successfully updated.",
  successUpdatingSocialIntegrationMessage: "The integration has been successfully updated.",
};
