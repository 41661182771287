import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import Text from "@ternary/web-ui-lib/components/Text";
import prettyBytes from "pretty-bytes";
import React, { useMemo } from "react";
import Modal from "../../../../ui-lib/components/Modal";
import copyText from "../../copyText";

type TableData = {
  instanceID: string;
  cost: string;
  backupUsedB: string;
  processingUnits: number;
  storageLimitB: string;
  totalEgressGib: string;
};

type SpannerInstance = {
  projectID: string | null;
  instanceID: string | null;
  cost: number;
  backupUsedB: number;
  processingUnits: number;
  storageLimitB: number;
  totalEgressGib: number;
};

interface Props {
  isLoading: boolean;
  instances: SpannerInstance[];
  onInteraction: (
    interaction: GCPCloudSpannerInstanceTable.Interaction
  ) => void;
}

export function GCPCloudSpannerInstanceTable(props: Props): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();

  const columns = useMemo(
    () => [
      columnHelper.accessor("instanceID", {
        header: copyText.cloudSpannerInstanceTableHeaderInstanceID,
        size: 200,
      }),
      columnHelper.accessor("cost", {
        header: copyText.cloudSpannerInstanceTableHeaderCost,
        size: 100,
      }),
      columnHelper.accessor("processingUnits", {
        header: copyText.cloudSpannerInstanceTableHeaderProvisionedUnits,
        meta: { align: "center" },
        size: 200,
      }),
      columnHelper.accessor("storageLimitB", {
        header: copyText.cloudSpannerInstanceTableHeaderStorage,
        meta: { align: "center" },
        size: 120,
      }),
      columnHelper.accessor("backupUsedB", {
        header: copyText.cloudSpannerInstanceTableHeaderBackup,
        meta: { align: "center" },
      }),
      columnHelper.accessor("totalEgressGib", {
        header: copyText.cloudSpannerInstanceTableHeaderNetwork,
        meta: { align: "center" },
      }),
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.instances.map((item) => {
      return {
        instanceID: item.instanceID ?? "--",
        cost: formatCurrency({ number: item.cost }),
        backupUsedB: prettyBytes(item.backupUsedB),
        processingUnits: item.processingUnits,
        storageLimitB: prettyBytes(item.storageLimitB),
        totalEgressGib: prettyBytes(item.totalEgressGib),
      };
    });
    return tableData;
  }, [props.instances]);

  return (
    <Modal
      isOpen
      showCloseButton
      closeOnClickOutside
      onClose={() =>
        props.onInteraction({
          type: GCPCloudSpannerInstanceTable.INTERACTION_CLOSE_BUTTON_CLICKED,
        })
      }
      minWidth={500}
    >
      <Modal.Header>
        <Text appearance="h4">
          {copyText.cloudSpannerInstanceModalHeader.replace(
            "%PROJECT%",
            props.instances[0].projectID ?? "Project"
          )}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <Table
          compact
          columns={columns}
          data={data}
          isLoading={props.isLoading}
          initialState={{ pagination: { pageSize: 8 } }}
          showPagination
          sortable
        />
      </Modal.Body>
    </Modal>
  );
}

GCPCloudSpannerInstanceTable.INTERACTION_CLOSE_BUTTON_CLICKED =
  `GCPCloudSpannerInstanceTable.INTERACTION_CLOSE_BUTTON_CLICKED` as const;

interface InteractionCloseButtonClicked {
  type: typeof GCPCloudSpannerInstanceTable.INTERACTION_CLOSE_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GCPCloudSpannerInstanceTable {
  export type Interaction = InteractionCloseButtonClicked;
}

export default GCPCloudSpannerInstanceTable;
