import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import { useTheme } from "@emotion/react";
import { faEdit, faFolder, faTimes } from "@fortawesome/pro-solid-svg-icons";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React, { MouseEvent, useEffect, useState } from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import Select from "../../../../../ui-lib/components/Select";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import { alibabaRegions, validatesBucketName } from "./constants";
import { FormData, FormProps } from "./types";

const validators = {
  s3BucketName: ({ value }: { value: string }) => {
    if (value.length === 0) return;

    return validatesBucketName(value);
  },
  name: ({ value }: { value: string }) => {
    if (value.length === 0) return;

    return value.length === 0 ? "Invalid" : undefined;
  },
  reportPathPrefix: ({ value }: { value: string }) => {
    if (value.length === 0) return;

    return value.length === 0 ? "Invalid" : undefined;
  },
};

const regionOptions = alibabaRegions.map((location) => ({
  label: location,
  value: location,
}));

export default function AlibabaFormReports(props: FormProps): JSX.Element {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState(false);
  const { form } = props;

  const reportsFields: Array<keyof FormData> = [
    "nameCUDs",
    "regionCUDs",
    "reportPathPrefixCUDs",
    "s3BucketNameCUDs",
  ];

  function clearFieldValues() {
    form.setFieldValue("isEditingConfig", false);
    form.setFieldValue("selectedCudConfigIndex", undefined);
    form.setFieldValue("nameCUDs", "");
    form.setFieldValue("s3BucketNameCUDs", "");
    form.setFieldValue("reportPathPrefixCUDs", "");
    form.setFieldValue("regionCUDs", null);
    setFieldErrors(false);
  }

  function setFieldErrors(error: boolean) {
    reportsFields.forEach((field) => {
      form.setFieldMeta(field, {
        errors: error ? [copyText.errorInputFieldRequired] : [],
        errorMap: error ? { onMount: copyText.errorInputFieldRequired } : {},
        isBlurred: false,
        isDirty: false,
        isPristine: true,
        isTouched: false,
        isValidating: false,
      });
    });
  }

  useEffect(() => {
    return () => {
      // Reset cost & usage form on unmount for the submit button.
      clearFieldValues();
    };
  }, []);

  return (
    <form.Field
      name="configuredCUDs"
      mode="array"
      children={(configsField) => (
        <Flex padding={theme.space_xxs} gap={theme.space_md}>
          <Box width="80%">
            <form.Field
              name={"nameCUDs"}
              validators={{
                onChange: validators.name,
              }}
              children={(field) => {
                const shouldShowError =
                  field.state.value.length > 0 &&
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={
                      shouldShowError
                        ? field.state.meta.errors.join(", ")
                        : undefined
                    }
                    label={copyText.alibabaFormReportNameLabel}
                    required
                    value={field.state.value}
                    variant={shouldShowError ? "danger" : undefined}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <form.Field
              name={`s3BucketNameCUDs`}
              validators={{
                onChange: validators.s3BucketName,
              }}
              children={(field) => {
                const shouldShowError =
                  field.state.value.length > 0 &&
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={
                      shouldShowError
                        ? field.state.meta.errors.join(", ")
                        : undefined
                    }
                    label={copyText.alibabaFormReportS3BucketLabel}
                    required
                    value={field.state.value}
                    variant={shouldShowError ? "danger" : undefined}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <form.Field
              name={`reportPathPrefixCUDs`}
              validators={{
                onChange: validators.reportPathPrefix,
                onMount: validators.reportPathPrefix,
              }}
              children={(field) => {
                const shouldShowError =
                  field.state.value.length > 0 &&
                  field.state.meta.isTouched &&
                  field.state.meta.errors.length > 0;

                return (
                  <FormField
                    input={TextInput}
                    errorCaption={
                      shouldShowError
                        ? field.state.meta.errors.join(", ")
                        : undefined
                    }
                    label={copyText.alibabaFormReportPathLabel}
                    required
                    value={field.state.value}
                    variant={shouldShowError ? "danger" : undefined}
                    onBlur={field.handleBlur}
                    onChange={(e) => field.handleChange(e.target.value)}
                  />
                );
              }}
            />
            <form.Field
              name={`regionCUDs`}
              children={(field) => {
                return (
                  <FormField label={copyText.alibabaFormReportRegionLabel}>
                    <Select
                      options={regionOptions}
                      value={
                        field.state.value
                          ? {
                              label: field.state.value,
                              value: field.state.value,
                            }
                          : []
                      }
                      onChange={(option) =>
                        option && field.handleChange(option.value)
                      }
                    />
                  </FormField>
                );
              }}
            />
            <form.Subscribe
              children={(state) => {
                const disabled =
                  state.values.nameCUDs.length === 0 ||
                  state.values.s3BucketNameCUDs.length === 0 ||
                  state.values.reportPathPrefixCUDs.length === 0 ||
                  !state.isFieldsValid;

                const showModal =
                  state.values.nameCUDs.length > 0 ||
                  state.values.s3BucketNameCUDs.length > 0 ||
                  state.values.reportPathPrefixCUDs.length > 0;

                function handleAddConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();

                  // Add completed config to array.
                  configsField.pushValue({
                    name: state.values.nameCUDs,
                    s3BucketName: state.values.s3BucketNameCUDs,
                    reportPathPrefix: state.values.reportPathPrefixCUDs,
                    region: state.values.regionCUDs,
                  });

                  // Reset form.
                  clearFieldValues();
                }

                function handleIsEditingConfig(
                  event: MouseEvent,
                  index: number
                ) {
                  event.preventDefault();
                  event.stopPropagation();
                  // Add values to inputs.
                  form.setFieldValue("isEditingConfig", true);
                  form.setFieldValue("selectedCudConfigIndex", index);

                  const selectedConfig = configsField.state.value[index];
                  form.setFieldValue("nameCUDs", selectedConfig.name);
                  form.setFieldValue(
                    "s3BucketNameCUDs",
                    selectedConfig.s3BucketName
                  );
                  form.setFieldValue(
                    "reportPathPrefixCUDs",
                    selectedConfig.reportPathPrefix
                  );
                  form.setFieldValue("regionCUDs", selectedConfig.region);
                }

                function handleSaveConfig(event: MouseEvent) {
                  event.preventDefault();
                  event.stopPropagation();
                  const index = state.values.selectedCudConfigIndex;
                  if (index === undefined) {
                    return;
                  }
                  configsField.replaceValue(index, {
                    name: state.values.nameCUDs,
                    s3BucketName: state.values.s3BucketNameCUDs,
                    reportPathPrefix: state.values.reportPathPrefixCUDs,
                    region: state.values.regionCUDs,
                  });
                  clearFieldValues();
                }

                return (
                  <>
                    {isOpen && (
                      <ConfirmationModal
                        message={copyText.alibabaFormEditModalMessage}
                        title={copyText.alibabaFormEditConfirmationTitle}
                        variant="danger"
                        onConfirm={(event) => {
                          if (event) {
                            handleIsEditingConfig(
                              event,
                              form.state.values.selectedCudConfigIndex ?? 0
                            );
                            setIsOpen(false);
                          }
                        }}
                        onCancel={() => setIsOpen(false)}
                      />
                    )}
                    {state.values.isEditingConfig ? (
                      <Flex>
                        <Button
                          marginRight={theme.space_xs}
                          size="small"
                          type="button"
                          onClick={clearFieldValues}
                        >
                          {copyText.actionCancel}
                        </Button>
                        <Button
                          disabled={disabled}
                          size="small"
                          type="button"
                          primary
                          onClick={handleSaveConfig}
                        >
                          {copyText.actionSave}
                        </Button>
                      </Flex>
                    ) : (
                      <Button
                        disabled={disabled}
                        size="small"
                        type="button"
                        primary
                        onClick={handleAddConfig}
                      >
                        {copyText.alibabaFormAddReportButtonLabel}
                      </Button>
                    )}

                    <Text
                      marginVertical={theme.space_xs}
                      fontSize={theme.h4_fontSize}
                      fontWeight={theme.h4_fontWeight}
                    >
                      {copyText.alibabaFormCreatedReportsHeader.replace(
                        "%reports%",
                        `${state.values.configuredCUDs.length}`
                      )}
                    </Text>
                    <Flex
                      direction="column"
                      gap={theme.space_xs}
                      paddingVertical={theme.space_xxs}
                    >
                      {configsField.state.value.map((config, index) => {
                        const disableActionButtons =
                          state.values.selectedCudConfigIndex === index;
                        return (
                          <Flex
                            key={index}
                            alignItems="center"
                            justifyContent="space-between"
                            marginHorizontal={theme.space_xxs}
                            width="100%"
                          >
                            <Flex alignItems="center">
                              <Flex justifyContent="center" minWidth={20}>
                                <Icon
                                  color={theme.text_color}
                                  icon={faFolder}
                                />
                              </Flex>
                              <Text
                                marginLeft={theme.space_xs}
                                fontSize={theme.fontSize_base}
                              >
                                {`${config.name}, ${config.s3BucketName}, 
                              ${config.reportPathPrefix},${config.region}`}
                              </Text>
                            </Flex>
                            <Box>
                              <Button
                                disabled={disableActionButtons}
                                iconEnd={<Icon icon={faEdit} />}
                                marginRight={theme.space_xxs}
                                secondary
                                size="tiny"
                                type="button"
                                onClick={(event) => {
                                  if (showModal) {
                                    form.setFieldValue(
                                      "selectedCudConfigIndex",
                                      index
                                    );
                                    setIsOpen(true);
                                  } else {
                                    handleIsEditingConfig(event, index);
                                  }
                                }}
                              />
                              <Button
                                disabled={disableActionButtons}
                                iconEnd={<Icon icon={faTimes} />}
                                secondary
                                size="tiny"
                                type="button"
                                onClick={() => configsField.removeValue(index)}
                              />
                            </Box>
                          </Flex>
                        );
                      })}
                    </Flex>
                  </>
                );
              }}
            />
          </Box>
        </Flex>
      )}
    />
  );
}
