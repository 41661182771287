import React, { ReactNode, cloneElement, isValidElement } from "react";

type TextComponents = {
  [key: string]: ReactNode;
};

interface ParsedTextProps {
  text: string;
  components: TextComponents;
}

function parseText(text: string, components: TextComponents): ReactNode[] {
  // Regular expression to match tags like <link>text</link>
  const regex = /<(\w+)>(.*?)<\/\1>/g;
  const result: ReactNode[] = [];
  let lastIndex = 0;
  let match: RegExpExecArray | null;

  while ((match = regex.exec(text)) !== null) {
    // Add text before the match
    if (match.index > lastIndex) {
      result.push(text.slice(lastIndex, match.index));
    }

    const [_, tagName, content] = match;

    // Add the JSX content if component exists, otherwise add plain text
    if (components[tagName]) {
      const Component = components[tagName];
      if (isValidElement(Component)) {
        // Type assertion to handle the cloneElement props
        result.push(
          cloneElement(Component as React.ReactElement, {
            key: match.index,
            children: content,
          })
        );
      }
    } else {
      result.push(content);
    }

    lastIndex = regex.lastIndex;
  }

  // Add remaining text after last match
  if (lastIndex < text.length) {
    result.push(text.slice(lastIndex));
  }

  return result;
}

export default function ParsedText({
  text,
  components,
}: ParsedTextProps): JSX.Element {
  return <>{parseText(text, components)}</>;
}
