import { useTheme } from "@emotion/react";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/web-ui-lib/components/Text";
import React from "react";

type TableTagsProps = {
  compact?: boolean;
  highlightedTags?: string[];
  repeatedTags?: string[];
  tags: string[];
  onClickTag: (tag: string) => void;
};

export default function TableTags(props: TableTagsProps): JSX.Element {
  const theme = useTheme();

  const allRepeatedTags = props.repeatedTags ?? [];
  const highlightedTags = props.highlightedTags ?? [];

  function getTagColor(tag: string): string {
    if (highlightedTags.length > 0 && highlightedTags.includes(tag)) {
      return theme.primary_color_background;
    }

    if (!highlightedTags.length && allRepeatedTags.includes(tag)) {
      return theme.feedback_positive;
    } else if (!highlightedTags.length && !allRepeatedTags.includes(tag)) {
      return theme.secondary_color_background;
    }

    return theme.secondary_color_background;
  }

  return (
    <Flex
      flexWrap="wrap"
      justifyContent="flex-start"
      maxHeight={props.compact ? "30px" : "60px"}
      maxWidth={"100%"}
      overflowY="auto"
      onClick={(event) => event.stopPropagation()}
    >
      {props.tags.map((tag, index) => (
        <Flex
          key={`${tag}+${index}`}
          alignItems="center"
          backgroundColor={getTagColor(tag)}
          borderRadius={theme.borderRadius_1}
          cursor="pointer"
          marginBottom={theme.space_xxs}
          marginRight={theme.space_xxs}
          paddingHorizontal={theme.space_xs}
          paddingRight={theme.space_xs}
          onClick={() => props.onClickTag(tag)}
        >
          <Text
            color={
              getTagColor(tag) === theme.secondary_color_background
                ? theme.default_button_text_color
                : theme.text_color_inverse
            }
          >
            {tag}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
}
