import { useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faEquals,
  faFileExport,
  IconDefinition,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper, Row } from "@tanstack/react-table";
import { UnitType } from "@ternary/api-lib/constants/analytics";
import { MeasureCell } from "@ternary/api-lib/ui-lib/charts/Table/MeasureCell";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Box from "@ternary/web-ui-lib/components/Box";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { formatDate } from "@ternary/web-ui-lib/utils/dates";
import { formatPercentage } from "@ternary/web-ui-lib/utils/formatNumber";
import React, { useCallback, useMemo, useRef } from "react";
import { CSVLink } from "react-csv";
import copyText from "../copyText";

type Annotations = {
  coverageSummary: {
    sourceCostConverted: number;
    sourceCostNormalized: number;
    sourceCostUnconverted: number;
    totalSourceCost: number;
    totalTargetCost: number;
  };
  pivotConverted: {
    byServiceCategory: {
      [key: string]: {
        sourcePlatformCost: number;
        targetPlatformCost: number;
      };
    };
  };
};

type TableData = {
  description: string;
  aws: number;
  gcp: number;
  change: number;
};

interface Props {
  annotations: Annotations;
  billName: string;
}

const columnHelper = createColumnHelper<TableData>();

export default function SavingsBreakdownTable(props: Props): JSX.Element {
  const theme = useTheme();

  const sortRulesRef = useRef<{
    id?: string;
    desc?: boolean;
  }>({});

  const customSort = useCallback(
    (rowA: Row<TableData>, rowB: Row<TableData>, columnID: string) => {
      let isDescending = true;

      if (sortRulesRef.current?.id === columnID) {
        isDescending = !sortRulesRef.current.desc;
      } else if (columnID === "description") {
        // The description column always start in ascending order
        isDescending = false;
      }

      const specialRows = [
        copyText.savingsBreakdownTableDescriptionSubtotal,
        copyText.savingsBreakdownTableDescriptionTotal,
        copyText.savingsBreakdownTableDescriptionUnconverted,
      ];

      const aIsSpecial = specialRows.includes(rowA.original.description);
      const bIsSpecial = specialRows.includes(rowB.original.description);

      if (aIsSpecial && !bIsSpecial) {
        return isDescending ? -1 : 1;
      } else if (!aIsSpecial && bIsSpecial) {
        return isDescending ? 1 : -1;
      } else if (aIsSpecial && bIsSpecial) {
        return 0;
      }

      return rowA.original[columnID] > rowB.original[columnID]
        ? 1
        : rowA.original[columnID] < rowB.original[columnID]
          ? -1
          : 0;
    },
    []
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor("description", {
        header: copyText.savingsBreakdownTableHeaderDescription,
        size: 100,
        sortingFn: customSort,
      }),
      columnHelper.accessor("aws", {
        cell: ({ column, getValue }) => (
          <MeasureCell
            applyMaxCharacters
            columnID={column?.id}
            unit={UnitType.CURRENCY}
            value={getValue()}
          />
        ),
        header: copyText.savingsBreakdownTableHeaderAWS,
        size: 100,
        sortingFn: customSort,
      }),
      columnHelper.accessor("gcp", {
        header: copyText.savingsBreakdownTableHeaderGCP,
        cell: ({ column, getValue }) => (
          <MeasureCell
            applyMaxCharacters
            columnID={column?.id}
            unit={UnitType.CURRENCY}
            value={getValue()}
          />
        ),
        size: 100,
        sortingFn: customSort,
      }),
      columnHelper.accessor("change", {
        header: copyText.savingsBreakdownTableHeaderChange,
        cell: ({ row }) => {
          const changeIconAttributes = getChangeIconAttributes(
            row.original.aws,
            row.original.gcp
          );

          return (
            <Flex justifyContent="flex-end" width="100%">
              <Flex justifyContent="center" width="50%">
                <Text marginRight={theme.space_sm}>
                  {row.original.change > 0 && "+"}
                  {formatPercentage(row.original.change)}
                </Text>
              </Flex>
              <Icon
                color={changeIconAttributes.color}
                icon={changeIconAttributes.icon}
              />
            </Flex>
          );
        },
        size: 100,
        sortingFn: customSort,
      }),
    ],
    [customSort]
  );

  const data = useMemo(() => {
    return [
      ...Object.entries(props.annotations.pivotConverted.byServiceCategory).map(
        ([key, value]) => ({
          description: key,
          aws: value.sourcePlatformCost,
          gcp: value.targetPlatformCost,
          change:
            value.sourcePlatformCost === 0 || value.sourcePlatformCost === 0
              ? 0
              : (value.targetPlatformCost - value.sourcePlatformCost) /
                value.sourcePlatformCost,
        })
      ),
      {
        description: copyText.savingsBreakdownTableDescriptionSubtotal,
        aws: props.annotations.coverageSummary.sourceCostConverted,
        gcp: props.annotations.coverageSummary.totalTargetCost,
        change:
          (props.annotations.coverageSummary.totalTargetCost -
            props.annotations.coverageSummary.sourceCostConverted) /
          props.annotations.coverageSummary.sourceCostConverted,
      },
      {
        description: copyText.savingsBreakdownTableDescriptionUnconverted,
        aws: props.annotations.coverageSummary.sourceCostUnconverted,
        gcp: props.annotations.coverageSummary.sourceCostUnconverted,
        change: 0,
      },
      {
        description: copyText.savingsBreakdownTableDescriptionTotal,
        aws: props.annotations.coverageSummary.totalSourceCost,
        gcp:
          props.annotations.coverageSummary.totalTargetCost +
          props.annotations.coverageSummary.sourceCostUnconverted,
        change:
          (props.annotations.coverageSummary.totalTargetCost +
            props.annotations.coverageSummary.sourceCostUnconverted -
            props.annotations.coverageSummary.totalSourceCost) /
          props.annotations.coverageSummary.totalSourceCost,
        opitons: {
          disableFilters: true,
        },
      },
    ];
  }, [props.annotations]);

  const csvData = getCSVDataFromEntries(data);

  return (
    <>
      <Table
        columns={columns}
        compact
        data={data}
        isRowHighlighted={(datum) => {
          return ["Subtotal", "Total"].includes(datum.description);
        }}
        sortable
        onChangeSortBy={(rules) => {
          // After sorting, keep the sorting rules for custom sorting
          sortRulesRef.current = rules?.[0];
        }}
        initialState={{ sorting: [{ id: "description", desc: false }] }}
      />
      <Box width="25%">
        <CSVLink
          data={csvData.data}
          filename={`savingsBreakdown${props.billName.replace(
            / /g,
            ""
          )}-${formatDate(new Date(), "MM-dd-yyyy")}`}
        >
          <Button
            iconStart={<Icon color="inherit" icon={faFileExport} />}
            marginTop={theme.space_sm}
            width={"100%"}
            primary
          >
            {copyText.exportAsCSVButtonLabel}
          </Button>
        </CSVLink>
      </Box>
    </>
  );

  function getChangeIconAttributes(
    sourceValue: number,
    targetValue: number
  ): { color: string; icon: IconDefinition } {
    if (Math.abs(sourceValue - targetValue) < 0.9) {
      return {
        color: "",
        icon: faEquals,
      };
    }

    if (targetValue - sourceValue > 0) {
      return {
        color: theme.feedback_negative,
        icon: faArrowUp,
      };
    }

    return {
      color: theme.feedback_positive,
      icon: faArrowDown,
    };
  }

  type CSVData = {
    data: { [key: string]: string | number | string[] }[];
  };

  type SavingsBreakdown = {
    description: string;
    aws: number;
    gcp: number;
    change: number;
  };

  function getCSVDataFromEntries(entries: SavingsBreakdown[]): CSVData {
    if (!entries) return { data: [] };

    const data: { [key: string]: string | number }[] = entries.map((entry) => {
      return {
        description: entry.description,
        aws: entry.aws,
        gcp: entry.gcp,
        change: entry.change,
      };
    });

    return { data };
  }
}
