import common from "../../common.copyText";

/* prettier-ignore */
export default {
...common,
actionMenuItemDeleteScopedView: "Delete",
actionMenuItemEditScopedView: "Edit",
actionMenuItemMakeCopyScopedView: "Make Copy",
addAllUsersLabel: "Apply to All Users",
addKeyNameLabel: "Key Name",
addNewKeyLabel: "Add API Key",
apiKeyManagementTabHeader: "API Keys",
apiManagementTabLabel: 'API Keys',
appearanceOption_darkMode: "Dark Mode",
availableForButtonLabel: "Available For",
availableForHeader: "Available For:",
cadenceHeader: "Cadence",
calendarOption_fiscalMode: "Fiscal Calendar",
cancelButtonLabel: "Cancel",
copiedToClipboardText: "Copied to Clipboard",
copyToClipboardText: "Click to Copy",
createScopedViewButtonLabel: "Create Scoped View",
deleteApiKeyLabel: "Delete Key",
deleteApiKeyWarningText: "Are you sure you want to delete this API key? This operation cannot be undone.",
deleteScopedViewAnd: " and ",
deleteScopedViewConfirmationMessage: "Are you sure you want to delete this scoped view? This operation cannot be undone. \n",
deleteScopedViewConfirmationTitle: "Delete Scoped View",
deleteScopedViewOthers: " other(s)",
deleteScopedViewUsersAssignedMessage: "\nThe following users are assigned to this scoped view and will be affected: \n",
enabledByDefaultForButtonLabel: "Enabled By Default For",
enabledByDefaultForHeader: "Enabled By Default For:",
enabledByDefaultLabel: "On by default",
enabledNo: "No",
enabledYes: "Yes",
enforcedForButtonLabel: "Enforced For",
enforcedForHeader: "Enforced For:",
enforcedForTooltip: "Scoped Views are only enforceable for other users",
errorCreatingApiKeyMessage: "Error creating the API key",
errorCreatingScopedViewMessage: "An error occurred while attempting to create the scoped view. Please try again.",
errorDeletingApiKeyMessage:"Error deleting the API key",
errorDeletingScopedViewMessage: "An error occurred while attempting to delete the scoped view. Please try again.",
errorGettingUserSettings_message: "An error occurred while attempting to fetch your settings. Please try again.",
errorLoadingScopedViewsMessage: "There was an error loading scoped views. Please refresh the page and try again.",
errorUpdatingScopedViewMessage: "An error occurred while attempting to update the scoped view. Please try again.",
errorUpdatingUserSettings_message: "An error occurred while attempting to update your settings. Please try again.",
expirationDateLimitText: "Optional expiration date.",
filtersHeader: "Filters",
formTitleCreate: "Create Scoped View",
formTitleEdit: "Edit Scoped View",
generalTabLabel: "General",
generatedApiCloseText: "Done",
generatedApiExpiresOn: "Expires on:",
generatedApiKeyText: "API Key Generated",
includeFiltersOnlyLabel: "Filters only",
includeInputLabel: "Include",
includeScopedViewsAndFiltersLabel: "Scoped views and filters",
includeScopedViewsOnlyLabel: "Scoped views only",
keyNameInfoText: "Display name for the API key",
keySecretMessage: "Please store your API secret in a safe place as it will not be shown again. ",
modalCloseButtonLabel: "Close",
modalDateRange: "Expiration Date",
modalHeaderCreateApiKey: "Create API Key",
modalPlaceholderLabel: "Ex: My API Key",
modalSubmitButtonLabel: "Generate",
modalTitleLabel: "Key Name",
moreListItemsLabel: "more...",
moreOptionsLabel: "More...",
notificationOption_daily: "Daily",
notificationOption_enabled: "Enabled",
notificationOption_monthly: "Monthly",
notificationOption_weekly: "Weekly",
notificationTypeAlerts: "Alerts",
notificationTypeBudgets: "Budgets",
notificationTypeCases: "Cases",
notificationTypeRecommendations: "Recommendations",
notificationTypeReports: "Reports",
recNotificationAllRecommendations: "All Recommendations",
recNotificationFilterInfoTooltipCaption: "Filters configured here will apply to both individual recommendations and recommendation summaries",
recNotificationsDaily: "Daily", 
recNotificationsRecommendationSummary: "Recommendation Summary",
recNotificationsServices: "Services",
recNotificationsSubAccounts: "Sub Accounts",
recNotificationSummaryInfoTooltipCaption:"Select one of these options if you would like to receive summaries of your recommendations",
recNotificationsVendor: "Vendor",
recNotificationsWeekly: "Weekly",
recNotificiationsMonthly: "Monthly",
recommendationsConfigureButtonLabel: "Configure",
recommendationsNotificationFormTitle: "configure",
removeAllUsersLabel: "Remove All Users",
scopedViewCopyLabel: "Copy",
scopedViewNameCopyLabel: "Copy",
scopedViewsSubTitle: "My Scoped Views",
scopedViewsTabLabel: "My Scoped Views",
searchInputPlaceholder: "Search",
selectUsersPlaceholder: "Select Users",
selectValueLabel: "Select Value...",
sharedLabel: "[Shared]",
sharedTooltip: "This Scoped View has been shared with you by your Admin.",
sideDrawerTitleCopy: "Copy Scoped View",
sideDrawerTitleCreate: "Create Scoped View",
sideDrawerTitleEdit: "Edit Scoped View",
sideDrawerTitleSave: "Save as Scoped View",
statusActive: "Active",
statusExpiresWarning: "Expires in %number% days",
statusInactive: "Expired",
strictEnabledForButtonLabel: "Strictly Enabled For",
strictEnabledForHeader: "Strictly Enabled For:",
submitButtonLabel: "Submit",
subTitleAppearancePreferences: "Appearance Preferences",
subTitleDatePreferences: "Date Preferences",
subTitleNotificationPreferences: "Notification Preferences",
successApiKeyCreatedMessage: "Successfully created the API key",
successApiKeyDeletedMessage: "Successfully deleted the API key",
successCreatingApiKey: "The API Key has been successfully created.",
successCreatingScopedViewMessage: "The scoped view has been successfully created.",
successDeletingApiKey: "The API Key has been successfully deleted.",
successDeletingScopedViewMessage: "The scoped view has been successfully deleted.",
successUpdatingScopedViewMessage: "The scoped view has been successfully updated.",
successUpdatingUserSettings_message: "You have successfully updated your settings.",
switchApplyToNewUsersLabel: "Apply to New Users",
tableHeaderCreatedBy: "Created By",
tableHeaderCreatedOn: "Created On",
tableHeaderDisplayName: "Name",
tableHeaderEmail: "Email",
tableHeaderEnabled: "Enabled",
tableHeaderExpiresOn: "Expires On",
tableHeaderID: "ID",
tableHeaderName: "Name",
tableHeaderStatus: "Status",
tableHeaderTimeLastModified: "Time Last Modified",
tableHeaderTotalFilters: "Total Filters",
tableHeaderTotalUsers: "Total Users",
tooltip_alerts: "Enable or disable your alert notifications.",
tooltip_budgets: "Enable or disable your budget alert notifications.",
tooltip_cases: "Disabling this will mute all email notifications for updates to cases you have interacted with.",
tooltip_recommendations: "Enable or disable your Compute, Kubernetes, and Storage optimization recommendations.",
tooltip_reports: "Set the frequency Ternary delivers your spend reports.",
typeInputLabel: "Type",
typeOrgLabel: "Org",
typePersonalLabel: "Personal",
usersHeader: "Users",
};
