import Modal from "@/ui-lib/components/Modal";
import IconJira from "@/ui-lib/icons/IconJira";
import IconSlack from "@/ui-lib/icons/IconSlack";
import { useTheme } from "@emotion/react";
import { faCircleSmall } from "@fortawesome/pro-solid-svg-icons";
import { SocialIntegrationType } from "@ternary/api-lib/constants/enums";
import {
  JiraIntegrationEntity as _JiraIntegrationEntity,
  SlackIntegrationEntity as _SlackIntegrationEntity,
} from "@ternary/api-lib/core/types";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import { IntegrationStatusVariant } from "../constants";
import copyText from "../copyText";
import { getIntegrationStatusColor } from "../utils";
import { IntegrationStatusTag } from "./IntegrationStatusTag";

const UNICODE_BULLET = "\u2022";

type JiraIntegrationEntity = Omit<
  _JiraIntegrationEntity,
  "encryptedAPIToken" | "instanceID" | "issueTypeID"
>;

type SlackIntegrationEntity = Omit<
  _SlackIntegrationEntity,
  "accessToken" | "authedUserID"
>;

interface Props {
  jiraIntegration: JiraIntegrationEntity | undefined;
  slackIntegration: SlackIntegrationEntity | undefined;
  providerType: SocialIntegrationType;
  onClose: () => void;
  onInteraction: (
    interaction: SocialIntegrationDetailsModal.Interaction
  ) => void;
}

export function SocialIntegrationDetailsModal(
  props: Props
): JSX.Element | null {
  const theme = useTheme();

  const handleIntegrationDetailsHeader = () => {
    const name =
      props.providerType === SocialIntegrationType.JIRA
        ? copyText.jiraIntegrationTitle
        : copyText.slackIntegrationTitle;

    let iconComponent: JSX.Element = <></>;
    switch (props.providerType) {
      case SocialIntegrationType.JIRA:
        iconComponent = <IconJira size={24} />;
        break;
      case SocialIntegrationType.SLACK:
        iconComponent = <IconSlack size={24} />;
        break;
    }
    const color = getIntegrationStatusColor(
      IntegrationStatusVariant.SUCCESS,
      theme
    );
    return (
      <Flex alignItems="center" justifyContent="space-between">
        <Flex alignItems="center">
          <Flex
            alignItems="center"
            backgroundColor="white"
            border={`1px solid ${theme.border_color}`}
            borderRadius="8px"
            height={48}
            marginRight={theme.space_sm}
            justifyContent="center"
            width={48}
          >
            {iconComponent}
          </Flex>
          <Text appearance="h4">{name}</Text>
          <Box marginLeft={theme.space_sm}>
            <IntegrationStatusTag variant={IntegrationStatusVariant.SUCCESS}>
              <Flex paddingHorizontal={theme.space_sm} alignItems="center">
                <Icon icon={faCircleSmall} color={color} size="xs" />
                <Text marginLeft={theme.space_xxs} color={color}>
                  {copyText.integrationStatusLabelConnected}
                </Text>
              </Flex>
            </IntegrationStatusTag>
          </Box>
        </Flex>
      </Flex>
    );
  };

  const handleIntegrationDetailsBody = () => {
    switch (props.providerType) {
      case SocialIntegrationType.JIRA: {
        const config = props.jiraIntegration;
        if (!config) {
          return;
        }

        return (
          <Flex>
            <Box>
              <Text bold>{copyText.jiraIntegrationInstanceUrlLabel}</Text>
              <Text marginBottom={theme.space_md}>{config.instanceURL}</Text>
              <Text bold>{copyText.jiraIntegrationProjectIDLabel}</Text>
              <Text marginBottom={theme.space_md}>{config.projectID}</Text>
              <Text bold>{copyText.jiraIntegrationEmailLabel}</Text>
              <Text marginBottom={theme.space_md}>{config.email}</Text>
              <Text bold>{copyText.jiraIntegrationApiTokenLabel}</Text>
              <Text marginBottom={theme.space_md}>
                {formatSecretPreview(config.apiTokenPreview)}
              </Text>
            </Box>
          </Flex>
        );
      }
      case SocialIntegrationType.SLACK: {
        const config = props.slackIntegration;
        if (!config) {
          return;
        }

        return (
          <Flex>
            <Box>
              <Text bold>{copyText.slackIntegrationWorkspaceURLLabel}</Text>
              <Text marginBottom={theme.space_md}>{config.workspaceURL}</Text>
              <Text bold>{copyText.slackIntegrationAttributeTeamURL}</Text>
              <Text marginBottom={theme.space_md}>{config.teamID}</Text>
              <Text bold>{copyText.slackIntegrationAttributeChannelID}</Text>
              <Text marginBottom={theme.space_md}>{config.channelID}</Text>
            </Box>
          </Flex>
        );
      }
    }
  };

  return (
    <Modal isOpen showCloseButton onClose={props.onClose} width="640px">
      <Modal.Header>{handleIntegrationDetailsHeader()}</Modal.Header>
      <Modal.Body>{handleIntegrationDetailsBody()}</Modal.Body>
      <Modal.Footer>
        <Flex width="100%" justifyContent="space-between">
          <Button
            type="button"
            variant="danger"
            onClick={() =>
              props.onInteraction({
                type: SocialIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED,
                providerType: props.providerType,
              })
            }
          >
            {copyText.actionDelete}
          </Button>
          <Box>
            <Button
              primary
              type="button"
              onClick={() =>
                props.onInteraction({
                  type: SocialIntegrationDetailsModal.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED,
                  providerType: props.providerType,
                })
              }
            >
              {copyText.actionItemUpdateIntegrations}
            </Button>
          </Box>
        </Flex>
      </Modal.Footer>
    </Modal>
  );
}

SocialIntegrationDetailsModal.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED =
  `SocialIntegrationDetailsModal.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED` as const;

SocialIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED =
  `SocialIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED` as const;

interface InteractionUpdateButtonClicked {
  type: typeof SocialIntegrationDetailsModal.INTERACTION_UPDATE_INTEGRATION_BUTTON_CLICKED;
  providerType: SocialIntegrationType;
}

interface InteractionDeleteButtonClicked {
  type: typeof SocialIntegrationDetailsModal.INTERACTION_DELETE_BUTTON_CLICKED;
  providerType: SocialIntegrationType;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SocialIntegrationDetailsModal {
  export type Interaction =
    | InteractionUpdateButtonClicked
    | InteractionDeleteButtonClicked;
}

export default SocialIntegrationDetailsModal;
function formatSecretPreview(text: string): string {
  const [start, _, end] = text.split(":");

  return `${start}${UNICODE_BULLET.repeat(32)}${end}`;
}
