import { useTheme } from "@emotion/react";
import { faCog, faPlus, faX } from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { UserEntity } from "@ternary/api-lib/core/types";
import Table, {
  ActionMenuButton,
} from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Tooltip from "@ternary/api-lib/ui-lib/components/Tooltip";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import { keyBy } from "lodash";
import React, { useMemo } from "react";
import useGatekeeper from "../../../hooks/useGatekeeper";
import Dropdown from "../../../ui-lib/components/Dropdown";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import Modal from "../../../ui-lib/components/Modal";
import copyText from "../copyText";

type Manifest = {
  id: string;
  name: string;
  createdAt: string;
  createdByID: string;
  endDate: string;
  expirationTime: string;
  startDate: string;
};

const columnHelper = createColumnHelper<Manifest>();

interface Props {
  hasInvoiceSettings: boolean;
  hasMspBillingInfo: boolean;
  isLoading: boolean;
  isProcessing: boolean;
  manifests: Manifest[];
  users: UserEntity[];
  tenant: { name: string };
  onInteraction: (interaction: MspBillingManifestListModal.Interaction) => void;
}

export function MspBillingManifestListModal(props: Props) {
  const theme = useTheme();
  const gatekeeper = useGatekeeper();

  const usersKeyedByID = keyBy(props.users, "id");

  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        cell: (context) => {
          return (
            <Tooltip content={context.getValue()}>
              <Text truncate>{context.getValue()}</Text>
            </Tooltip>
          );
        },
        header: copyText.tableHeaderName,
        size: 170,
      }),
      columnHelper.accessor("createdAt", {
        cell: (context) => {
          return (
            <Text>
              {`${formatDate(new Date(context.getValue()), "MM/dd/yyyy hh:mm a")}`}
            </Text>
          );
        },
        header: copyText.tableHeaderCreatedAt,
        size: 170,
      }),
      columnHelper.accessor("startDate", {
        cell: (context) => {
          return (
            <Flex>
              <Text>
                {`${formatDate(new Date(context.getValue()), "MM/dd/yyyy hh:mm a")} - ${formatDate(new Date(context.row.original.endDate), "MM/dd/yyyy hh:mm a")}`}
              </Text>
            </Flex>
          );
        },
        header: copyText.tableHeaderDateRange,
        size: 300,
      }),
      columnHelper.accessor("expirationTime", {
        cell: (context) => {
          return (
            <Flex>
              <Text>
                {`${formatDate(new Date(context.getValue()), "MM/dd/yyyy hh:mm a")}`}
              </Text>
            </Flex>
          );
        },
        header: copyText.tableHeaderExpiresOn,
        size: 125,
      }),
      columnHelper.accessor("createdByID", {
        cell: (context) => {
          return <Text>{usersKeyedByID[context.getValue()].email}</Text>;
        },
        header: copyText.tableHeaderOwner,
      }),
      columnHelper.display({
        id: "actionMenu",
        cell: function renderButton({ row }) {
          const dropdownOptions = [
            {
              disabled: !gatekeeper.canViewMspBillingManifest,
              label: copyText.manifestListModalExportManifest,
              locked: !gatekeeper.canViewMspBillingManifest,
              onClick: () =>
                props.onInteraction({
                  type: MspBillingManifestListModal.INTERACTION_DOWNLOAD_MANIFEST_CLICKED,
                  manifestID: row.original.id,
                }),
            },
            {
              disabled:
                !gatekeeper.canViewMspBillingManifest ||
                !props.hasInvoiceSettings ||
                !props.hasMspBillingInfo,
              label: copyText.manifestListModalExportPdf,
              locked:
                !gatekeeper.canViewMspBillingManifest ||
                !props.hasInvoiceSettings ||
                !props.hasMspBillingInfo,
              onClick: () =>
                props.onInteraction({
                  type: MspBillingManifestListModal.INTERACTION_DOWNLOAD_PDF_CLICKED,
                  manifestID: row.original.id,
                }),
            },
          ];

          return (
            <Tooltip
              content={getTooltipText(
                props.hasInvoiceSettings,
                props.hasMspBillingInfo
              )}
              hide={props.hasInvoiceSettings && props.hasMspBillingInfo}
            >
              <Dropdown
                disabled={props.isProcessing}
                options={dropdownOptions}
                placement="bottom"
              >
                {props.isProcessing ? <LoadingSpinner /> : <ActionMenuButton />}
              </Dropdown>
            </Tooltip>
          );
        },
        size: 30,
      }),
    ],
    [props.isLoading || props.isProcessing]
  );

  return (
    <Modal isOpen={true} width={1250}>
      <Modal.Header>
        <Text appearance="h4">{`${copyText.modalTitleManifests} - ${props.tenant.name}`}</Text>
        <Flex alignItems="center">
          <Button
            iconStart={<Icon icon={faPlus} />}
            marginRight={theme.space_sm}
            secondary
            onClick={() =>
              props.onInteraction({
                type: MspBillingManifestListModal.INTERACTION_CREATE_BUTTON_CLICKED,
              })
            }
          >
            {copyText.manifestListModalNewLabel}
          </Button>
          <Button
            iconStart={<Icon icon={faX} />}
            size="small"
            onClick={() => {
              props.onInteraction({
                type: MspBillingManifestListModal.INTERACTION_CANCEL_BUTTON_CLICKED,
              });
            }}
          />
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Box height={500}>
          <Table
            columns={columns}
            data={props.manifests}
            initialState={{ sorting: [{ id: "name", desc: false }] }}
            isLoading={props.isLoading}
            showPagination
            sortable
          />
        </Box>
        <Flex justifyContent="flex-end" width="100%">
          <Button
            iconStart={<Icon icon={faCog} />}
            secondary
            onClick={() =>
              props.onInteraction({
                type: MspBillingManifestListModal.INTERACTION_INVOICE_SETTINGS_CLICKED,
              })
            }
          />
        </Flex>
      </Modal.Body>
    </Modal>
  );
}

function getTooltipText(
  hasInvoiceSettings: boolean,
  hasMspBillingInfo: boolean
): string {
  if (!hasInvoiceSettings && !hasMspBillingInfo) {
    return "Please update your invoice settings and add billing information for the customer tenant.";
  } else if (!hasInvoiceSettings) {
    return "Please add your invoice settings.";
  } else if (!hasMspBillingInfo) {
    return "Please add billing information for the customer tenant.";
  } else {
    return "";
  }
}

MspBillingManifestListModal.INTERACTION_CANCEL_BUTTON_CLICKED =
  "ManifestListModal.INTERACTION_CANCEL_BUTTON_CLICKED" as const;
MspBillingManifestListModal.INTERACTION_CREATE_BUTTON_CLICKED =
  "ManifestListModal.INTERACTION_CREATE_BUTTON_CLICKED" as const;
MspBillingManifestListModal.INTERACTION_DOWNLOAD_MANIFEST_CLICKED =
  "ManifestListModal.INTERACTION_DOWNLOAD_MANIFEST_CLICKED" as const;
MspBillingManifestListModal.INTERACTION_DOWNLOAD_PDF_CLICKED =
  "ManifestListModal.INTERACTION_DOWNLOAD_PDF_CLICKED" as const;
MspBillingManifestListModal.INTERACTION_INVOICE_SETTINGS_CLICKED =
  "ManifestListModal.INTERACTION_INVOICE_SETTINGS_CLICKED" as const;

type InteractionCancelButtonClicked = {
  type: typeof MspBillingManifestListModal.INTERACTION_CANCEL_BUTTON_CLICKED;
};

type InteractionCreateButtonClicked = {
  type: typeof MspBillingManifestListModal.INTERACTION_CREATE_BUTTON_CLICKED;
};

type InteractionDownloadManifestClicked = {
  type: typeof MspBillingManifestListModal.INTERACTION_DOWNLOAD_MANIFEST_CLICKED;
  manifestID: string;
};

type InteractionDownloadPdfClicked = {
  type: typeof MspBillingManifestListModal.INTERACTION_DOWNLOAD_PDF_CLICKED;
  manifestID: string;
};

type InteractionInvoiceSettingsClicked = {
  type: typeof MspBillingManifestListModal.INTERACTION_INVOICE_SETTINGS_CLICKED;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace MspBillingManifestListModal {
  export type Interaction =
    | InteractionCancelButtonClicked
    | InteractionCreateButtonClicked
    | InteractionDownloadManifestClicked
    | InteractionDownloadPdfClicked
    | InteractionInvoiceSettingsClicked;
}

export default MspBillingManifestListModal;
