import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { useQuery } from "@tanstack/react-query";
import CoreAPIClient from "../../../api/core/CoreAPIClient";
import useGatekeeper from "../../../hooks/useGatekeeper";
import { UseQueryOptions, UseQueryResult } from "../../../lib/react-query";
import copyText from "../copyText";

type TextWidgetEntity = Awaited<
  ReturnType<CoreAPIClient["getTextWidgetsByTenantID"]>
>[0];

export default function useGetTextWidgetsByTenantID(
  tenantID: string,
  options?: UseQueryOptions<TextWidgetEntity[], CoreAPIClientError>
): UseQueryResult<TextWidgetEntity[], CoreAPIClientError> {
  const client = useCoreAPIClient();
  const gatekeeper = useGatekeeper();

  const enabled = options?.enabled
    ? options.enabled && gatekeeper.canListSavingOpportunityFilters
    : gatekeeper.canListSavingOpportunityFilters;

  return useQuery({
    queryKey: ["textWidgets", tenantID],
    queryFn: () => client.getTextWidgetsByTenantID(tenantID),
    enabled,
    meta: { errorMessage: copyText.errorLoadingTextWidgetsMessage },
    ...options,
  });
}
