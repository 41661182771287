import paths from "@/constants/paths";
import { useActivityTracker } from "@/context/ActivityTrackerProvider";
import useGetSlackIntegrationByTenantID from "@/features/admin/hooks/useGetSlackIntegrationByTenantID";
import useUpdateSlackIntegration from "@/features/admin/hooks/useUpdateSlackIntegration";
import Form from "@/ui-lib/components/Form";
import LoadingSpinner from "@/ui-lib/components/LoadingSpinner";
import { AlertType, postAlert } from "@/utils/alerts";
import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/pro-solid-svg-icons";
import { FormApi, useForm } from "@tanstack/react-form";
import { SocialIntegrationType } from "@ternary/api-lib/constants/enums";
import { actions } from "@ternary/api-lib/telemetry";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEqual } from "lodash";
import React from "react";
import { useLocation } from "react-router-dom";
import useAuthenticatedUser from "../../../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../../../lib/react-router";
import copyText from "../../copyText";
import { _defaultValues } from "./constants";
import SlackIntegrationForm from "./SlackIntegrationForm";
import { FormData } from "./types";

type SlackIntegration = {
  channelID: string;
};

export function SlackIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const theme = useTheme();

  //
  // State
  //

  const slackIntegrationLocation: SlackIntegration | undefined = get(
    location.state,
    "integration"
  );

  //
  // Queries
  //

  const {
    data: slackIntegrationServer,
    isLoading: isLoadingSlackIntegrationServer,
    refetch: refetchSlackIntegration,
  } = useGetSlackIntegrationByTenantID(authenticatedUser.tenant.fsDocID, {
    enabled: !slackIntegrationLocation,
  });

  //
  // Mutations
  //

  const {
    isPending: isUpdatingSlackIntegration,
    mutate: updateSlackIntegration,
  } = useUpdateSlackIntegration({
    onSuccess: () => {
      refetchSlackIntegration();

      postAlert({
        type: AlertType.SUCCESS,
        message: copyText.successUpdatingSocialIntegrationMessage,
      });

      navigate(paths._admin, { searchParams: { tab: "integrations" } });
    },
    onError: () => {
      postAlert({
        type: AlertType.ERROR,
        message: copyText.slackErrorUpdatingIntegration,
      });
    },
  });

  //
  // Computed Values
  //

  const slackIntegration = slackIntegrationLocation ?? slackIntegrationServer;

  //
  // Interaction Handlers
  //

  const defaultValues = slackIntegration
    ? getValuesFromIntegration(slackIntegration)
    : _defaultValues;

  const handleSubmitSlack = ({
    formApi,
    value,
  }: {
    value: FormData;
    formApi: FormApi<FormData>;
  }) => {
    if (slackIntegration) {
      const defaultValues = formApi.options.defaultValues;
      const tenantID = authenticatedUser.tenant.fsDocID;
      if (!defaultValues) return;
      handleUpdateSlackIntegration(tenantID, value);
    }
  };

  function handleUpdateSlackIntegration(tenantID: string, values: FormData) {
    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: SocialIntegrationType.SLACK,
    });

    updateSlackIntegration({
      tenantID,
      channelID: values.channelID,
    });
  }

  const form = useForm({
    defaultValues: defaultValues,
    onSubmit: handleSubmitSlack,
  });

  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "integrations" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text marginVertical={theme.space_md} appearance="h3">
        {copyText.slackIntegrationTitle}
      </Text>

      <Flex height="100%" width="100%">
        {isLoadingSlackIntegrationServer ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <Form
            flex
            width="55%"
            onSubmit={(event) => {
              event.preventDefault();
              event.stopPropagation();
              form.handleSubmit();
            }}
          >
            <SlackIntegrationForm form={form} />
            <form.Subscribe
              children={(state) => {
                let disabled =
                  !state.canSubmit ||
                  !state.isTouched ||
                  isLoadingSlackIntegrationServer ||
                  isUpdatingSlackIntegration;
                if (slackIntegration) {
                  const hasChanged = getHasChanged(
                    state.values,
                    form.options.defaultValues
                  );

                  disabled = disabled || !hasChanged;
                }

                return (
                  <Button
                    marginTop={theme.space_sm}
                    disabled={disabled}
                    primary
                    type="submit"
                    width={100}
                  >
                    {isUpdatingSlackIntegration ? (
                      <LoadingSpinner />
                    ) : (
                      copyText.submitButtonLabel
                    )}
                  </Button>
                );
              }}
            />
          </Form>
        )}
      </Flex>
    </Flex>
  );
}

function getValuesFromIntegration(integration: SlackIntegration) {
  return {
    channelID: integration.channelID,
  };
}

function getHasChanged(values, defaultValues) {
  return Object.keys(values).some(
    (key) => !isEqual(values[key], defaultValues[key])
  );
}
