import Permission from "@ternary/api-lib/constants/permissions";
import {} from "@ternary/api-lib/constants/system";
import { GateGetterParameters } from "../../api/core/useGetGatekeeper";

export type TextWidgetGates = ReturnType<typeof textWidgetGates>;

export default function textWidgetGates(params: GateGetterParameters) {
  return {
    canCreateTextWidgets: params._permissionsIncludeAllOf([
      Permission.CREATE_TEXT_WIDGET,
    ]),
    canListTextWidgets: params._permissionsIncludeAllOf([
      Permission.READ_TEXT_WIDGETS,
    ]),
    getCanEditTextWidget: (createdByID: string) =>
      params._permissionsIncludeAllOf([Permission.UPDATE_TEXT_WIDGET]) ||
      createdByID === params.authenticatedUser.id,
    getCanDeleteTextWidget: (createdByID: string) =>
      params._permissionsIncludeAllOf([Permission.DELETE_TEXT_WIDGET]) ||
      createdByID === params.authenticatedUser.id,
  };
}
