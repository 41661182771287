import IconJira from "@/ui-lib/icons/IconJira";
import IconSlack from "@/ui-lib/icons/IconSlack";
import { Theme, useTheme } from "@emotion/react";
import { faCircleSmall } from "@fortawesome/pro-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { SocialIntegrationType } from "@ternary/api-lib/constants/enums";
import {
  JiraIntegrationEntity as _JiraIntegrationEntity,
  SlackIntegrationEntity as _SlackIntegrationEntity,
} from "@ternary/api-lib/core/types";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import React, { useMemo } from "react";
import { IntegrationStatusVariant } from "../constants";
import copyText from "../copyText";
import { getIntegrationStatusColor } from "../utils";
import { IntegrationStatusTag } from "./IntegrationStatusTag";

type TableData = {
  name: string;
  providerType: SocialIntegrationType;
  status: boolean;
};

const columnHelper = createColumnHelper<TableData>();

type JiraIntegrationEntity = Omit<
  _JiraIntegrationEntity,
  "encryptedAPIToken" | "instanceID" | "issueTypeID"
>;

type SlackIntegrationEntity = Omit<
  _SlackIntegrationEntity,
  "accessToken" | "authedUserID"
>;

interface Props {
  jiraIntegration: JiraIntegrationEntity | undefined;
  slackIntegration: SlackIntegrationEntity | undefined;
  isLoading: boolean;
  onInteraction: (interaction: SocialIntegrationsTable.Interaction) => void;
}

export function SocialIntegrationsTable(props: Props) {
  const theme = useTheme();
  const columns = useMemo(() => {
    return [
      columnHelper.accessor("name", {
        cell: ({ row, getValue }) =>
          renderIntegrationName(row.original.providerType, getValue(), theme),
        header: copyText.tableHeaderName,

        minSize: 800,
      }),
      columnHelper.accessor("status", {
        cell: ({ getValue }) => renderIntegrationStatus(getValue(), theme),
        header: copyText.tableHeaderStatus,
        minSize: 200,
      }),
      columnHelper.display({
        id: "details",
        cell: ({ row }) => {
          const status = row.original.status;
          const type = row.original.providerType;
          let buttonLabel = copyText.integrationButtonLabelDetails;

          if (!status && type === SocialIntegrationType.SLACK) {
            buttonLabel = copyText.integrationButtonLabelSlack;
          } else if (!status) {
            buttonLabel = copyText.integrationButtonLabelConfigure;
          }
          return (
            <Button
              size="small"
              primary={!status}
              secondary={status}
              onClick={() => {
                if (status) {
                  props.onInteraction({
                    type: SocialIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED,
                    providerType: type,
                  });
                } else if (!status && type === SocialIntegrationType.SLACK) {
                  props.onInteraction({
                    type: SocialIntegrationsTable.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED,
                  });
                } else {
                  props.onInteraction({
                    type: SocialIntegrationsTable.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED,
                  });
                }
              }}
            >
              {buttonLabel}
            </Button>
          );
        },
        meta: { align: "right" },
      }),
    ];
  }, [props.jiraIntegration, props.slackIntegration]);

  const data = useMemo(() => {
    const tableData: TableData[] = [
      {
        name: copyText.jiraIntegrationTitle,
        providerType: SocialIntegrationType.JIRA,
        status: !!props.jiraIntegration,
      },
      {
        name: copyText.slackIntegrationTitle,
        providerType: SocialIntegrationType.SLACK,
        status: !!props.slackIntegration,
      },
    ];

    return tableData;
  }, [props.jiraIntegration, props.slackIntegration]);

  return (
    <Table
      columns={columns}
      data={data}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

SocialIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED =
  `SocialIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED` as const;

SocialIntegrationsTable.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED =
  `SocialIntegrationsTable.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED` as const;

SocialIntegrationsTable.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED =
  `SocialIntegrationsTable.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED` as const;

interface InteractionDetailButtonClicked {
  type: typeof SocialIntegrationsTable.INTERACTION_DETAIL_BUTTON_CLICKED;
  providerType: SocialIntegrationType;
}

type InteractionConfigureIntegrationButtonClicked = {
  type: typeof SocialIntegrationsTable.INTERACTION_CONFIGURE_INTEGRATION_BUTTON_CLICKED;
};

type InteractionCreateSlackIntegration = {
  type: typeof SocialIntegrationsTable.INTERACTION_CREATE_INTEGRATION_BUTTON_CLICKED;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace SocialIntegrationsTable {
  export type Interaction =
    | InteractionDetailButtonClicked
    | InteractionConfigureIntegrationButtonClicked
    | InteractionCreateSlackIntegration;
}

export default SocialIntegrationsTable;

function renderIntegrationName(
  providerType: SocialIntegrationType,
  name: string,
  theme: Theme
) {
  let iconComponent: JSX.Element = <></>;
  switch (providerType) {
    case SocialIntegrationType.JIRA:
      iconComponent = <IconJira size={24} />;
      break;
    case SocialIntegrationType.SLACK:
      iconComponent = <IconSlack size={24} />;
      break;
  }
  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Flex
          alignItems="center"
          backgroundColor="white"
          border={`1px solid ${theme.border_color}`}
          borderRadius="8px"
          height={40}
          marginRight={theme.space_sm}
          justifyContent="center"
          width={40}
        >
          {iconComponent}
        </Flex>
        <Text>{name}</Text>
      </Flex>
    </Flex>
  );
}

function renderIntegrationStatus(status: boolean, theme: Theme) {
  const statusVariant = status
    ? IntegrationStatusVariant.SUCCESS
    : IntegrationStatusVariant.INCOMPLETE;
  const textLabel = status
    ? copyText.integrationStatusLabelConnected
    : copyText.integrationStatusLabelNotConfigured;
  const color = getIntegrationStatusColor(statusVariant, theme);

  return (
    <IntegrationStatusTag variant={statusVariant}>
      <Flex paddingHorizontal={theme.space_sm} alignItems="center">
        <Icon icon={faCircleSmall} color={color} size="xs" />
        <Text marginLeft={theme.space_xxs} color={color}>
          {textLabel}
        </Text>
      </Flex>
    </IntegrationStatusTag>
  );
}
