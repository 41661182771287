import externalLinks from "@/constants/externalLinks";
import ConfirmationModal from "@/ui-lib/components/ConfirmationModal";
import ParsedText from "@/ui-lib/components/ParsedText";
import TextInput from "@/ui-lib/components/TextInput";
import { useTheme } from "@emotion/react";
import { faEdit, faTimes, faUser } from "@fortawesome/free-solid-svg-icons";
import { LinkedAccount } from "@ternary/api-lib/core/types";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import Box from "@ternary/web-ui-lib/components/Box";
import React, { useEffect, useState } from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import copyText from "../../copyText";
import {
  AWS_ROLE_ARN_ACCOUNT_ID_REGEX,
  AWS_ROLE_ARN_REGEX,
  defaultValues,
} from "./constants";
import { FormProps } from "./types";

function getAccountId(roleARN: string) {
  const match = roleARN.match(AWS_ROLE_ARN_ACCOUNT_ID_REGEX);
  return match ? match[1] : "";
}

const validators = {
  roleARN: ({
    value,
    form,
    editingIndex,
  }: {
    value: string;
    form: FormProps["form"];
    editingIndex?: number | null;
  }) => {
    if (value.length === 0) {
      return undefined;
    }

    if (!AWS_ROLE_ARN_REGEX.test(value)) {
      return copyText.errorInputFieldArnError;
    }

    // Get existing linked accounts
    const linkedAccounts = form.getFieldValue("linkedAccounts") || [];

    // Check for duplicate ARN, excluding the currently editing row
    const isDuplicateARN = linkedAccounts.some(
      (account: LinkedAccount, index: number) =>
        account.roleARN === value && index !== editingIndex
    );
    if (isDuplicateARN) {
      return copyText.awsFormLinkedAccountARNAlreadyExists;
    }

    // Check for duplicate Account ID
    const currentAccountId = value.match(AWS_ROLE_ARN_ACCOUNT_ID_REGEX)?.[1];
    const isDuplicateAccountId = linkedAccounts.some(
      (account: LinkedAccount, index: number) =>
        getAccountId(account.roleARN) === currentAccountId &&
        index !== editingIndex
    );
    if (isDuplicateAccountId) {
      return copyText.awsFormLinkedAccountIdAlreadyExists;
    }

    return undefined;
  },
};

function LinkedAccountRow(props: {
  data: LinkedAccount;
  onEdit: () => void;
  onRemove: () => void;
  disableActionButtons?: boolean;
}) {
  const { data, onEdit, onRemove, disableActionButtons } = props;
  const theme = useTheme();

  return (
    <Flex alignItems="center" justifyContent="space-between">
      <Flex alignItems="center">
        <Flex justifyContent="center" minWidth={20}>
          <Icon color={theme.text_color} icon={faUser} />
        </Flex>
        <Flex marginLeft={theme.space_xs} style={{ maxWidth: "40vw" }}>
          <Text truncate fontSize={theme.fontSize_base}>
            {data.roleARN}
          </Text>
        </Flex>
      </Flex>
      <Box>
        <Button
          disabled={disableActionButtons}
          iconEnd={<Icon icon={faEdit} height={"24px"} />}
          marginRight={theme.space_xxs}
          secondary
          size="tiny"
          type="button"
          onClick={onEdit}
        />
        <Button
          disabled={disableActionButtons}
          iconEnd={<Icon icon={faTimes} height={"24px"} />}
          secondary
          size="tiny"
          type="button"
          onClick={onRemove}
        />
      </Box>
    </Flex>
  );
}

function LinkedAccountForm(
  props: FormProps & { editingIndex?: number | null }
) {
  const { form, editingIndex } = props;

  return (
    <Flex direction="column" width={"100%"}>
      <form.Field
        name={"linkedAccountARN"}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              caption={
                <ParsedText
                  text={copyText.awsFormLinkedAccountARNCaption}
                  components={{
                    link: (
                      <a
                        href={
                          externalLinks.awsIntegrationLinkedAccountsDocumentation
                        }
                        rel="noreferrer"
                        target="_blank"
                      />
                    ),
                  }}
                />
              }
              input={TextInput}
              label={copyText.awsFormLinkedAccountRoleArn}
              placeholder={copyText.awsFormPlaceholderRoleArn}
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
        validators={{
          onChange: ({ value }) =>
            validators.roleARN({ value, form, editingIndex }),
          onMount: ({ value }) =>
            validators.roleARN({ value, form, editingIndex }),
        }}
      />
    </Flex>
  );
}

export function AwsFormLinkedAccounts(props: FormProps) {
  const theme = useTheme();
  const { form } = props;

  //
  // State
  //

  const [editRowIdx, setEditRowIdx] = useState<number | null>(null);
  const [isOpen, setIsOpen] = useState(false);

  //
  // Side Effects
  //

  useEffect(() => {
    return () => {
      // Reset account form on unmount for the submit button.
      setFieldErrors(false);
    };
  }, []);

  //
  // Interaction Handlers
  //

  function handleEditRow() {
    if (editRowIdx === null) {
      return;
    }

    const value = form.getFieldValue("linkedAccounts")[editRowIdx];
    form.setFieldValue("linkedAccountARN", value.roleARN);
    setFieldErrors(false);
  }

  function setFieldErrors(error: boolean) {
    form.setFieldMeta(`linkedAccountARN`, {
      errors: error ? [copyText.errorInputFieldRequired] : [],
      errorMap: error ? { onMount: copyText.errorInputFieldRequired } : {},
      isBlurred: false,
      isDirty: false,
      isPristine: true,
      isTouched: false,
      isValidating: false,
    });
  }

  return (
    <Box minWidth={"100%"} padding={theme.space_xxs}>
      <form.Field mode="array" name="linkedAccounts">
        {(configsField) => (
          <Flex direction="column" gap={theme.space_md} width="100%">
            {isOpen && (
              <ConfirmationModal
                message={copyText.awsFormEditLinkedAccountModalMessage}
                title={copyText.awsFormEditLinkedAccountConfirmationTitle}
                variant="danger"
                onCancel={() => {
                  setEditRowIdx(null);
                  setIsOpen(false);
                }}
                onConfirm={() => {
                  handleEditRow();
                  setIsOpen(false);
                }}
              />
            )}
            <LinkedAccountForm form={form} editingIndex={editRowIdx} />

            <form.Subscribe
              children={(state) => {
                function handleAddConfig(
                  event: React.MouseEvent<HTMLButtonElement>
                ) {
                  event.preventDefault();
                  event.stopPropagation();

                  const linkedAccountARN = state.values.linkedAccountARN;
                  const validationError = validators.roleARN({
                    value: linkedAccountARN,
                    form,
                    editingIndex: null,
                  });

                  if (!validationError) {
                    const accountID = getAccountId(linkedAccountARN);
                    configsField.pushValue({
                      accountID,
                      roleARN: linkedAccountARN,
                      validations: [],
                    });

                    setEditRowIdx(null);
                    setFieldErrors(true);
                    form.setFieldValue(
                      "linkedAccountARN",
                      defaultValues.linkedAccountARN
                    );
                  }
                }

                function handleEditConfig(
                  event: React.MouseEvent<HTMLButtonElement>
                ) {
                  event.preventDefault();
                  event.stopPropagation();

                  if (editRowIdx !== null) {
                    const linkedAccountARN = state.values.linkedAccountARN;
                    const validationError = validators.roleARN({
                      value: linkedAccountARN,
                      form,
                      editingIndex: editRowIdx,
                    });

                    if (!validationError) {
                      const linkedAccountConfig = {
                        accountID: getAccountId(linkedAccountARN),
                        roleARN: linkedAccountARN,
                        validations: [],
                      };

                      configsField.replaceValue(
                        editRowIdx,
                        linkedAccountConfig
                      );
                      setFieldErrors(true);
                      form.setFieldValue(
                        "linkedAccountARN",
                        defaultValues.linkedAccountARN
                      );
                      setEditRowIdx(null);
                    }
                  }
                }

                function handleCancelConfig(
                  event: React.MouseEvent<HTMLButtonElement>
                ) {
                  event.preventDefault();
                  event.stopPropagation();

                  setFieldErrors(true);
                  form.setFieldValue(
                    "linkedAccountARN",
                    defaultValues.linkedAccountARN
                  );
                  setEditRowIdx(null);
                }

                const linkedAccountARNFieldMeta =
                  form.getFieldMeta("linkedAccountARN");

                const linkedAccountARN = form.getFieldValue("linkedAccountARN");

                const disabled =
                  !linkedAccountARN ||
                  (linkedAccountARNFieldMeta?.errors &&
                    linkedAccountARNFieldMeta?.errors?.length > 0);

                if (editRowIdx !== null) {
                  return (
                    <Flex gap={theme.space_md}>
                      <Button
                        secondary
                        size="small"
                        type="button"
                        onClick={handleCancelConfig}
                      >
                        {copyText.actionCancel}
                      </Button>
                      <Button
                        disabled={disabled}
                        primary
                        size="small"
                        type="button"
                        onClick={handleEditConfig}
                      >
                        {copyText.actionSave}
                      </Button>
                    </Flex>
                  );
                }

                return (
                  <div>
                    <Button
                      disabled={disabled}
                      primary
                      size="small"
                      type="button"
                      onClick={handleAddConfig}
                    >
                      {copyText.awsFormLinkedAccountAddButtonLabel}
                    </Button>
                  </div>
                );
              }}
            />
            <Text fontSize={theme.h4_fontSize} fontWeight={theme.h4_fontWeight}>
              {copyText.awsFormLinkedAccountsListLabel.replace(
                "%VALUE%",
                `${configsField.state.value.length}`
              )}
            </Text>
            <Flex
              direction="column"
              gap={theme.space_xs}
              paddingVertical={theme.space_xxs}
            >
              {configsField.state.value.map((vl, index) => {
                return (
                  <LinkedAccountRow
                    key={`linked-account-row-${index}`}
                    data={vl}
                    disableActionButtons={index === editRowIdx}
                    onEdit={() => {
                      setEditRowIdx(index);

                      const showModal =
                        !!form.getFieldValue("linkedAccountARN");

                      if (showModal) {
                        setIsOpen(true);
                      } else {
                        form.setFieldValue("linkedAccountARN", vl.roleARN);
                        setFieldErrors(false);
                      }
                    }}
                    onRemove={() => configsField.removeValue(index)}
                  />
                );
              })}
            </Flex>
          </Flex>
        )}
      </form.Field>
    </Box>
  );
}
