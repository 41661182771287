import { useTheme } from "@emotion/react";
import { useForm } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { MspTenantSettingsEntity } from "@ternary/api-lib/core/types/MspTenantSettings";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { isEqual } from "lodash";
import React from "react";
import { useMspStore } from "../../../lib/zustand";
import Form, { FormField } from "../../../ui-lib/components/Form";
import LoadingSpinner from "../../../ui-lib/components/LoadingSpinner";
import TextInput from "../../../ui-lib/components/TextInput";
import { AlertType, postAlert } from "../../../utils/alerts";
import copyText from "../copyText";
import queryKeys from "../hooks/queryKeys";
import useGetMspTenantSettingsByTenantID from "../hooks/useGetMspTenantSettingsByTenantID";
import { useUpdateMspTenantSettings } from "../hooks/useUpdateMspTenantSettings";

export default function MspAccountLogoContainer() {
  const queryClient = useQueryClient();
  const theme = useTheme();

  //
  // State
  //

  const mspStore = useMspStore();

  const mspTenantID = mspStore.selectedParentTenantID as string;

  //
  // Queries
  //

  const { data: mspTenantSettings, isLoading: isLoadingMspTenantSettings } =
    useGetMspTenantSettingsByTenantID(mspTenantID, { enabled: !!mspTenantID });

  //
  // Mutations
  //

  const {
    isPending: isUpdatingMspTenantSettings,
    mutate: updateMspTenantSettings,
  } = useUpdateMspTenantSettings({
    onError: (_error, _params, context) => {
      queryClient.setQueryData(
        queryKeys.mspTenantSettings(mspTenantID),
        context?.previousSettings
      );

      postAlert({
        message: copyText.errorUpdatingMspTenantSettingsMessage,
        type: AlertType.ERROR,
      });
    },
    onMutate: async (params) => {
      await queryClient.cancelQueries({
        queryKey: queryKeys.mspTenantSettings(mspTenantID),
      });

      const previousSettings = queryClient.getQueryData(
        queryKeys.mspTenantSettings(mspTenantID)
      ) as MspTenantSettingsEntity;

      const newBillingInfo = { ...previousSettings, ...params };

      queryClient.setQueryData(
        queryKeys.mspTenantSettings(mspTenantID),
        newBillingInfo
      );

      return { previousSettings };
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: queryKeys.mspTenantSettings(mspTenantID),
      });

      postAlert({
        message: copyText.successUpdatingMspTenantSettingsMessage,
        type: AlertType.SUCCESS,
      });
    },
  });

  //
  // Render
  //

  const defaultValues = mspTenantSettings
    ? {
        logoImageSquareURL: mspTenantSettings.logoImageSquareURL ?? "",
        logoImageWideURL: mspTenantSettings.logoImageWideURL ?? "",
      }
    : { logoImageSquareURL: "", logoImageWideURL: "" };

  const form = useForm({
    defaultValues,
    // validators: { onMount: genericRequiredFieldValidator },
    onSubmit: ({ value }) => {
      if (!mspTenantSettings) return;

      updateMspTenantSettings({
        settingsID: mspTenantSettings.id,
        logoImageSquareURL: value.logoImageSquareURL,
        logoImageWideURL: value.logoImageWideURL,
      });
    },
  });

  return (
    <Box width="40%">
      <Box marginBottom={theme.space_md}>
        <Text>{copyText.accountLogoHeadingMessage}</Text>
      </Box>
      {isLoadingMspTenantSettings ? (
        <EmptyPlaceholder loading />
      ) : (
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            e.stopPropagation();
            form.handleSubmit();
          }}
        >
          <form.Field
            name="logoImageSquareURL"
            children={(field) => {
              const shouldShowError =
                field.state.meta.isTouched &&
                field.state.meta.errors.length > 0;

              const errorCaption = shouldShowError
                ? field.state.meta.errors.join(", ")
                : undefined;

              const variant = shouldShowError ? "danger" : undefined;

              return (
                <FormField
                  input={TextInput}
                  caption={copyText.imageLinkSquareCaption}
                  errorCaption={errorCaption}
                  label={copyText.imageLinkSquareLabel}
                  placeholder={copyText.imageLinkPlaceholder}
                  value={field.state.value}
                  variant={variant}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              );
            }}
          />
          <form.Field
            name="logoImageWideURL"
            children={(field) => {
              const shouldShowError =
                field.state.meta.isTouched &&
                field.state.meta.errors.length > 0;

              const errorCaption = shouldShowError
                ? field.state.meta.errors.join(", ")
                : undefined;

              const variant = shouldShowError ? "danger" : undefined;

              return (
                <FormField
                  input={TextInput}
                  caption={copyText.imageLinkWideCaption}
                  errorCaption={errorCaption}
                  label={copyText.imageLinkWideLabel}
                  placeholder={copyText.imageLinkPlaceholder}
                  value={field.state.value}
                  variant={variant}
                  onChange={(e) => field.handleChange(e.target.value)}
                />
              );
            }}
          />
          <form.Subscribe
            children={(state) => {
              const hasChanged = Object.keys(state.values).some(
                (key) => !isEqual(state.values[key], defaultValues[key])
              );

              const disabled =
                !state.canSubmit ||
                !state.isTouched ||
                !hasChanged ||
                isUpdatingMspTenantSettings;

              return (
                <Button disabled={disabled} primary type="submit" width={100}>
                  {isUpdatingMspTenantSettings ? (
                    <LoadingSpinner />
                  ) : (
                    copyText.submitButtonLabel
                  )}
                </Button>
              );
            }}
          />
        </Form>
      )}
    </Box>
  );
}
