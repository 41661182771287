import Chip from "@/ui-lib/components/Chip";
import { useTheme } from "@emotion/react";
import {
  faArrowDown,
  faArrowUp,
  faSearch,
} from "@fortawesome/free-solid-svg-icons";
import { createColumnHelper } from "@tanstack/react-table";
import { RawData } from "@ternary/api-lib/analytics/types";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import getMergeState, {
  COMPARISON_KEY,
  FORECASTED_KEY,
} from "@ternary/api-lib/ui-lib/charts/utils";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatCurrency } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React, { useMemo, useState } from "react";
import TextInput from "../../../ui-lib/components/TextInput";
import copyText from "../copyText";

interface State {
  filters: Record<string, string>; // Ex. { category: "compute" }
  searchText: string;
}

type TableData = {
  [dimension: string]: string | number;
  currentSpend: number;
  estimatedTotalSpend: number;
  estimatedDeltaSpend: number;
  prevMonthTotal: number;
};

const initialState: State = {
  filters: {},
  searchText: "",
};

const columnHelper = createColumnHelper<TableData>();

interface Props {
  data: RawData[];
  dimensions: string[];
  measure: string;
  isLoading: boolean;
}

export function ForecastingTable(props: Props) {
  const [state, setState] = useState<State>(initialState);
  const mergeState = getMergeState(setState);

  const theme = useTheme();

  const columns = useMemo(
    () => [
      ...props.dimensions.map((dimension) =>
        columnHelper.accessor(dimension, {
          header: dimension,
          cell: ({ getValue }) => (
            <Text
              color={theme.primary_color_text}
              cursor="pointer"
              marginRight={theme.space_xs}
              onClick={() =>
                setState((currentState) => ({
                  ...currentState,
                  filters: {
                    ...currentState.filters,
                    [dimension]: String(getValue()),
                  },
                }))
              }
            >
              {getValue()}
            </Text>
          ),
        })
      ),
      columnHelper.accessor("currentSpend", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.tableHeaderCurrentSpend,
        meta: { align: "right" },
      }),
      columnHelper.accessor("prevMonthTotal", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.tableHeaderPrevMonthTotal,
        meta: { align: "right" },
      }),
      columnHelper.accessor("estimatedTotalSpend", {
        cell: ({ getValue }) => formatCurrency({ number: getValue() }),
        header: copyText.tableHeaderEstimatedTotalSpend,
        meta: { align: "right" },
      }),
      columnHelper.accessor("estimatedDeltaSpend", {
        cell: ({ getValue }) => {
          const isPositive = getValue() > 0;
          return (
            <Flex justifyContent="flex-end" alignItems="center">
              <Text marginRight={theme.space_xxs}>
                {formatCurrency({ number: getValue() })}
              </Text>
              <Icon
                color={
                  isPositive ? theme.feedback_negative : theme.feedback_positive
                }
                icon={isPositive ? faArrowUp : faArrowDown}
              />
            </Flex>
          );
        },
        header: copyText.tableHeaderEstimatedDeltaSpend,
        meta: { align: "right" },
      }),
    ],
    [props.dimensions]
  );

  const data = useMemo(() => {
    let filteredData = getTableData(props.data);

    if (state.searchText.length > 0) {
      filteredData = filteredData.filter((datum) =>
        props.dimensions.some((dimension) =>
          String(datum[dimension])
            .toLowerCase()
            .includes(state.searchText.toLowerCase())
        )
      );
    }

    if (Object.values(state.filters).length > 0) {
      filteredData = filteredData.filter((datum) =>
        passesFilters(props.dimensions, state.filters, datum)
      );
    }

    return filteredData;
  }, [props.data, props.dimensions, state.filters, state.searchText]);

  function getTableData(data: RawData[]): TableData[] {
    return data.map((datum) => {
      const totalSpend =
        Number(datum[props.measure] ?? 0) +
        Number(datum[`${props.measure}${FORECASTED_KEY}`] ?? 0);

      return {
        ...props.dimensions.reduce(
          (accum, dimension) => ({
            ...accum,
            [dimension]: datum[dimension]?.toString() ?? "-",
          }),
          {}
        ),
        currentSpend: Number(datum[props.measure] ?? 0),
        estimatedDeltaSpend:
          totalSpend - Number(datum[`${props.measure}${COMPARISON_KEY}`] ?? 0),
        estimatedTotalSpend: totalSpend,
        prevMonthTotal: Number(datum[`${props.measure}${COMPARISON_KEY}`] ?? 0),
      };
    });
  }

  return (
    <>
      <Flex
        alignItems="center"
        backgroundColor={theme.panel_backgroundColor}
        borderRadius={theme.borderRadius_1}
        height={70}
        justifyContent="right"
        marginBottom={theme.space_md}
        padding={theme.space_md}
      >
        <Flex flex="1 0 0">
          {Object.entries(state.filters).map(([key, value]) => (
            <Chip
              key={key}
              label={key}
              text={value}
              onClick={() =>
                setState((currentState) => ({
                  ...currentState,
                  filters: Object.entries(currentState.filters).reduce(
                    (
                      accum: Record<string, string>,
                      [filterKey, filterValue]
                    ) => {
                      if (filterKey === key) {
                        return accum;
                      } else {
                        accum[filterKey] = filterValue;
                      }

                      return accum;
                    },
                    {}
                  ),
                }))
              }
            />
          ))}
        </Flex>
        <Box width="12rem" marginRight={theme.space_md}>
          <TextInput
            iconStart={
              <Icon color={theme.text_color_secondary} icon={faSearch} />
            }
            placeholder={"Search"}
            onChange={(event) => mergeState({ searchText: event.target.value })}
          />
        </Box>
      </Flex>
      <Table
        columns={columns}
        data={data}
        isLoading={props.isLoading}
        initialState={{ sorting: [{ id: "estimatedTotalSpend", desc: true }] }}
        showPagination
        sortable
      />
    </>
  );
}

function passesFilters(
  dimensions: string[],
  filters: Record<string, string | null>,
  tableDatum: TableData
): boolean {
  return !dimensions.some(
    (dimension) =>
      filters[dimension] &&
      String(tableDatum[dimension])?.toLowerCase().trim() !==
        filters[dimension]?.toLowerCase().trim()
  );
}

export default ForecastingTable;
