import { useTheme } from "@emotion/react";
import { createColumnHelper } from "@tanstack/react-table";
import {
  DurationType,
  GcpBigQueryEditionType,
} from "@ternary/api-lib/constants/enums";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { formatPercentage } from "@ternary/api-lib/ui-lib/utils/formatNumber";
import React, { useMemo } from "react";
import Modal from "../../../../ui-lib/components/Modal";
import { DateRange } from "../../../../utils/dates";
import copyText from "../../copyText";
import { getStringifiedDurationType } from "../../utils";

type TableData = {
  adminProjectId: string;
  edition: string;
  jobCount: number;
  potentialSlotS: number;
  region: string;
  reservationName: string;
  usedSlotS: number;
  utilization: string;
};

type BigQueryEdition = {
  adminProjectId: string;
  edition: GcpBigQueryEditionType;
  jobCount: number;
  potentialSlotS: number;
  region: string;
  reservationName: string;
  usedSlotS: number;
  utilization: number;
};

interface Props {
  dateRange: DateRange;
  durationType: DurationType;
  selectedDatasetID: string;
  selectedProjectID: string;
  instances: BigQueryEdition[];
  isLoading: boolean;
  onInteraction: (interaction: GcpBigQueryEditionTable.Interaction) => void;
}

export function GcpBigQueryEditionTable(props: Props): JSX.Element {
  const columnHelper = createColumnHelper<TableData>();
  const theme = useTheme();

  const columns = useMemo(
    () => [
      columnHelper.accessor("reservationName", {
        header: copyText.bigQueryEditionTableHeader_reservationName,
        size: 170,
      }),
      columnHelper.accessor("adminProjectId", {
        header: copyText.bigQueryEditionTableHeader_adminProjectId,
      }),
      columnHelper.accessor("edition", {
        header: copyText.bigQueryEditionTableHeader_edition,
      }),
      columnHelper.accessor("region", {
        header: copyText.bigQueryEditionTableHeader_region,
        meta: { align: "center" },
      }),
      columnHelper.accessor("jobCount", {
        header: copyText.bigQueryEditionTableHeader_jobCount,
        meta: { align: "center" },
      }),
      columnHelper.accessor("usedSlotS", {
        header: copyText.bigQueryEditionTableHeader_usedSlotS,
        meta: { align: "center" },
      }),
      columnHelper.accessor("potentialSlotS", {
        header: copyText.bigQueryEditionTableHeader_potentialSlotS,
        meta: { align: "center" },
      }),
      columnHelper.accessor("utilization", {
        header: copyText.bigQueryEditionTableHeader_utilization,
        meta: { align: "center" },
      }),
    ],
    []
  );

  const data: TableData[] = useMemo(() => {
    const tableData = props.instances.map((item) => {
      return {
        adminProjectId: item.adminProjectId,
        edition: getStringifiedEdition(item.edition),
        jobCount: item.jobCount,
        potentialSlotS: item.potentialSlotS,
        region: item.region,
        reservationName: item.reservationName,
        usedSlotS: Math.ceil(item.usedSlotS),
        utilization: formatPercentage(item.utilization, 3),
      };
    });
    return tableData;
  }, [props.instances]);

  return (
    <Modal
      isOpen
      closeOnClickOutside
      minWidth={500}
      showCloseButton
      onClose={() =>
        props.onInteraction({
          type: GcpBigQueryEditionTable.INTERACTION_CLOSE_BUTTON_CLICKED,
        })
      }
    >
      <Modal.Header>
        <Flex>
          <Text fontStyle="italic" appearance="h4">
            {props.selectedProjectID}
          </Text>
          <Text appearance="h4" marginHorizontal={theme.space_xxs}>
            {copyText.bigQueryEditionTableModalHeaderAnd}
          </Text>
          <Text fontStyle="italic" appearance="h4">
            {props.selectedDatasetID}
          </Text>
          <Text appearance="h4" marginHorizontal={theme.space_xxs}>
            {props.durationType === DurationType.CUSTOM
              ? copyText.bigQueryEditionTableModalHeaderPrepositionDateRange
              : copyText.bigQueryEditionTableModalHeaderPrepositionDuration}
          </Text>
          <Text appearance="h4">
            {getStringifiedDurationType(props.durationType, props.dateRange)}
          </Text>
        </Flex>
      </Modal.Header>
      <Modal.Body>
        <Table
          compact
          columns={columns}
          data={data}
          isLoading={props.isLoading}
          initialState={{ pagination: { pageSize: 8 } }}
          showPagination
          sortable
        />
      </Modal.Body>
    </Modal>
  );
}

function getStringifiedEdition(edition: GcpBigQueryEditionType): string {
  switch (edition) {
    case GcpBigQueryEditionType.ENTERPRISE: {
      return copyText.bigQueryEditionTypeEnterprise;
    }
    case GcpBigQueryEditionType.ENTERPRISE_PLUS: {
      return copyText.bigQueryEditionTypeEnterprisePlus;
    }
    case GcpBigQueryEditionType.STANDARD: {
      return copyText.bigQueryEditionTypeStandard;
    }
  }
}

GcpBigQueryEditionTable.INTERACTION_CLOSE_BUTTON_CLICKED =
  `GCPCloudSpannerInstanceTable.INTERACTION_CLOSE_BUTTON_CLICKED` as const;

interface InteractionCloseButtonClicked {
  type: typeof GcpBigQueryEditionTable.INTERACTION_CLOSE_BUTTON_CLICKED;
}

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace GcpBigQueryEditionTable {
  export type Interaction = InteractionCloseButtonClicked;
}

export default GcpBigQueryEditionTable;
