import { createColumnHelper } from "@tanstack/react-table";
import Table from "@ternary/api-lib/ui-lib/charts/Table/Table";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import { formatDate } from "@ternary/api-lib/ui-lib/utils/dates";
import React, { useMemo } from "react";
import copyText from "../copyText";

type TableData = {
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string | null;
  updatedBy: string | null;
  name: string;
};

const columnHelper = createColumnHelper<TableData>();

type Role = {
  id: string;
  createdAt: string;
  createdBy: string;
  updatedAt: string | null;
  updatedBy: string | null;
  name: string;
};

interface Props {
  isLoading: boolean;
  roles: Role[];
  onInteraction: (interaction: RoleTable.Interaction) => void;
}

export function RoleTable(props: Props) {
  const columns = useMemo(
    () => [
      columnHelper.accessor("name", {
        header: copyText.rolesTableHeaderName,
      }),
      columnHelper.accessor("id", {
        header: copyText.rolesTableHeaderID,
        size: 300,
      }),
      columnHelper.accessor("createdAt", {
        cell: ({ getValue }) => formatDate(new Date(getValue()), "MM/dd/yyyy"),
        header: copyText.rolesTableHeaderCreatedAt,
        meta: { align: "center" },
      }),
      columnHelper.accessor("createdBy", {
        header: copyText.rolesTableHeaderCreatedBy,
        meta: { align: "center" },
        size: 200,
      }),
      columnHelper.accessor("updatedAt", {
        cell: ({ getValue }) => {
          const value = getValue();

          return value ? formatDate(new Date(value), "MM/dd/yyyy") : "--";
        },
        header: copyText.rolesTableHeaderUpdatedAt,
        meta: { align: "center" },
      }),
      columnHelper.accessor("updatedBy", {
        cell: ({ getValue }) => {
          const value = getValue();

          return value ? formatDate(new Date(value), "MM/dd/yyyy") : "--";
        },
        header: copyText.rolesTableHeaderUpdatedBy,
        meta: { align: "center" },
        size: 200,
      }),
      columnHelper.display({
        id: "viewPermissionsButton",
        cell: ({ row }) => (
          <Button
            secondary
            size="tiny"
            type="button"
            onClick={() =>
              props.onInteraction({
                type: RoleTable.INTERACTION_VIEW_PERMISSIONS_BUTTON_CLICKED,
                roleID: row.original.id,
              })
            }
          >
            {copyText.viewPermissionsButtonLabel}
          </Button>
        ),
        meta: { align: "center" },
      }),
    ],
    [props.roles]
  );

  const data = useMemo(() => {
    if (props.isLoading) return [];

    return props.roles;
  }, [props.isLoading, props.roles]);

  return (
    <Table
      columns={columns}
      data={data}
      initialState={{ sorting: [{ id: "name", desc: false }] }}
      isLoading={props.isLoading}
      showPagination
      sortable
    />
  );
}

RoleTable.INTERACTION_VIEW_PERMISSIONS_BUTTON_CLICKED =
  `RoleTable.INTERACTION_VIEW_PERMISSIONS_BUTTON_CLICKED` as const;

type InteractionViewPermissionsButtonClicked = {
  type: typeof RoleTable.INTERACTION_VIEW_PERMISSIONS_BUTTON_CLICKED;
  roleID: string;
};

// eslint-disable-next-line @typescript-eslint/no-namespace
namespace RoleTable {
  export type Interaction = InteractionViewPermissionsButtonClicked;
}

export default RoleTable;
