import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormApi } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import { CloudProviderType } from "@ternary/api-lib/constants/enums";
import { GcpIntegrationEntity } from "@ternary/api-lib/core/types/GcpIntegration";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEqual } from "lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { UpdateGCPIntegrationParams } from "../../../../../api/core/types";
import paths from "../../../../../constants/paths";
import { useActivityTracker } from "../../../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../../../lib/react-router";
import { VerticalStepperForm } from "../../../../../ui-lib/components/Stepper";
import { AlertType, postAlert } from "../../../../../utils/alerts";
import queryKeys from "../../../hooks/queryKeys";
import useCreateGCPIntegration from "../../../hooks/useCreateGCPIntegration";
import useUpdateGCPIntegration from "../../../hooks/useUpdateGCPIntegration";
import useValidateDataIntegration from "../../../hooks/useValidateDataIntegration";
import copyText from "../../copyText";
import { useGetGcpIntegrationByID } from "../../hooks/useGetGcpIntegrationByID";
import { BigQueryScope, defaultValues as _defaultValues } from "./constants";
import { GcpIntegrationFormBigQuery } from "./GcpIntegrationFormBigQuery";
import { GcpIntegrationFormCarbonFootprint } from "./GcpIntegrationFormCarbonFootprint";
import { GcpIntegrationFormCustomPricing } from "./GcpIntegrationFormCustomPricing";
import { GcpIntegrationFormDetailedBilling } from "./GcpIntegrationFormDetailedBilling";
import { GcpIntegrationFormGeneral } from "./GcpIntegrationFormGeneral";
import { GcpIntegrationFormStandardBilling } from "./GcpIntegrationFormStandardBilling";
import { BigQueryConfig, FormData } from "./types";

export function GcpIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { integrationID } = useParams();

  //
  // State
  //

  const gcpIntegrationLocation: GcpIntegrationEntity | undefined = get(
    location.state,
    "integration"
  );

  //
  // Queries
  //

  // If coming from a fresh session we need to fetch the integration from the server,
  // because it won't exist on location state.
  const { data: gcpIntegrationServer, isLoading: isLoadingGcpIntegration } =
    useGetGcpIntegrationByID(integrationID ?? "", {
      enabled: !!integrationID && !gcpIntegrationLocation,
    });

  //
  // Mutations
  //

  const { isPending: isCreatingGCPIntegration, mutate: createGCPIntegration } =
    useCreateGCPIntegration({
      onError: () => {
        postAlert({
          message: copyText.errorCreatingGcpIntegrationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (integrationID) => {
        validateDataIntegration({ integrationID });

        postAlert({
          message: copyText.successCreatingGcpIntegrationMessage,
          type: AlertType.SUCCESS,
        });

        navigate(paths._admin, { searchParams: { tab: "integrations" } });
      },
    });

  const { isPending: isUpdatingGCPIntegration, mutate: updateGCPIntegration } =
    useUpdateGCPIntegration({
      onError: () => {
        postAlert({
          message: copyText.errorUpdatingGcpIntegrationMessage,
          type: AlertType.ERROR,
        });
      },
      onSuccess: (integrationID) => {
        validateDataIntegration({ integrationID });

        postAlert({
          message: copyText.successUpdatingGcpIntegrationMessage,
          type: AlertType.SUCCESS,
        });

        navigate(paths._admin, { searchParams: { tab: "integrations" } });
      },
    });

  const { mutate: validateDataIntegration } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      return queryClient.invalidateQueries({
        queryKey: [queryKeys.dataIntegrations, authenticatedUser.tenant.id],
      });
    },
  });

  //
  // Computed Values
  //

  // If use integration on location state first, if we have it.
  const gcpIntegration = gcpIntegrationLocation ?? gcpIntegrationServer;

  //
  // Interaction Handlers
  //

  const defaultValues = gcpIntegration
    ? getValuesFromIntegration(gcpIntegration)
    : _defaultValues;

  async function handleSubmit({
    formApi,
    value,
  }: {
    value: FormData;
    formApi: FormApi<FormData>;
  }) {
    if (gcpIntegration) {
      const defaultValues = formApi.options.defaultValues;

      if (!defaultValues) return;

      handleUpdateGcpIntegration(gcpIntegration.id, value, defaultValues);
    } else {
      handleCreateGcpIntegration(authenticatedUser.tenant.id, value);
    }
  }

  function handleCreateGcpIntegration(tenantID: string, value: FormData) {
    activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
      type: CloudProviderType.GCP,
    });

    const isValidDetailedExport =
      value.datasetIDDetailed.length > 0 &&
      value.locationDetailed.length > 0 &&
      value.projectIDDetailed.length > 0 &&
      value.tableIDDetailed.length > 0;

    const detailedBillingExportSource = isValidDetailedExport
      ? {
          detailedBillingExportSource: {
            datasetID: value.datasetIDDetailed,
            location: value.locationDetailed,
            projectID: value.projectIDDetailed,
            tableID: value.tableIDDetailed,
          },
        }
      : null;

    const isValidCustomPricingExport =
      value.datasetIDCustom.length > 0 &&
      value.locationCustom.length > 0 &&
      value.projectIDCustom.length > 0 &&
      value.tableIDCustom.length > 0;

    const pricingExportSource = isValidCustomPricingExport
      ? {
          pricingExportSource: {
            datasetID: value.datasetIDCustom,
            location: value.locationCustom,
            projectID: value.projectIDCustom,
            tableID: value.tableIDCustom,
          },
        }
      : null;

    const isValidCarbonFootprintExport =
      value.datasetIDCarbon.length > 0 &&
      value.locationCarbon.length > 0 &&
      value.projectIDCarbon.length > 0 &&
      value.tableIDCarbon.length > 0;

    const carbonFootprintSource = isValidCarbonFootprintExport
      ? {
          carbonFootprintSource: {
            datasetID: value.datasetIDCarbon,
            location: value.locationCarbon,
            projectID: value.projectIDCarbon,
            tableID: value.tableIDCarbon,
          },
        }
      : null;

    const bigQueryMonitoring =
      value.configsBigQuery.length > 0
        ? {
            bigQueryMonitoring: value.configsBigQuery.map((config) => ({
              infoSchemaTable: config.scope,
              projectID: config.projectID,
              regions: config.regions,
            })),
          }
        : null;

    const billingExportSource = {
      datasetID: value.datasetIDStandard,
      location: value.locationStandard,
      projectID: value.projectIDStandard,
      tableID: value.tableIDStandard,
    };

    createGCPIntegration({
      tenantID: tenantID,
      billingAccountID: value.billingAccountID,
      cudSharingEnabled: value.enableCommitmentSharing,
      name: value.name,
      rootElement: value.rootResourceID,
      billingExportSource: billingExportSource,
      ...(bigQueryMonitoring ? bigQueryMonitoring : {}),
      ...(carbonFootprintSource ? carbonFootprintSource : {}),
      ...(detailedBillingExportSource ? detailedBillingExportSource : {}),
      ...(pricingExportSource ? pricingExportSource : {}),
    });
  }

  function handleUpdateGcpIntegration(
    integrationID: string,
    values: FormData,
    defaultValues: FormData
  ) {
    const changes = getChanges(values, defaultValues);

    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: CloudProviderType.GCP,
    });

    updateGCPIntegration({
      integrationID,
      ...changes,
    });
  }

  //
  // Render
  //

  const steps = [
    {
      component: (form) => <GcpIntegrationFormGeneral form={form} />,
      label: copyText.gcpFormTabLabelGeneral,
      requiredInputs: ["name", "rootResourceID", "billingAccountID"],
    },
    {
      component: (form) => <GcpIntegrationFormStandardBilling form={form} />,
      label: copyText.gcpFormTabLabelStandardBilling,
      requiredInputs: [
        "datasetIDStandard",
        "projectIDStandard",
        "tableIDStandard",
      ],
    },
    {
      component: (form) => <GcpIntegrationFormDetailedBilling form={form} />,
      isOptional: true,
      label: copyText.gcpFormTabLabelDetailedBilling,
    },
    {
      component: (form) => <GcpIntegrationFormCustomPricing form={form} />,
      isOptional: true,
      label: copyText.gcpFormTabLabelCustomPricing,
    },
    {
      component: (form) => <GcpIntegrationFormCarbonFootprint form={form} />,
      isOptional: true,
      label: copyText.gcpFormTabLabelCarbonFootprint,
    },
    {
      component: (form) => <GcpIntegrationFormBigQuery form={form} />,
      isOptional: true,
      label: copyText.gcpFormTabLabelBigQuery,
    },
    {
      component: () => (
        <Box width="50%">
          <Text appearance="h4">{copyText.createIntegrationHeader}</Text>
          <Text marginBottom={theme.space_lg}>
            After submitting your new integration, we will validate all
            permissions and attempt to collect your cost & usage data, assuming
            we have the necessary access your data will begin processing and
            should complete within 24 hours
          </Text>
        </Box>
      ),
      label: copyText.submitButtonLabel,
    },
  ];

  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "integrations" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text appearance="h3" marginVertical={theme.space_md}>
        {gcpIntegration
          ? gcpIntegration.name
          : copyText.gcpFormNewGcpIntegrationHeader}
      </Text>
      <Flex height="100%" width="100%">
        {isLoadingGcpIntegration ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <VerticalStepperForm
            defaultValues={defaultValues}
            isProcessing={isCreatingGCPIntegration || isUpdatingGCPIntegration}
            steps={steps}
            onSubmit={handleSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
}

function getValuesFromIntegration(integration: GcpIntegrationEntity) {
  return {
    // General
    billingAccountID: integration.billingAccountID ?? "",
    enableCommitmentSharing: integration.cudSharingEnabled,
    name: integration.name,
    rootResourceID: integration.rootElement ?? "",

    // Standard Billing
    datasetIDStandard: integration.billingExportSource?.datasetID ?? "",
    locationStandard: integration.billingExportSource?.location ?? "US",
    projectIDStandard: integration.billingExportSource?.projectID ?? "",
    tableIDStandard: integration.billingExportSource?.tableID ?? "",

    // Detailed Billing
    datasetIDDetailed: integration.detailedBillingExportSource?.datasetID ?? "",
    locationDetailed: integration.detailedBillingExportSource?.location ?? "US",
    projectIDDetailed: integration.detailedBillingExportSource?.projectID ?? "",
    tableIDDetailed: integration.detailedBillingExportSource?.tableID ?? "",

    // Custom Pricing
    datasetIDCustom: integration.pricingExportSource?.datasetID ?? "",
    locationCustom: integration.pricingExportSource?.location ?? "US",
    projectIDCustom: integration.pricingExportSource?.projectID ?? "",
    tableIDCustom: integration.pricingExportSource?.tableID ?? "",

    // Carbon Footprint
    datasetIDCarbon: integration.carbonFootprintSource?.datasetID ?? "",
    locationCarbon: integration.carbonFootprintSource?.location ?? "US",
    projectIDCarbon: integration.carbonFootprintSource?.projectID ?? "",
    tableIDCarbon: integration.carbonFootprintSource?.tableID ?? "",

    // BigQuery
    configsBigQuery: integration.bigQueryMonitoring
      ? integration.bigQueryMonitoring.map((config) => ({
          projectID: config.projectID,
          regions: config.regions,
          scope: config.infoSchemaTable,
        }))
      : ([] as BigQueryConfig[]),
    regionsBigQuery: [] as string[],
    projectIDBigQuery: "",
    scopeBigQuery: BigQueryScope.JOBS_BY_PROJECT,
  };
}

function getChanges(values: FormData, defaultValues: FormData) {
  const changes: UpdateGCPIntegrationParams = {};

  if (!isEqual(defaultValues.billingAccountID, values.billingAccountID)) {
    changes.billingAccountID = values.billingAccountID;
  }

  if (
    !isEqual(
      defaultValues.enableCommitmentSharing,
      values.enableCommitmentSharing
    )
  ) {
    changes.cudSharingEnabled = values.enableCommitmentSharing;
  }

  if (!isEqual(defaultValues.rootResourceID, values.rootResourceID)) {
    changes.rootElement = values.rootResourceID;
  }

  if (!isEqual(defaultValues.configsBigQuery, values.configsBigQuery)) {
    changes.bigQueryMonitoring = values.configsBigQuery.map((config) => ({
      infoSchemaTable: config.scope,
      projectID: config.projectID,
      regions: config.regions,
    }));
  }

  if (
    !isEqual(
      {
        datasetID: defaultValues.datasetIDStandard,
        location: defaultValues.locationStandard,
        projectID: defaultValues.projectIDStandard,
        tableID: defaultValues.tableIDStandard,
      },
      {
        datasetID: values.datasetIDStandard,
        location: values.locationStandard,
        projectID: values.projectIDStandard,
        tableID: values.tableIDStandard,
      }
    )
  ) {
    changes.billingExportSource = {
      datasetID: values.datasetIDStandard,
      location: values.locationStandard,
      projectID: values.projectIDStandard,
      tableID: values.tableIDStandard,
    };
  }

  if (
    !isEqual(
      {
        datasetID: defaultValues.datasetIDCarbon,
        location: defaultValues.locationCarbon,
        projectID: defaultValues.projectIDCarbon,
        tableID: defaultValues.tableIDCarbon,
      },
      {
        datasetID: values.datasetIDCarbon,
        location: values.locationCarbon,
        projectID: values.projectIDCarbon,
        tableID: values.tableIDCarbon,
      }
    )
  ) {
    changes.carbonFootprintSource = {
      datasetID: values.datasetIDCarbon,
      location: values.locationCarbon,
      projectID: values.projectIDCarbon,
      tableID: values.tableIDCarbon,
    };
  }

  if (
    !isEqual(
      {
        datasetID: defaultValues.datasetIDCarbon,
        location: defaultValues.locationCarbon,
        projectID: defaultValues.projectIDCarbon,
        tableID: defaultValues.tableIDCarbon,
      },
      {
        datasetID: values.datasetIDCarbon,
        location: values.locationCarbon,
        projectID: values.projectIDCarbon,
        tableID: values.tableIDCarbon,
      }
    )
  ) {
    changes.carbonFootprintSource = {
      datasetID: values.datasetIDCarbon,
      location: values.locationCarbon,
      projectID: values.projectIDCarbon,
      tableID: values.tableIDCarbon,
    };
  }

  if (
    !isEqual(
      {
        datasetID: defaultValues.datasetIDDetailed,
        location: defaultValues.locationDetailed,
        projectID: defaultValues.projectIDDetailed,
        tableID: defaultValues.tableIDDetailed,
      },
      {
        datasetID: values.datasetIDDetailed,
        location: values.locationDetailed,
        projectID: values.projectIDDetailed,
        tableID: values.tableIDDetailed,
      }
    )
  ) {
    changes.detailedBillingExportSource = {
      datasetID: values.datasetIDDetailed,
      location: values.locationDetailed,
      projectID: values.projectIDDetailed,
      tableID: values.tableIDDetailed,
    };
  }

  if (
    !isEqual(
      {
        datasetID: defaultValues.datasetIDCustom,
        location: defaultValues.locationCustom,
        projectID: defaultValues.projectIDCustom,
        tableID: defaultValues.tableIDCustom,
      },
      {
        datasetID: values.datasetIDCustom,
        location: values.locationCustom,
        projectID: values.projectIDCustom,
        tableID: values.tableIDCustom,
      }
    )
  ) {
    changes.pricingExportSource = {
      datasetID: values.datasetIDCustom,
      location: values.locationCustom,
      projectID: values.projectIDCustom,
      tableID: values.tableIDCustom,
    };
  }

  return changes;
}
