import ErrorBoundary from "@/components/ErrorBoundary";
import Tabs from "@/ui-lib/components/Tabs";
import React from "react";
import { Navigate } from "react-router-dom";
import { StringParam, useQueryParams, withDefault } from "use-query-params";
import paths from "../../../constants/paths";
import useGatekeeper from "../../../hooks/useGatekeeper";
import CustomLabelsManagerContainer from "../../label-management/components/CustomLabelManagementContainer";
import LabelGroupingRuleManagementContainer from "../../label-management/components/LabelGroupingRuleManagementContainer";
import ScopedViewManagementContainer from "../../user-settings/components/ScopedViewManagementContainer";
import copyText from "../copyText";
import DataIntegrationManagementContainer from "./DataIntegrationManagementContainer";
import FiscalCalendarBuilderContainer from "./FiscalCalendar/FiscalCalendarBuilderContainer";
import GlobalPreferencesManagementContainer from "./GlobalPreferencesManagementContainer";
import TenantInfoContainer from "./TenantInfoContainer";
import UserGroupConfigManagementContainer from "./UserGroupConfigs/UserGroupConfigManagementContainer";
import UserManagementContainer from "./UserManagementContainer";

export default function AdminPage(): JSX.Element {
  const gatekeeper = useGatekeeper();

  const [searchParamState, setSearchParamState] = useQueryParams({
    tab: withDefault(StringParam, "tenant"),
  });

  if (!gatekeeper.canViewAdminPage) {
    return <Navigate to={paths._home} replace={true} />;
  }

  const containers = [
    {
      component: <TenantInfoContainer />,
      label: copyText.tabLabelTenant,
      value: "tenant",
    },
    ...(gatekeeper.canViewDataIntegrationsTab
      ? [
          {
            component: <DataIntegrationManagementContainer />,
            label: copyText.tabLabelIntegrations,
            value: "integrations",
          },
        ]
      : []),
    ...(gatekeeper.canViewUsersTab
      ? [
          {
            component: <UserManagementContainer />,
            label: copyText.tabLabelUsers,
            value: "users",
          },
        ]
      : []),
    {
      component: <UserGroupConfigManagementContainer />,
      label: copyText.tabLabelUserGroupConfigs,
      value: "userGroups",
    },
    {
      component: <ScopedViewManagementContainer isAdmin={true} />,
      label: copyText.tabLabelScopedViews,
      value: "scopedViews",
    },
    ...(gatekeeper.canViewLabelManagement
      ? [
          {
            component: <CustomLabelsManagerContainer />,
            label: copyText.tabLabelCustomLabels,
            value: "customLabels",
          },
          {
            component: <LabelGroupingRuleManagementContainer />,
            label: copyText.tabLabelCustomGroupings,
            value: "customGroupings",
          },
        ]
      : []),
    {
      component: <GlobalPreferencesManagementContainer />,
      label: copyText.tabLabelGlobalPreferences,
      value: "global",
    },
    {
      component: <FiscalCalendarBuilderContainer />,
      label: copyText.tabLabelFiscalCalendar,
      value: "fiscalCalendar",
    },
  ];

  return (
    <ErrorBoundary boundaryName="AdminPage">
      <Tabs
        activeValue={searchParamState.tab}
        tabs={containers}
        onSelect={(tab: string) => setSearchParamState({ tab })}
      />
    </ErrorBoundary>
  );
}
