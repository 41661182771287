import CoreAPIClientError from "@/api/core/CoreAPIClientError";
import { useCoreAPIClient } from "@/api/core/CoreAPIClientProvider";
import { UpdateTextWidgetParams } from "@/api/core/types";
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
} from "@tanstack/react-query";

type Options = UseMutationOptions<
  string,
  CoreAPIClientError,
  {
    textWidgetID: string;
  } & UpdateTextWidgetParams
>;

type Result = UseMutationResult<
  string,
  CoreAPIClientError,
  {
    textWidgetID: string;
  } & UpdateTextWidgetParams
>;

export default function useUpdateTextWidget(options?: Options): Result {
  const client = useCoreAPIClient();

  return useMutation({
    mutationFn: ({ textWidgetID, ...params }) => {
      return client.updateTextWidget(textWidgetID, params);
    },
    ...options,
  });
}
