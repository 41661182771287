import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { faChevronDown, faRemove } from "@fortawesome/free-solid-svg-icons";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import Flex from "@ternary/web-ui-lib/components/Flex";
import Icon from "@ternary/web-ui-lib/components/Icon";
import Text from "@ternary/web-ui-lib/components/Text";
import { Theme } from "@ternary/web-ui-lib/theme/default";
import { keyBy } from "lodash";
import React, { useEffect, useState } from "react";
import {
  MenuProps,
  OnChangeValue,
  OptionProps,
  Options,
  StylesConfig,
  components,
} from "react-select";
import copyText from "../copyText";
import IconCheck from "../icons/IconCheck";
import Select from "./Select";

export const LoadingEllipsis = styled.div<{ margin?: number | string }>`
  position: relative;
  width: 8px;
  height: 8px;
  border-radius: 5px;
  background-color: ${(props) => props.theme.feedback_neutral};
  color: ${(props) => props.theme.feedback_neutral};
  animation: dot-flashing 500ms infinite linear alternate;
  animation-delay: 250ms;
  margin: ${(props) => props.margin ?? props.theme.space_xs};

  ::before,
  ::after {
    content: "";
    display: inline-block;
    position: absolute;
    top: 0;
  }
  ::before {
    left: -14px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.feedback_neutral};
    color: ${(props) => props.theme.feedback_neutral};
    animation: dot-flashing 500ms infinite alternate;
    animation-delay: 0s;
  }
  ::after {
    left: 14px;
    width: 8px;
    height: 8px;
    border-radius: 5px;
    background-color: ${(props) => props.theme.feedback_neutral};
    color: ${(props) => props.theme.feedback_neutral};
    animation: dot-flashing 500ms infinite alternate;
    animation-delay: 500ms;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${(props) => props.theme.feedback_neutral};
    }
    50%,
    100% {
      background-color: rgba(192, 192, 192, 0.2);
    }
  }
`;

const checkboxTheme = (baseTheme: Theme) => ({
  Checkbox_backgroundColor_hover: baseTheme.primary_color_hover,
  Checkbox_borderColor_hover: baseTheme.primary_color_border,
  Checkbox_borderColor: baseTheme.secondary_color_border,
  ...baseTheme,
});

const Checkbox = styled("span")<{ checked: boolean }>(({
  checked,
  theme: baseTheme,
}) => {
  const theme = checkboxTheme(baseTheme);

  return {
    backgroundColor: (() => {
      return checked
        ? theme.primary_color_background
        : theme.panel_backgroundColor;
    })(),
    borderColor: checked ? "transparent" : theme.Checkbox_borderColor,
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    height: "16px",
    marginRight: theme.space_xs,
    minWidth: "16px",
    width: "16px",

    "&:hover": {
      backgroundColor: !checked
        ? undefined
        : theme.Checkbox_backgroundColor_hover,
      borderColor: theme.Checkbox_borderColor_hover,
    },
  };
});

const Dropdown = styled("button")<{ compact?: boolean; disabled?: boolean }>(
  ({ compact, disabled, theme }) => ({
    alignItems: "center",
    backgroundColor: disabled
      ? theme.background_color_disabled
      : theme.panel_backgroundColor,
    borderColor: theme.secondary_color_border,
    borderRadius: theme.borderRadius_2,
    borderStyle: "solid",
    borderWidth: "1px",
    cursor: disabled ? undefined : "pointer",
    display: "flex",
    height: compact ? "30px" : "38px",
    justifyContent: "space-between",
    minHeight: compact ? "32px" : "38px",
    paddingLeft: theme.space_md,
    paddingRight: theme.space_md,
    width: "100%",

    "&:hover": {
      borderColor: disabled
        ? theme.secondary_color_border
        : theme.primary_color_border,
    },

    "&:focus": {
      borderColor: "transparent",
      boxShadow: `0 0 0 2px ${theme.primary_color_focus}`,
    },

    "&:active": {
      borderColor: disabled ? theme.secondary_color_border : "transparent",
      boxShadow: disabled
        ? theme.secondary_color_border
        : `0 0 0 2px ${theme.primary_color_focus}`,
    },
  })
);

const Content = styled("div")(({ theme }) => ({
  backgroundColor: theme.panel_backgroundColor,
  border: `1px solid ${theme.border_color}`,
  borderRadius: theme.borderRadius_2,
  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  marginTop: theme.space_xxl,
  minWidth: 150,
  overflowY: "auto",
  padding: `${theme.space_xs} ${theme.space_xxs}`,
  position: "absolute",
  width: "100%",
  zIndex: theme.zIndex_1600,
}));

function getStyles(
  theme: Theme,
  props: { hideSearch?: boolean }
): Partial<StylesConfig<Option, true>> {
  return {
    container: (styles) => ({
      ...styles,
      padding: `0 ${theme.space_xxs}`,
      width: "100%",
    }),
    control: (styles, controlProps) => {
      const boxShadow =
        controlProps.isFocused && !props.hideSearch
          ? `0 0 0 2px ${theme.primary_color_focus}`
          : "none";

      return {
        ...styles,
        borderColor: controlProps.isFocused ? "transparent" : "purple",
        borderStyle: "solid",
        borderWidth: props.hideSearch ? "0px" : "1px",
        borderRadius: theme.borderRadius_2,
        boxShadow,
        color: theme.text_color,
        cursor: "text",
        fontSize: theme.fontSize_ui,
        height: props.hideSearch ? "0px" : "32px",
        marginBottom: props.hideSearch ? "0px" : "10px",
        minHeight: props.hideSearch ? "0px" : "32px",

        "&:hover": {
          borderColor: controlProps.isFocused
            ? "transparent"
            : theme.primary_color_border,
        },
      };
    },
    menu: () => ({
      backgroundColor: theme.panel_backgroundColor,
      border: `1px solid ${theme.panel_backgroundColor}`,
      borderRadius: theme.borderRadius_2,
      boxShadow: "none",
      zIndex: theme.zIndex_100,
    }),
    option: (styles) => ({
      ...styles,
      alignItems: "center",
      backgroundColor: "transparent",
      borderRadius: theme.borderRadius_2,
      color: theme.text_color,
      cursor: "pointer",
      display: "flex",
      fontSize: theme.fontSize_ui,
      padding: theme.space_xs,

      "&:hover": {
        backgroundColor: theme.secondary_color_background,
      },
    }),
    placeholder: (styles) =>
      props.hideSearch
        ? {
            height: "0px",
            minHeight: "0px",
          }
        : styles,
  };
}

export type Option = { label: string; value: string };

export interface Props {
  closeOnSubmit?: boolean;
  backspaceRemovesValue?: boolean;
  compact?: boolean;
  disabled?: boolean;
  height?: number | string;
  hideSearch?: boolean;
  hideValues?: boolean;
  isClearable?: boolean;
  isCreatable?: boolean;
  isLoading?: boolean;
  options: Option[];
  placeholder?: string;
  selectedValues: string[];
  width?: number | string;
  submitButtonText?: string;
  onChange: (value: string[]) => void;
}

export default function SelectCheckbox(props: Props): JSX.Element {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [selectedCount, setSelectedCount] = useState(0);
  const [selectedOptions, setSelectedOptions] = useState<readonly Option[]>(
    props.selectedValues
      ? props.selectedValues.map((value) => ({
          label: value,
          value,
        }))
      : []
  );

  const optionsKeyedByValue = keyBy(props.options, "value");

  useEffect(() => {
    if (props.isLoading) return;

    setSelectedCount(props.selectedValues.length);

    setSelectedOptions(
      props.selectedValues.map((value) => {
        const option = optionsKeyedByValue[value];

        return {
          label: option ? option.label : value,
          value,
        };
      })
    );
  }, [props.isLoading, props.selectedValues]);

  function getValuesLabel(values: string[]): string {
    if (props.isLoading) return "";

    const labels = values.map((value) => {
      const option = optionsKeyedByValue[value];
      return option ? option.label : value;
    });

    return labels
      .reduce((accum: string[], value) => {
        if (accum.length < 2) {
          return [...accum, value];
        }

        if (accum.length === 2) {
          return [
            ...accum,
            `${values.length - 2} ${copyText.moreOptionsLabel}`,
          ];
        }

        return accum;
      }, [])
      .join(", ");
  }

  function handleChange(options: Options<Option>): void {
    setSelectedOptions(options);
    props.onChange(options.map((option) => option.value));
  }

  function handleSelectOption(value: OnChangeValue<Option, true>): void {
    if (!value) return;

    setSelectedCount(value.length);
    setSelectedOptions(value);
  }

  function handleSubmit(menuProps): void {
    handleChange(menuProps.getValue());
    setIsOpen(false);
  }

  const select = (
    <Select
      autoFocus
      options={props.options}
      blurInputOnSelect={false}
      closeMenuOnSelect={false}
      components={{
        DropdownIndicator: null,
        IndicatorSeparator: null,
        ...(props.closeOnSubmit ? { Menu } : {}),
        ClearIndicator: ({ ...props }) => (
          <components.ClearIndicator {...props}>
            <Icon icon={faRemove} size="lg" />
          </components.ClearIndicator>
        ),
        Option,
      }}
      controlShouldRenderValue={false}
      defaultValue={selectedOptions}
      disabled={props.disabled}
      hideSelectedOptions={false}
      isClearable={props.isClearable ?? false}
      isCreatable={props.isCreatable}
      isLoading={props.isLoading}
      isMulti={true}
      menuIsOpen
      placeholder={copyText.searchInputPlaceholder}
      backspaceRemovesValue={props.backspaceRemovesValue ?? false}
      searchable={!props.hideSearch}
      styles={getStyles(theme, props)}
      value={selectedOptions}
      onChange={props.closeOnSubmit ? handleSelectOption : handleChange}
      onBlur={() => setIsOpen(false)}
      {...{
        selectProps: {
          selectedCount: selectedCount,
          submitButtonText: props.submitButtonText,
          onSubmit: handleSubmit,
        },
      }}
    />
  );

  const placeholder = props.placeholder ?? copyText.defaultSelectPlaceholder;

  const values = selectedOptions.map((option) => option.value);

  const textProps = {
    color:
      props.disabled || props.hideValues || values.length === 0
        ? theme.text_color_placeholder
        : undefined,
    lineHeight: 1,
    truncate: true,
  };

  const inputContent = props.isLoading ? (
    <LoadingEllipsis />
  ) : (
    <Text {...textProps}>
      {props.hideValues || values.length === 0
        ? placeholder
        : getValuesLabel(values)}
    </Text>
  );

  return (
    <Flex
      height={props.height}
      position="relative"
      width={props.width ?? "100%"}
    >
      <Dropdown
        compact={props.compact}
        disabled={props.disabled}
        type="button"
        onClick={() => !props.disabled && setIsOpen((isOpen) => !isOpen)}
      >
        {inputContent}
        <Icon
          clickable
          color={theme.text_color_placeholder}
          icon={faChevronDown}
        />
      </Dropdown>
      {isOpen && <Content>{select}</Content>}
    </Flex>
  );
}

const Option = (props: OptionProps<Option, true>): JSX.Element => (
  <components.Option {...props}>
    <Checkbox checked={props.isSelected}>
      {props.isSelected && <IconCheck clickable />}
    </Checkbox>
    <Text>{props.label}</Text>
  </components.Option>
);

const Menu = ({
  children,
  ...menuProps
}: MenuProps<Option, boolean>): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const customProps = (menuProps.selectProps as any).selectProps;
  return (
    <components.Menu {...menuProps}>
      <>
        {children}
        <Button
          fullWidth
          primary
          onClick={() => {
            customProps.onSubmit(menuProps);
          }}
        >
          {`${customProps.submitButtonText ?? copyText.submitButtonLabel} (${
            customProps.selectedCount
          })`}
        </Button>
      </>
    </components.Menu>
  );
};
