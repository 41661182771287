import { useTheme } from "@emotion/react";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { FormApi } from "@tanstack/react-form";
import { useQueryClient } from "@tanstack/react-query";
import {
  CloudProviderType,
  MongoDbAuthType,
} from "@ternary/api-lib/constants/enums";
import { PublicMongoDbIntegrationEntity } from "@ternary/api-lib/core/types/MongoDbIntegration";
import { actions } from "@ternary/api-lib/telemetry";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Button from "@ternary/api-lib/ui-lib/components/Button";
import EmptyPlaceholder from "@ternary/api-lib/ui-lib/components/EmptyPlaceholder";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Icon from "@ternary/api-lib/ui-lib/components/Icon";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import { get, isEqual, repeat } from "lodash";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { UpdateMongoDbIntegrationParams } from "../../../../../api/core/types";
import paths from "../../../../../constants/paths";
import { useActivityTracker } from "../../../../../context/ActivityTrackerProvider";
import useAuthenticatedUser from "../../../../../hooks/useAuthenticatedUser";
import { useNavigateWithSearchParams } from "../../../../../lib/react-router";
import { VerticalStepperForm } from "../../../../../ui-lib/components/Stepper";
import { AlertType, postAlert } from "../../../../../utils/alerts";
import queryKeys from "../../../hooks/queryKeys";
import useValidateDataIntegration from "../../../hooks/useValidateDataIntegration";
import copyText from "../../copyText";
import useCreateMongoDbIntegration from "../../hooks/useCreateMongoDbIntegration";
import { useGetMongoDbIntegrationByID } from "../../hooks/useGetMongoDbIntegrationByID";
import useUpdateMongoDbIntegration from "../../hooks/useUpdateMongoDbIntegration";
import { defaultValues as _defaultValues } from "./constants";
import { MongoDbIntegrationFormGeneral } from "./MongoDbIntegrationFormGeneral";
import { FormData } from "./types";

export function MongoDbIntegrationFormContainer() {
  const activityTracker = useActivityTracker();
  const authenticatedUser = useAuthenticatedUser();
  const location = useLocation();
  const navigate = useNavigateWithSearchParams();
  const queryClient = useQueryClient();
  const theme = useTheme();

  const { integrationID } = useParams();

  //
  // State
  //
  const mongoDbIntegrationLocation: PublicMongoDbIntegrationEntity | undefined =
    get(location.state, "integration");

  //
  // Queries
  //

  // If coming from a fresh session we need to fetch the integration from the server,
  // because it won't exist on location state.
  const {
    data: mongoDbIntegrationServer,
    isLoading: isLoadingMongoDbIntegration,
  } = useGetMongoDbIntegrationByID(integrationID ?? "", {
    enabled: !!integrationID && !mongoDbIntegrationLocation,
  });

  //
  // Mutations
  //

  const {
    isPending: isCreatingMongoDbIntegration,
    mutate: createMongoDbIntegration,
  } = useCreateMongoDbIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorCreatingMongoDbIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successCreatingMongoDbIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "integrations" } });
    },
  });

  const {
    isPending: isUpdatingMongoDbIntegration,
    mutate: updateMongoDbIntegration,
  } = useUpdateMongoDbIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorUpdatingMongoDbIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSuccess: (integrationID) => {
      validateDataIntegration({ integrationID });

      postAlert({
        message: copyText.successUpdatingMongoDbIntegrationMessage,
        type: AlertType.SUCCESS,
      });

      navigate(paths._admin, { searchParams: { tab: "integrations" } });
    },
  });

  const { mutate: validateDataIntegration } = useValidateDataIntegration({
    onError: () => {
      postAlert({
        message: copyText.errorValidatingDataIntegrationMessage,
        type: AlertType.ERROR,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: [queryKeys.dataIntegrations, authenticatedUser.tenant.id],
      });
    },
  });

  //
  // Computed Values
  //

  // If use integration on location state first, if we have it.
  const mongoDbIntegration =
    mongoDbIntegrationLocation ?? mongoDbIntegrationServer;

  //
  // Interaction Handlers
  //

  const defaultValues = mongoDbIntegration
    ? getValuesFromIntegration(mongoDbIntegration)
    : _defaultValues;

  async function handleSubmit({
    formApi,
    value,
  }: {
    value: FormData;
    formApi: FormApi<FormData>;
  }) {
    if (mongoDbIntegration) {
      const defaultValues = formApi.options.defaultValues;

      if (!defaultValues) return;

      handleUpdateMongoDbIntegration(
        mongoDbIntegration.id,
        value,
        defaultValues
      );
    } else {
      handleCreateMongoDbIntegration(authenticatedUser.tenant.id, value);
    }
  }

  function handleCreateMongoDbIntegration(tenantID: string, value: FormData) {
    activityTracker.captureAction(actions.CLICK_ADMIN_CREATE_INTEGRATION, {
      type: CloudProviderType.MONGO_DB,
    });

    const authorization =
      value.authType === MongoDbAuthType.API_KEY
        ? {
            type: MongoDbAuthType.API_KEY,
            publicKey: value.publicKey,
            secretKey: value.privateKey,
          }
        : {
            type: MongoDbAuthType.SERVICE_ACCOUNT,
            clientID: value.publicKey,
            clientSecret: value.privateKey,
          };

    createMongoDbIntegration({
      tenantID: tenantID,
      name: value.name,
      organizationID: value.organizationID,
      ...authorization,
    });
  }

  function handleUpdateMongoDbIntegration(
    integrationID: string,
    values: FormData,
    defaultValues: FormData
  ) {
    activityTracker.captureAction(actions.CLICK_ADMIN_UPDATE_INTEGRATION, {
      type: CloudProviderType.MONGO_DB,
    });

    const formattedDefaultValues = {
      name: defaultValues.name,
      organizationID: defaultValues.organizationID,
      ...(mongoDbIntegrationLocation?.authType === MongoDbAuthType.API_KEY
        ? {
            type: MongoDbAuthType.API_KEY,
            publicKey: defaultValues.publicKey,
            secretKey: defaultValues.privateKey,
          }
        : {
            type: MongoDbAuthType.SERVICE_ACCOUNT,
            clientID: defaultValues.publicKey,
            clientSecret: defaultValues.privateKey,
          }),
    };

    const formattedValues = {
      name: values.name,
      organizationID: values.organizationID,
      ...(values.authType === MongoDbAuthType.API_KEY
        ? {
            type: MongoDbAuthType.API_KEY,
            publicKey: values.publicKey,
            secretKey: values.privateKey,
          }
        : {
            type: MongoDbAuthType.SERVICE_ACCOUNT,
            clientID: values.publicKey,
            clientSecret: values.privateKey,
          }),
    };

    const changes = getChanges(formattedValues, formattedDefaultValues);

    updateMongoDbIntegration({
      integrationID,
      ...changes,
    });
  }

  //
  // Render
  //

  const steps = [
    {
      component: (form) => <MongoDbIntegrationFormGeneral form={form} />,
      label: copyText.mongoDbFormTabLabelGeneral,
      requiredInputs: [
        "name",
        "organizationID",
        "authType",
        "publicKey",
        "privateKey",
      ],
    },
    {
      component: () => (
        <Box width="50%">
          <Text appearance="h4">{copyText.createIntegrationHeader}</Text>
          <Text marginBottom={theme.space_lg}>
            {copyText.integrationLastInstructions}
          </Text>
        </Box>
      ),
      label: copyText.submitButtonLabel,
    },
  ];

  return (
    <Flex direction="column" height="80vh">
      <Button
        iconStart={<Icon icon={faArrowLeft} />}
        secondary
        width={200}
        onClick={() =>
          navigate(paths._admin, { searchParams: { tab: "integrations" } })
        }
      >
        {copyText.backToIntegrationsButtonLabel}
      </Button>
      <Text appearance="h3" marginVertical={theme.space_md}>
        {mongoDbIntegration
          ? mongoDbIntegration.name
          : copyText.mongoDbFormNewMongoDbIntegrationHeader}
      </Text>
      <Flex height="100%" width="100%">
        {isLoadingMongoDbIntegration ? (
          <EmptyPlaceholder loading width="50%" />
        ) : (
          <VerticalStepperForm
            defaultValues={defaultValues}
            isProcessing={
              isCreatingMongoDbIntegration || isUpdatingMongoDbIntegration
            }
            steps={steps}
            onSubmit={handleSubmit}
          />
        )}
      </Flex>
    </Flex>
  );
}

function showPasswordPreview(secret: string): string {
  const splitSecret = secret.split(":");

  const count = Number(splitSecret[1]);

  const asterisks = repeat("*", count > 16 ? 16 : count);

  return splitSecret[0].concat(asterisks, splitSecret[2]);
}

function getValuesFromIntegration(integration: PublicMongoDbIntegrationEntity) {
  const preview = showPasswordPreview(
    integration.authType === MongoDbAuthType.API_KEY
      ? (integration.secretKeyPreview ?? "")
      : (integration.clientSecretPreview ?? "")
  );

  return {
    // General
    authType: integration.authType,
    name: integration.name,
    organizationID: integration.organizationID ?? "",
    privateKey: "",
    privateKeyPreview: preview,
    publicKey:
      integration.authType === MongoDbAuthType.API_KEY
        ? (integration.publicKey ?? "")
        : (integration.clientID ?? ""),
  };
}
function getChanges(
  values: UpdateMongoDbIntegrationParams,
  defaultValues: UpdateMongoDbIntegrationParams
) {
  const changes: UpdateMongoDbIntegrationParams = {};

  if (!isEqual(defaultValues.name, values.name)) {
    changes.name = values.name;
  }

  if (!isEqual(defaultValues.organizationID, values.organizationID)) {
    changes.organizationID = values.organizationID;
  }

  // if any of the fields change, rebuild the entire object
  if (
    !isEqual(defaultValues.type, values.type) ||
    !isEqual(defaultValues.publicKey, values.publicKey) ||
    !isEqual(defaultValues.clientID, values.clientID) ||
    !isEqual(defaultValues.secretKey, values.secretKey) ||
    !isEqual(defaultValues.clientSecret, values.clientSecret)
  ) {
    changes.type = values.type;
    if (values.type === MongoDbAuthType.API_KEY) {
      changes.publicKey = values.publicKey;
      changes.secretKey = values.secretKey;
    } else {
      changes.clientID = values.clientID;
      changes.clientSecret = values.clientSecret;
    }
  }

  return changes;
}
