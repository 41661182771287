import externalLinks from "@/constants/externalLinks";
import { useTheme } from "@emotion/react";
import Box from "@ternary/api-lib/ui-lib/components/Box";
import Flex from "@ternary/api-lib/ui-lib/components/Flex";
import Text from "@ternary/api-lib/ui-lib/components/Text";
import React from "react";
import { FormField } from "../../../../../ui-lib/components/Form";
import TextInput from "../../../../../ui-lib/components/TextInput";
import copyText from "../../copyText";
import { FormProps } from "./types";

const validators = {
  channelID: ({ value }: { value: string }) => {
    return value.length === 0 ? copyText.errorInputFieldRequired : undefined;
  },
};

export default function SlackIntegrationForm(props: FormProps) {
  const theme = useTheme();

  const { form } = props;

  const captionFragments =
    copyText.slackIntegrationChannelIDCaption.split("%link%");

  const externalLinkCaption = (
    <Flex alignItems="center">
      <Text whiteSpace="pre">{captionFragments[0]}</Text>
      <a
        href={externalLinks.readmeSlackIntegrationDocumentation}
        rel="noreferrer"
        target="_blank"
      >
        {copyText.slackIntegrationChannelIDCaptionLink}
      </a>
      <Text whiteSpace="pre">{captionFragments[1]}</Text>
    </Flex>
  );

  return (
    <Box padding={theme.space_xxs}>
      <form.Field
        name="channelID"
        validators={{
          onChange: validators.channelID,
          onMount: validators.channelID,
        }}
        children={(field) => {
          const shouldShowError =
            field.state.meta.isTouched && field.state.meta.errors.length > 0;

          return (
            <FormField
              input={TextInput}
              errorCaption={
                shouldShowError ? field.state.meta.errors.join(", ") : undefined
              }
              autoFocus
              caption={externalLinkCaption}
              label={copyText.slackIntegrationAttributeChannelID}
              required
              value={field.state.value}
              variant={shouldShowError ? "danger" : undefined}
              onChange={(e) => field.handleChange(e.target.value)}
            />
          );
        }}
      />
    </Box>
  );
}
