import { useEffect, useRef } from "react";

/**
 * A hook that creates a Promise that resolves when a loading state completes.
 * Useful for coordinating async operations with loading states.
 *
 * Example use case: SelectDropdown.tsx
 */
export default function useLoadingPromise(isLoading: boolean | undefined) {
  const promiseRef = useRef(Promise.resolve());

  useEffect(() => {
    let resolver = () => {};

    // Create a new promise that will resolve when loading completes
    promiseRef.current = new Promise((resolve) => {
      // If we're not loading, resolve immediately
      if (!isLoading) {
        resolve();
      }
      // Store the resolver function to be called when loading completes
      resolver = resolve;
    });

    // Cleanup: ensure any pending promise is resolved when the effect is cleaned up
    return () => {
      resolver();
    };
  }, [isLoading]); // Only re-run when loading state changes

  return promiseRef.current;
}
